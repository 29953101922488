import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Variable from '@/assets/js/models/Variable'
import { TributeCollection } from 'tributejs'

@Component({
  i18n: {
    messages: {
      en: {
        noMatch: 'No Match Found!',
      },
      it: {
        noMatch: 'Nessun risultato!',
      },
    },
  },
})
export default class MentionMixin extends Vue {
  @Prop({type: Boolean, required: false, default: false})
  activeMention: boolean

  @Prop({type: Boolean, required: false, default: true})
  requireLeadingSpace: boolean

  @Watch('variablesToShow', {immediate: true})
  onVariablesToShowChange(val: Variable[], oldVal: Variable[]) {
    if (val !== oldVal) {
      if (this.tribute) {
        this.tribute.collection[0].values = this.variablesToMention
      }
    }
  }

  mentionOptions: TributeCollection<{ [key: string]: string }> = {
    values: [],
    trigger: '@',
    requireLeadingSpace: true,
    noMatchTemplate: () => {
      return `<li>${this.$t('noMatch')}</li>`;
    }
  }
  tribute: any = null

  get variablesToShow(): Variable[] {
    return this.$store?.state.variables.all
  }

  get variablesToMention(): {
    key: string
    value: string
  }[] {
    return this.variablesToShow?.map((item) => {
      return {
        key: item.getName(),
        value: item.getName(),
      }
    })
  }

  attachMention(DOMelement: HTMLDivElement | Element) {
    this.mentionOptions.requireLeadingSpace = this.requireLeadingSpace
    this.tribute = new this.$tribute(this.mentionOptions);
    
    if (DOMelement) {
      this.tribute.attach(DOMelement);
      this.tribute.append(0, this.variablesToMention)
    }
  }
}
