import { Vue, Component, Ref, Prop } from 'nuxt-property-decorator'
import { defineAsyncComponent } from 'vue'
import UNavbarMenuElement from '@/components/u-navbar/u-navbar-menu-element/index.vue'
import ClickOutside from '@/components/events/click-outside/index.vue'
import { hasPermission } from '@/modules/auth'
import { Bot } from '@/assets/js/models/Bot'
import { Socket } from '@/assets/js/core/Socket'

const UModalBotCreation = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/modals/u-modal-bot-creation/index.vue' /* webpackChunkName: "components/modals/u-modal-bot-creation" */
      ).then(resolve)
    })
)

@Component({
  components: {
    UNavbarMenuElement,
    ClickOutside,
    UModalBotCreation,
  },
})
export default class UNavbarBots extends Vue {
  @Prop({ type: String, required: false, default: 'div' })
  tag: string

  @Ref()
  readonly content!: HTMLDivElement

  @Ref()
  readonly dropdown!: any

  get bot() {
    return new Bot(this.$store.state.page.bot)
  }

  get bots(): [] {
    return this.$store.state.bots.all?.filter((bot: Bot) => !bot.isSuspended())
  }

  get navbarStatus() {
    return this.$store.state.navbar.status
  }

  status: boolean = false
  modalNewBot: boolean = false

  async mounted() {
    await this.$nextTick()
    if (this.dropdown) {
      this.dropdown.$el
        .querySelector('.dropdown-menu')
        .classList.add('u-dropdown-navbar-bots')
    }
  }

  queryUrlAfterBotId() {
    try {
      const filteredPathArray = location.pathname
        .split(this.bot.id())[1]
        ?.split('/')
        .filter((f) => f)
      if (filteredPathArray) {
        if (filteredPathArray[0] === 'flows' || filteredPathArray[0] === 'chats') {
          return filteredPathArray[0]
        }
        return filteredPathArray.join('/')
      }
    } catch (error) {
      console.log(error)
      return 'dashboard'
    }
  }

  toggle() {
    this.status = !this.status
  }

  onClickOutside() {
    this.status = false
  }

  onBotCreationClick() {
    this.modalNewBot = true
    this.status = false
  }

  getUrl(bot: Bot) {
    return this.localePath(`/bots/${bot.id()}/${this.queryUrlAfterBotId()}`)
  }

  refreshSocket(argument: string) {
    this.$store.commit('documents/resetDocuments')
    this.$store?.commit('page/changeBot', argument)
    Socket.send(`sitekey undefined ${argument}`)
    Socket.freeze()
    this.$store?.dispatch('variables/reset')
  }

  hasPermission = hasPermission
}
