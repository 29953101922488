import { Socket } from '../core/Socket'

export default function WebSocketPublishEvent(
  command: string,
  subcommand: string = ''
) {
  return function (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const childFn = descriptor.value

    descriptor.value = async function (...args: any) {
      const commandToSend = `${command} ${subcommand}`.trim()
      Socket.send(commandToSend, childFn.apply(this, args))
    }
    return descriptor
  }
}
