export default {
  linkedin: {
    // track onload page if not tracked by Google Tag Manager
    track: true,
    pid: '336049',
    conversionsId: {
      signup: '2202500',
    },
  },
  /**
   * @description SAAS options
   */
  SAAS: {
    upgrade: {
      link: 'https://userbot.typeform.com/to/c9w7vN',
      corporate: 'https://userbot.typeform.com/to/lS7vf3',
    },
  },
  email: {
    sales: 'sales@userbot.ai',
  },
  support: {
    link: 'https://userbot.typeform.com/to/onRvnoiQ',
  },
  supportChat: {
    key: 'cf13f5e2fffa895dd4b7245fcaf16b4a',
    customerToken: '',
  },
}
