import * as VeeValidate from 'vee-validate'
import BotService from '@/assets/js/services/BotService'
import { Vue, Component, Watch } from 'nuxt-property-decorator'
import Mixpanel from '@/assets/js/core/Analytics/Mixpanel'
const VeeValidateLocalize = VeeValidate.localize

Vue.use(VeeValidate as any)

@Component
export default class BaseLayout extends Vue {

  beforeCreate() {
    const locale = (this as unknown as Vue).$i18n.locale
    const messages = require('vee-validate/dist/locale/' + locale + '.json')
    VeeValidateLocalize(locale, messages)

    Vue.mixin({
      localize(localeName: string) {
        // localize your app here, like i18n plugin.
        // asynchronously load the locale file then localize the validator with it.
        import(`vee-validate/dist/locale/${localeName}.json`).then((res) => {
          VeeValidateLocalize(locale, res)
        })
      },
    } as any)
  }

  beforeMount() {
    Mixpanel.init()
  }

  head() {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('description'),
        },
      ],
    }
  }

  @Watch('$route.params.botKey')
  async onBotKeyChange(botKey: string) {
    if (botKey) {
      try {
        const response = await BotService.getOneByKey(botKey)
        return (this as any).$store.commit('page/setBot', response.data())
      } catch {
        return this.$router.push(this.localePath('/bots'))
      }
    }
  }
}
