export interface Sharepoint {
  tenantName: string
  tenantId: string
  clientId: string
  certificate: string
  collections: string[]
  site: string
}

export interface Collections {
  id: string
  name: string
}

export const defautlCollections = [
  'Documents',
  'Form Templates',
  'Site Assets',
  'Site Pages',
  'Style Library',
]
