<template></template>
<script>
export default {
  name: 'UFrillWidget',
  mounted() {
    window.Frill_Config = window.Frill_Config || []
    window.Frill_Config.push({ key: process.env.NUXT_ENV_FRILL_KEY })
    
    const frillScript = document.createElement('script')
    frillScript.src = 'https://widget.frill.co/v2/widget.js'
    frillScript.async = true
    document.body.appendChild(frillScript)
  }
}
</script>
