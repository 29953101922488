import GroupUpdateData from '../interfaces/Services/GroupUpdateData'
import { BaseService } from './BaseService'
import { Response } from '@/assets/js/core/Http/Response'
import GroupPostData from '@/assets/js/interfaces/GroupPostData'

class _GroupService extends BaseService {
  load(): Promise<Response> {
    return this.http.mock().get('groups/list')
  }

  disable(groupsId: number[]): Promise<Response> {
    return this.http.mock().post('groups/disable', {
      group_id: groupsId[0],
    })
  }

  enable(groupsId: number[]): Promise<Response> {
    return this.http.mock().post('groups/enable', {
      group_id: groupsId[0],
    })
  }

  create(group: GroupPostData): Promise<Response> {
    return this.http.mock().post('groups/create', {
      ...group,
    }, false)
  }

  update(id: number, group: GroupUpdateData): Promise<Response> {
    return this.http.mock().post('groups/update', {
      ...group,
      group_id: id,
    })
  }
}

const GroupService = new _GroupService()

export default GroupService
