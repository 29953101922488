import { Context } from '@nuxt/types'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import BotService from '@/assets/js/services/BotService'

export default async function (context: Context) {
  const { store, $auth, params } = context
  if (store.state.page.bot === null) {
    if (params.botKey) {
      const token = $auth.getToken('local')
      Bearer.setToken(token)
      try {
        const response = await BotService.getOneByKey(params.botKey)
        const resBotOptions = await BotService.getOptions(response.data()?.id)
        const resDefaultOptions = await BotService.getDefaultOption()
        store.commit('page/setDefaultOptions', resDefaultOptions.attrs)
        store.commit('page/setBotOptions', resBotOptions.attrs)
        return store.commit('page/setBot', response.data())
      } catch (e: any) {
        console.warn(e.message)
      }
    }
  }
  return true
}
