import { Vue, Component, Prop, Emit, Watch } from 'nuxt-property-decorator'

@Component
export default class USwitch extends Vue {
  @Prop({ type: Boolean, required: false })
  modelValue: boolean

  @Prop({ type: String, required: false, default: '' })
  color: string

  @Prop({ type: Boolean, required: false, default: false })
  disabled: boolean

  @Watch('modelValue', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.checked = val
    }
  }

  @Watch('disabled', { immediate: true })
  onDisabledChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localDisabled = val
    }
  }

  localDisabled: boolean = false
  checked: boolean = false

  toggle(): void | boolean {
    if (!this.localDisabled) {
      this.checked = !this.checked
      this.$emit('update:modelValue', this.checked)
    }
  }
}
