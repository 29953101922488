<template>
  <transition name="fade">
    <div
      v-if="status"
      v-click-outside="onClickOutside"
      class="u-popover"
      :class="{ 'u-popover--opened': status }"
    >
      <div v-if="title" class="u-popover__title">
        <h3>{{ title }}</h3>
      </div>
      <slot name="content">
        <div class="u-popover__content">
          <slot />
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UPopover',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      status: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.status = val
        }
      },
    },
  },
  methods: {
    onClickOutside() {
      this.status = false
      this.$emit('update:modelValue', false)
    },
  },
}
</script>

<style lang="scss">
.u-popover {
  position: absolute;
  background-color: #fff;
  border-radius: $border-radius-default;
  box-shadow: $box-shadow-default;
  will-change: opacity, transform;

  &__title {
    background-color: transparent;
    border: 0;
    padding: var(--padding) 30px;

    h3 {
      color: $grey;
      font-size: 0.75em;
    }
  }

  &__content {
    padding: var(--padding);
  }

  &.fade-leave-active {
    transition: opacity 0.2s linear, transform 0.2s ease !important;
  }

  &.fade-leave,
  &.fade-leave-to {
    opacity: 0 !important;
  }

  &--opened {
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
  }
}
</style>
