/**
 * This animation brings the items from left to right and show them one by one.
 */

import { Vue, Component } from 'vue-property-decorator'
import { gsap } from "gsap";

@Component
export default class AnimationListMixins extends Vue {
  onListBeforeEnter(el: HTMLElement) {
    el.style.opacity = '0'
    gsap.to(el, 0.3, {
      opacity: 0,
      x: -40,
      y: 0,
    })
  }

  onListEnter(el: HTMLElement, done: Function) {
    const index = parseInt(el.dataset.index || '')
    const delay = index * 150
    setTimeout(() => {
      gsap.to(el, 0.3, {
        opacity: 1,
        x: 0,
        y: 0,
        onComplete: () => {
          done(el, index)
        },
      })
    }, delay)
  }

  onListLeave(el: HTMLElement, done: Function) {
    gsap.to(el, 0.3, {
      opacity: 0,
      x: 40,
      y: 0,
      onComplete: done,
    })
  }
}
