/* eslint-disable camelcase */
import axios from 'axios'
import { Api } from '../core/Api'
import { BaseService } from './BaseService'
import { apiUrl } from '@/modules/api'
import UrlAsDoc from '@/assets/js/interfaces/UrlAsDoc'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import { setGeneralData, setTarget } from '@/assets/js/interfaces/Services/GenerativeAI'

export default class GenerativeAiSerivce extends BaseService {
  constructor(private readonly botId: number) {
    super()
  }

  getGeneralData() {
    return Api.get(`bot/${this.botId}/generative-ai`)
  }

  setGeneralData(data: setGeneralData) {
    return Api.post(`bot/${this.botId}/generative-ai`, data)
  }

  getTarget() {
    return Api.get(`bot/${this.botId}/generative-ai/target/?primary=true`)
  }

  setTarget(data: setTarget) {
    return Api.post(`bot/${this.botId}/generative-ai/target`, data)
  }

  getDocuments() {
    return Api.get(`bot/${this.botId}/generative-ai/docs`)
  }

  deleteDocuments(id: number) {
    return Api.delete(`bot/${this.botId}/generative-ai/docs/${id}`)
  }

  pdfConvert(docId: number) {
    return axios({
      url: apiUrl(`/v1/pdf/convert`),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        botId: this.botId,
        docId,
      },
    })
  }

  getWebPages() {
    return Api.get(`bot/${this.botId}/generative-ai/url`)
  }

  addWebPages(data: UrlAsDoc) {
    return Api.post(`bot/${this.botId}/generative-ai/url`, data)
  }

  refreshWebPage(data: {id: number, config: UrlAsDoc}) {
    return Api.post(`bot/${this.botId}/generative-ai/url/${data.id}/refresh`, data.config)
  }

  deleteWebPages(id: number) {
    return Api.delete(`bot/${this.botId}/generative-ai/url/${id}`)
  }

  getBotDocUrl(botDocId: number) {
    return Api.get(`bot/${this.botId}/generative-ai/docs/${botDocId}/url`)
  }
}
