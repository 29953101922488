import { BaseService } from './BaseService'

class _StripeService extends BaseService {
  async getCustomerPortalUrl() {
    try {
      const response = await this.http2.get('customer-portal')
      return response.data().url || ''
    } catch (e) {
      console.warn(e)
    }
  }
}

const StripeService = new _StripeService()

export default StripeService
