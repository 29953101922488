/* eslint-disable camelcase */
import axios from 'axios'
import { Api } from '../core/Api'
import { BaseService } from './BaseService'
import env from '@/env'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import { Interaction } from '@/assets/js/models/Interaction/Interaction'
import { apiUrl } from '@/modules/api'

type TrainingMergeData = {
  answers: Array<{ name: string }>
  buttons: []
  'fallback-team': string
  flows_id: number
  flows_startMessageLabel: string
  groups: string[]
  label: string
  name: string
  questions: {
    name: string
    deep_learning: boolean
  }[]
}

type TrainingMergeParams = {
  data: TrainingMergeData
  labels: string[]
}

export default class TrainingService extends BaseService {
  constructor(private readonly botId: string) {
    super()
  }

  async getInteractions(params: any) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training`),
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      params: {
        ...params,
        // intent: ''
      },
    })
  }

  async saveInteraction(interaction: Interaction) {
    const interactionJson = interaction.toJson()
    if (interactionJson['fallback-team'] === '1') {
      interactionJson.fallbackTeam = '301'
    } else {
      interactionJson.fallbackTeam = '404'
    }
    delete interactionJson['fallback-team']
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training`),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: interactionJson,
    })
  }

  getGroupsList() {
    return Api.get(`bot/${this.botId}/training/groups`)
  }

  async deleteInteraction(labels: string[]) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training`),
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        label: labels,
      },
    })
  }

  async getToApproveInteractions(data: any) {
    return await axios({
      url: apiUrl(
        `/v1/bot/${this.botId}/training/to-approve`
      ),
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      params: {
        limit: data.limit,
        offset: data.offset,
        recommended: data.recommended ? 1 : 0,
        search: data.search,
        verify: data.toBeVerified ? 1 : 0,
      },
    })
  }

  async count(query: string) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/count`),
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      params: {
        query,
      },
    })
  }

  async getRecentInteractions() {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/recent`),
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
    })
  }

  async hideOne(qeaId: number) {
    return await axios({
      url: apiUrl(
        `/v1/bot/${this.botId}/training/${qeaId}/hide`
      ),
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
    })
  }

  async updateGroups(params: { label: string; groups: string[] }) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/groups`),
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        ...params,
      },
    })
  }

  async addGroups(params: { label: string[]; groups: string[] }) {
    return await axios({
      url: apiUrl(
        `/v1/bot/${this.botId}/training/groups/add`
      ),
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        ...params,
      },
    })
  }

  async approve(qeaId: number, params: { label: string; intent: string }) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/${qeaId}/approve`),
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        token: 'approve',
        ...params,
      },
    })
  }

  async merge(params: TrainingMergeParams) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/merge`),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        ...params,
      },
    })
  }

  async loadOne(label: string) {
    return await axios({
      url: apiUrl(`/v1/bot/${this.botId}/training/load`),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        label,
      },
    })
  }

  async download() {
    return await axios({
      url: apiUrl(
        `/v1/bot/${this.botId}/training/export-training`
      ),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
    })
  }

  async import(params: any) {
    return await axios({
      url: apiUrl(
        `/v1/bot/${this.botId}/training/import-training`
      ),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
      data: {
        ...params,
      },
    })
  }
}
