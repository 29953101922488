import merge from 'lodash/merge'

class PluginManagerClass {
  /**
   * @description set plugins array
   * @param pluginsArray
   */
  setPlugins(pluginsArray) {
    this.plugins = pluginsArray
    this.pluginsConfig = []
    /* pluginsArray.forEach((name) => {
      this.pluginsConfig.push(
        require(`@/assets/js/plugins/${name}/index.js`).default
      )
    }) */
  }

  /**
   * @description get path config
   * @param path
   */
  getConfig(path) {
    if (this.pluginsConfig) {
      path = path.split('.')
      const values = []
      this.pluginsConfig.forEach((config) => {
        if (config) {
          let value = config
          path.forEach((step) => {
            if (value) {
              value = value[step]
            }
          })

          if (value) {
            values.push(value)
          }
        }
      })
      return values
    }
    return null
  }

  /**
   * @description merge translations
   * @param translations
   * @param lang
   * @return {any}
   */
  mergeTranslations(translations, lang = 'it') {
    const configLangs = this.getConfig('langs')
    const pluginLangs = configLangs.length
      ? configLangs.flat().reduce((accumulator, currentValue) => {
          accumulator = merge(accumulator, currentValue)
          return accumulator
        })[lang]
      : []
    return Object.assign({}, translations, pluginLangs)
  }

  /**
   * @description register vue components
   * @param Vue
   */
  registerComponents(Vue) {
    const components = this.getConfig('components')

    components.forEach((pluginComponent) => {
      Vue.component(
        Object.keys(pluginComponent)[0],
        Object.values(pluginComponent)[0]
      )
    })
  }
}

export const PluginManager = new PluginManagerClass()
