import PricePlan from '@/assets/js/interfaces/PricePlan'

export default {
  id: 'sales-professional',
  name: 'Professional',
  disabled: false,
  stripe_key: {
    month: 'products.license.sales_professional.month',
  },
  price: {
    monthly: 199,
  },
} as PricePlan
