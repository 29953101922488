import { HttpError } from '@/assets/js/core/Http/HttpError'

export class Response {
  attrs: any = {}

  status: number | null = null

  constructor(attrs: any) {
    if (attrs.data) {
      this.attrs = attrs.data
      this.status = attrs.data.success === 'OK' ? 200 : null
      this.attrs.success = attrs.data.success
    } else {
      this.attrs = attrs
    }
  }

  data(): any {
    if (this.attrs.data) {
      if (this.attrs.data.items) {
        return this.attrs.data.items
      }
      return this.attrs.data
    }
  }

  succeed(): boolean {
    return (
      ((this.attrs.statusCode >= 200 && this.attrs.statusCode < 300) ||
        (!this.attrs.statusCode && this.status === 200) ||
        this.attrs.success === 'OK') &&
      this.attrs.success !== 'KO'
    )
  }

  failed(): boolean {
    return (
      this.attrs.statusCode === 403 ||
      this.attrs.statusCode === 400 ||
      this.attrs.statusCode === 408 ||
      this.attrs.statusCode === 503 ||
      this.attrs.success !== 'KO'
    )
  }

  errors(): HttpError[] {
    const errors = []
    if (this.attrs) {
      if (this.attrs.errorCode) {
        errors.push(
          new HttpError({
            message: this.attrs.errorMessage,
            code: this.attrs.errorCode,
          })
        )
      } else {
        errors.push(
          new HttpError({
            message: this.attrs.message
              ? this.attrs.message
              : 'Qualcosa non ha funzionato!',
          })
        )
      }
    }
    return errors
  }

  error(): HttpError {
    return this.errors()[0]
  }

  /**
   * check if response has bearer token
   * @return {boolean}
   */
  hasBearer() {
    return !!this.getBearer()
  }

  /**
   * get bearer token
   * @return {string}
   */
  getBearer() {
    return this.attrs.data.bearer
  }

  /**
   * Check if on sign up process user can login
   */
  canLogin() {
    return this.attrs.login === true
  }

  getStatus(): number {
    return this.attrs.statusCode || this.attrs.status
  }
}
