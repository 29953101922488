import { render, staticRenderFns } from "./badge.html?vue&type=template&id=7632e328&scoped=true&external"
import script from "./badge.js?vue&type=script&lang=js&external"
export * from "./badge.js?vue&type=script&lang=js&external"
import style0 from "./badge.scss?vue&type=style&index=0&id=7632e328&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7632e328",
  null
  
)

export default component.exports