import { Vue, Component, Ref, Prop, Watch, Emit } from 'nuxt-property-decorator'
import Key from '@/assets/js/core/Key'
@Component
export default class UDatalist extends Vue {
  @Ref('input') input: HTMLDivElement

  @Prop({ type: String, required: false })
  modelValue: string

  @Prop({ type: Object, required: false })
  callbacks: object

  @Prop({ type: String, required: false, default: '' })
  placeholder: string

  @Prop({ type: Number, required: false })
  tabindex: number

  @Prop({ type: [Object, Array], required: false, default: [] })
  source: any | []

  @Prop({ type: Boolean, required: false, default: false })
  group: boolean

  @Prop({ type: Boolean, required: false, default: false })
  allowNew: boolean

  @Prop({ type: Array, required: false })
  exclude: []

  @Prop({ type: Boolean, required: false })
  disabled: boolean

  @Prop({ type: Boolean, required: false, default: false })
  requireSelect: boolean

  @Prop({ type: Boolean, required: false, default: false })
  dropup: boolean

  @Prop({ type: Object, required: false })
  inputOptions: object

  /**
   * @description text to search
   */
  text: string = ''

  /**
   * @description first text value
   */
  historicText: string = ''

  /**
   * @description group selected
   */
  groupSelected: any = null

  defaultGroup: string = 'Tutti'

  /**
   * @description edited property
   */
  dirty: boolean = false

  /**
   * @description selected item by arrow
   */
  selectedItemByArrows: any = null

  /**
   * @description disabled status
   */
  disableDatalist: boolean = false

  dropdownStatus = false
  hover = false
  formVisible = false

  private selected: any

  get groups() {
    return [this.defaultGroup].concat(Object.keys(this.source))
  }

  get matches() {
    if (this.text) {
      if (this.groupSelected) {
        const obj: any = {}
        obj[this.groupSelected] = this.source[this.groupSelected]
        return obj
      }
      return this.source
    }
    return this.source
  }

  @Watch('modelValue', { immediate: true })
  onValueChange(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.text = val
    }
  }

  @Watch('text')
  onTextChange(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.$emit('change', val)
    }
  }

  @Watch('groupSelected')
  onGroupSelectedChange(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.text = ''
    }
  }

  @Watch('disabled', { immediate: true })
  onDisabledChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.disableDatalist = val
    }
  }

  static emits = [
    'mounted',
    'selected',
    'input',
    'focus',
    'keydown',
    'blur',
    'hide',
  ]

  @Emit('mounted')
  async mounted() {
    this.historicText = this.modelValue
    await this.$nextTick()
    return true
  }

  /**
   * @description cancel input
   */
  @Emit('selected')
  cancel() {
    this.text = ''
    this.focus()
    this.emitInput()
    return null
  }

  /**
   * @description select item
   * @param attribute
   * @param item
   */
  @Emit('selected')
  select(attribute: any, item: any = false) {
    if (!item) {
      item = {
        value: attribute,
      }
    }

    this.text = item.value ? item.value : item[attribute]
    this.dirty = false
    this.selected = item
    this.hideDropdown()
    this.emitInput()
    return item
  }

  /**
   * @description select group filter
   */
  selectGroup(group: any) {
    if (group === this.defaultGroup) {
      this.groupSelected = null
    } else {
      this.groupSelected = group
      this.dropdownStatus = false
    }
  }

  /**
   * @description check if there are matches
   */
  areThereMatches() {
    if (Array.isArray(this.matches)) {
      return this.matches.length
    } else {
      const keys = Object.keys(this.matches)
      let match = false

      keys.forEach((key) => {
        if (this.matches[key].data.length) {
          match = true
        }
      })
      return match
    }
  }

  /**
   * @description filter data by text
   */
  filterDataByText(value: any) {
    if (value.filter && this.historicText !== this.text) {
      const text = this.text?.toLowerCase() ? this.text.toLowerCase() : ''

      const data = value.data.filter((item: any) => {
        if (this.exclude) {
          const isInExcludeArray = this.exclude.some((int: []) => {
            const name = Array.isArray(int) ? int.join(',') : int
            return name === item.name
          })
          if (isInExcludeArray) return
        }

        if (text) {
          return (
            item[value.display].toLowerCase().includes(text) ||
            (item.label && item.label.toLowerCase().includes(text))
          )
        } else {
          return true
        }
      })

      return data
    }
    return value.data
  }

  /**
   * @description emit input value
   */
  @Emit('update:modelValue')
  emitInput() {
    return this.text ? this.text.trim() : ''
  }

  /**
   * @description emit focus event
   */
  @Emit('focus')
  emitFocus(e: InputEvent) {
    this.dropdownStatus = true
    return e
  }

  /**
   * @description emit keydown event
   */
  @Emit('keydown')
  emitKeydown(e: KeyboardEvent) {
    if (Key.isEsc(e)) {
      this.selectedItemByArrows = null
      this.dirty = false
      this.dropdownStatus = false
      e.preventDefault()
    } else {
      this.dropdownStatus = true
      this.dirty = true

      if (Key.isDownArrow(e)) {
        this.selectedItemByArrows =
          this.selectedItemByArrows === null ? 0 : this.selectedItemByArrows + 1
        if (this.selectedItemByArrows > this.allSources().length - 1) {
          this.selectedItemByArrows = 0
        }
        e.preventDefault()
        return
      }

      if (Key.isUpArrow(e)) {
        this.selectedItemByArrows =
          this.selectedItemByArrows <= 0
            ? this.allSources().length - 1
            : this.selectedItemByArrows - 1
        e.preventDefault()
        return
      }

      if (Key.isEnter(e)) {
        const item = this.allSources()[this.selectedItemByArrows]

        if (item) {
          this.select(this.getAttributeToDisplay(item), item)
        } else if (this.allowNew) {
          this.create(this.text)
        }
        e.preventDefault()
        return
      }
    }

    return e
  }

  @Emit('blur')
  emitBlur(e: InputEvent) {
    this.selectedItemByArrows = null

    /* if (!this.selected && this.dirty) {
      if (this.historicText !== this.text) {
        this.text = ''
      }
    }

    if (!this.hover) {
      this.dropdownStatus = false
    } */

    // this.$emit('update:modelValue', this.text)
    return e
  }

  /**
   * @description focus on input
   */
  focus() {
    this.input.focus()
  }

  create(text: string) {
    if (text.trim().length) {
      this.dirty = false
      this.dropdownStatus = false
      this.$emit('create', text)
    }
  }

  /**
   * @description selected item by arrows
   * @param item
   * @returns {boolean}
   */
  isActiveLi(item: any) {
    const i = this.allSources().indexOf(item)
    if (i === -1) return false
    return this.selectedItemByArrows === i
  }

  /**
   * @description get all sources in one array
   * @returns {Array}
   */
  allSources() {
    if (Array.isArray(this.matches)) {
      return this.matches
    } else {
      let sources: any = []

      const keys = Object.keys(this.matches)

      keys.forEach((key) => {
        sources = sources.concat(this.filterDataByText(this.matches[key]))
      })
      return sources
    }
  }

  /**
   * @description get display attribute value
   * @param item
   * @private
   */
  private getAttributeToDisplay(item: any) {
    let attribute = ''

    if (this.groupSelected) {
      attribute = this.matches[this.groupSelected].display
    } else {
      for (const key in this.matches) {
        if (this.matches[key].data) {
          this.matches[key].data.forEach((i: any) => {
            if (i === item) {
              attribute = this.matches[key].display
            }
          })
        }
      }
    }
    return attribute
  }

  showFormCreational() {
    this.formVisible = true
  }

  isSearchEmpty() {
    if (this.text && this.text.length) {
      return Object.values(this.matches).every((data: any) => {
        if (data.filter) {
          return this.filterDataByText(data)?.length === 0
        }
        return false
      })
    }
    return false
  }

  canAddNew() {
    if (!this.allowNew) {
      return false
    }

    if (!this.text?.trim().length) {
      return false
    }

    return !this.allSources().some((v: { id: string; label: string }) => {
      return v.label === this.text?.trim()
    })
  }

  backToList() {
    this.formVisible = false
  }

  @Emit('hide')
  hideDropdown() {
    this.dropdownStatus = false
    this.formVisible = false
  }

  private onClickOutside() {
    this.hideDropdown()
  }

  private onMouseEnter() {
    this.hover = true
  }

  private onMouseLeave() {
    this.hover = false
  }
}
