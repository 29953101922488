/* eslint-disable camelcase */
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'
import { localStorage } from 'nuxt-storage'
import { InteractionButton } from './InteractionButton'
import DeepLearningType from './types/DeepLearningType'
import TextType from './types/TextType'
import InteractionCheckProperty from './InteractionCheckProperty'
import { Model } from '@/assets/js/core/Model'
import {
  remove_json,
  extract_json,
  trim,
  truncate,
  media_links_to_emoji,
} from '@/assets/js/core/helpers'
import { Question } from '@/assets/js/models/Interaction/Question'
import { hasPermission } from '@/modules/auth'

let _lastKey = 0

export class Interaction extends Model {
  /**
   * @description new Interaction
   * @param attrs
   */
  constructor(attrs = {}) {
    attrs.plugins = {}
    super(attrs)

    this.set(
      'answers',
      attrs.answer_nl ? attrs.answer_nl.map((item) => remove_json(item)) : []
    )
    this.set('questions', this._parseQuestions(attrs.records))
    this.set('created_at', attrs.sys_create ? attrs.sys_create : attrs.quando)
    this.set('to_approve', attrs.ai === 1)

    if (!this.get('buttons') || !this.get('buttons').length) {
      this.set('buttons', this._getButtonsFromAnswer())
    }

    const upscale = this.get('fallback') === '301'
    this.set('contact_team', upscale)
    const index = upscale ? 2 : attrs.flows_id ? 1 : 0
    const type = this.optionsCallbackType()[index]
    this.setType(type)
    this._preloadGroups()
  }

  /**
   * @description create id if not present
   */
  id() {
    if (this.get('id')) {
      return this.get('id')
    }

    return this.name()
      .replace(/\s/g, '-')
      .replace(/\\/, '')
      .replace(/</, '')
      .replace(/>/, '')
      .replace(/'/g, '')
      .replace(/"/g, '')
      .replace(/(#|!|)/g, '')
      .toLowerCase()
  }

  /**
   * @description get name
   * @returns {*}
   */
  name() {
    if (this.get('name')) {
      return this.get('name')
    }
    if (this.get('label')) {
      return this.get('label')
    }
    return ''
  }

  /**
   * @description add Button
   * @param button
   * @return {Interaction}
   */
  addButton(button = null) {
    if (!this.get('buttons')) {
      this.set('buttons', [])
    }

    this.attributes.buttons.push(
      new InteractionButton(
        button || {
          key: `${uniqueId('button-id-')}`,
          title: '',
          action: 'goto',
        }
      )
    )

    return this
  }

  /**
   * @description get words of AND rules
   * @return {Array}
   */
  softRuleAND() {
    return this.softRule('AND')
  }

  /**
   * @description get words of OR rules
   * @return {Array}
   */
  softRuleOR() {
    return this.softRule('OR')
  }

  /**
   * @description get array for soft rule
   * @param rule
   */
  softRule(rule) {
    return this.get('questions')
      .map((q) => {
        if (Array.isArray(q.question_nl)) {
          return q.question_nl.map((item) => item.substr(1))
        }
      })
      .filter((item) => !!item)
  }

  /**
   * @description return array cleaned from +
   * @param array
   * @return {*}
   * @private
   */
  _cleanRules(array) {
    if (Array.isArray(array)) {
      return array.map((item) => {
        return item.trim().substring(0, 1) === '+'
          ? item.trim().substr(1)
          : item
      })
    }
    return array || []
  }

  /**
   * @description return answer text cleared
   * @return {string}
   */
  answer() {
    return (
      (this.get('flows_id')
        ? this.get('flows_name')
        : remove_json(this.get('answers')[0])) || ''
    )
  }

  /**
   * @description return answer preview
   * @returns {string}
   */
  answerPreview(partial = true) {
    return partial
      ? truncate(media_links_to_emoji(this.answer()), 50)
      : media_links_to_emoji(this.answer())
  }

  /**
   * @description return answer text cleared
   * @param n
   * @return {string}
   */
  answerTrimmed(n = 50) {
    if (this.get('flows_id')) {
      return this.answer()
    }
    const answer = remove_json(media_links_to_emoji(this.get('answers')[0]))
    return trim(answer, n)
  }

  /**
   * @description return answer text cleared
   * @return {string}
   */
  question() {
    if (!Array.isArray(this.get('question_nl'))) {
      const a = remove_json(this.get('question_nl'))

      if (a) return a

      if (this.get('questions')) {
        return this.get('questions')[0].get('question_nl')
      }

      return ''
    } else {
      return this._cleanRules(this.get('question_nl')).join(', ')
    }
  }

  /**
   * @description add empty question
   */
  addEmptyQuestion(type = null) {
    if (type === null) {
      this._addLastEmptyQuestionTypeClicked()
    } else if (type instanceof TextType) {
      this.addStrictQuestion()
    } else if (type instanceof DeepLearningType) {
      this.addDeepLearningQuestion()
      return this
    } else this.addTagQuestion()

    return this
  }

  /**
   * @description add empty question
   * @private
   */
  _addLastEmptyQuestionTypeClicked() {
    let fromLocalStorage = localStorage?.getItem('defaultQuestionType')

    if (!hasPermission('AIDeepLearning')) {
      fromLocalStorage = fromLocalStorage === 'LIKE' ? 'TAG' : fromLocalStorage
    }

    switch (fromLocalStorage) {
      case 'LIKE':
        this.addDeepLearningQuestion()
        break

      case 'STRICT':
        this.addStrictQuestion()
        break

      default:
        this.addTagQuestion()
        break
    }
  }

  /**
   * @description add a new DeepLearning question
   * @return this
   */
  addDeepLearningQuestion() {
    let keyToAdd = this.get('questions').length
    for (const key in this.get('questions')) {
      const q = this.get('questions')[key]
      const nextQ = this.get('questions')[Number(key) + 1]
      if (q.isDeepLearning()) {
        if (!nextQ || !nextQ.isDeepLearning()) {
          keyToAdd = Number(key) + 1
          break
        }
      }
    }

    this.get('questions').splice(
      keyToAdd,
      0,
      new Question({
        id: '',
        question_nl: '',
        key: this._getKeyQuestion(),
        type: new DeepLearningType(),
      })
    )

    return this
  }

  /**
   * @description add new STRICT question
   */
  addStrictQuestion() {
    this.get('questions').push(
      new Question({
        id: '',
        question_nl: '',
        key: this._getKeyQuestion(),
      })
    )

    return this
  }

  /**
   * @description add new TAG question
   */
  addTagQuestion() {
    this.get('questions').push(
      new Question({
        id: '',
        question_nl: [],
        key: this._getKeyQuestion(),
      })
    )

    return this
  }

  /**
   * @description get all questions
   * @return {any}
   */
  questions() {
    return this.get('questions')
  }

  /**
   * @description get valid questions
   * @return {*}
   */
  validQuestions() {
    return this.questions().filter((q) => {
      return q.isValid()
    })
  }

  /**
   * @description add questions
   * @param array
   */
  addQuestions(array) {
    const questions = array.map((q) => {
      q.set('key', this._getKeyQuestion())
      return q
    })
    this.set('questions', this.questions().concat(questions))
    return this
  }

  removeQuestionByIndex(i) {
    this.attributes.questions.splice(i, 1)
  }

  /**
   * @description get DeepLearningType questions
   */
  getDeepLearningQuestions() {
    return this.questions().filter((q) => {
      return q.type() instanceof DeepLearningType
    })
  }

  /**
   * @description get created_at
   * @return moment
   */
  created_at() {
    const when = this.get('created_at')
    if (when) {
      return moment.unix(when)
    }
    return null
  }

  /**
   * @description created at in default format
   */
  created_at_default_format() {
    if (this.created_at()) {
      return this.created_at().format('DD/MM/YYYY - HH:mm')
    }
    return ''
  }

  /**
   * @description approve interaction
   */
  approve() {
    this.set('ai', 'approved')
    this.set('to_approve', false)
    return this
  }

  /**
   * @description approve interaction
   */
  disapprove() {
    this.set('ai', 0)
    this.set('to_approve', true)
    return this
  }

  /**
   * @description return json to save to backend
   * @returns {json}
   */
  toJson() {
    const questions = this.validQuestions().map((item) => {
      return {
        name: this._cleanRules(item.get('question_nl')),
        id: item.get('id'),
        deep_learning: item.isDeepLearning(),
      }
    })

    const data = {
      label: this.originals.label,
      name: this._getNameToSave(),
      groups: this.get('groups'),
      questions,
      answers: this.get('answers').map((item) => {
        return { name: item }
      }),
      flows_id: this.get('flows_id'),
      flows_startMessageLabel: this.get('flows_startMessageLabel')
        ? this.get('flows_startMessageLabel')
        : this.get('flows_startingPoint'),
      'fallback-team': (this.get('contact_team') ? 1 : 0).toString(),
    }

    if (this.get('buttons')) {
      data.buttons = this.get('buttons')
        .filter((btn) => btn.get('title').trim())
        .map((btn) => btn.toJson())
    } else data.buttons = []

    return Object.assign({}, data, this.get('plugins'))
  }

  /**
   * @description get interaction copy
   */
  getCopy(attrs) {
    attrs = Object.assign(
      {
        created_at: new Date(),
        label: '',
        name: '',
        id: '',
      },
      attrs
    )

    const copiedInteraction = cloneDeep(this)

    for (const name in attrs) {
      copiedInteraction.setDeep(name, attrs[name])
    }

    copiedInteraction.questions().forEach((item) => delete item.attributes.id)

    return copiedInteraction
  }

  /**
   * @description get type of interaction
   * @returns {*}
   */
  callbackType() {
    return this._callbackType
  }

  /**
   * @description set type of interaction
   * @param option
   */
  setType(option) {
    if (option.type === 'flow') {
      this.set('buttons', [])
      this.set('answers', [])
      this.set('contact_team', false)
    } else {
      this.attributes.flows_id = null
      this.attributes.flows_startMessageLabel = null
      delete this.attributes.flows_name
      delete this.attributes.flow
      this.set('contact_team', option.type === 'upscale')
    }

    this._callbackType = this.optionsCallbackType().find((c) => {
      return c.type === option.type
    })
  }

  getType() {
    if (this.get('contact_team')) {
      return 'upscale'
    }

    return this._callbackType.type
  }

  /**
   * @description dropdown options on response
   * @return {array}
   */
  optionsCallbackType() {
    return [
      {
        label: 'with-a-message',
        icon: 'chat',
        description: 'with-a-message-description',
        type: 'default',
      },
      {
        label: 'with-a-flow',
        icon: 'flows',
        description: 'with-a-flow-description',
        type: 'flow',
      },
      {
        label: 'upscaling-to-agent',
        icon: 'users',
        description: 'upscaling-to-agent-description',
        type: 'upscale',
      },
    ]
  }

  /**
   * @default get buttons from answer
   * @return {Array}
   */
  _getButtonsFromAnswer() {
    let json

    if (this.get('answer_nl')) {
      json = extract_json(this.get('answer_nl')[0])

      if (json?.buttons) {
        json.buttons =
          json.buttons
            .filter((item) => item)
            .map((item) => {
              item.key = uniqueId('button-id-')
              return new InteractionButton(item)
            }) ?? []
      }
    }

    if (json?.buttons?.length) {
      return json.buttons
    } else {
      return this.get('buttons') ?? []
    }
  }

  /**
   * @description get groups
   */
  _preloadGroups() {
    if (!Array.isArray(this.get('groups'))) {
      let groups = []
      if (this.get('groups') && this.get('groups').length) {
        groups = this.get('groups').split(', ')
        if (groups.length) {
          groups = groups.map((item, i) => {
            return {
              id: i,
              name: item,
            }
          })
        }
      }
      this.set('groups', groups)
    }
  }

  /**
   * @description set answer text
   * @param text
   */
  setAnswer(text) {
    this.set('answer_nl', text)
    this.set('answers', [text])
  }

  /**
   * @description parse questions from backend
   * @param records
   * @return {Array}
   * @private
   */
  _parseQuestions(records) {
    if (records && records.length) {
      records = this._groupDeepLearningQuestions(records)
    }

    return records
      ? records.map((item, key) => {
          if (!(item instanceof Question)) {
            item.question_nl = this._cleanRules(item.question_nl)
            item.key = this._getKeyQuestion()
            if (this.get('ai') === 0) {
              item.deep_learning = true
            }
            return new Question(item)
          }
          return item
        })
      : []
  }

  /**
   * @description group deep learning questions
   * @param records
   * @return {*}
   * @private
   */
  _groupDeepLearningQuestions(records = []) {
    let first = null
    const deepLearnings = []

    records.forEach((r, key) => {
      if (r.deep_learning === true) {
        if (first === null) first = key
        else deepLearnings.push(r)
      }
    })

    if (deepLearnings.length) {
      records = records.filter((r, key) => {
        return key === first || !r.deep_learning
      })
      records.splice(first, 0, ...deepLearnings)
    }

    return records
  }

  /**
   * @description get key question
   * @private
   */
  _getKeyQuestion() {
    ++_lastKey
    return this.id() + '-question-' + _lastKey
  }

  /**
   * @description save training status
   * @param value
   */
  inTraining(value) {
    this._training_status = !!value
    return this
  }

  /**
   * @description count number of thumbs up
   * @return {integer}
   */
  countThumbsUp() {
    let count = 0
    this.get('records').forEach((a) => {
      count += a.thumbsUp ? a.thumbsUp : 0
    })
    return count
  }

  /**
   * @description count number of thumbs down
   * @return {integer}
   */
  countThumbsDown() {
    let count = 0
    this.get('records').forEach((a) => {
      count += a.thumbsDown ? a.thumbsDown : 0
    })
    return count
  }

  /**
   * @description get best predicted label from AI
   * @return {boolean|string}
   */
  getBestMatchPredictedInteractionIntent() {
    try {
      const predicted = JSON.parse(this.get('records')[0].predicted)
      return predicted.best_match.intent
    } catch (e) {
      return false
    }
  }

  /**
   * @description return matches ai
   * @return {array|boolean}
   */
  getPredictedInteractionsIntent() {
    try {
      const predicted = JSON.parse(this.get('records')[0].predicted)
      return predicted.results
    } catch (e) {
      return false
    }
  }

  /**
   * @description update last interactions
   * @param bot
   * @param array
   */
  static async updateLastInserted(bot, array) {
    array = array.map((item) => {
      item.bot_id = bot.id()
      return item
    })
    return array.map((item) => new this(item))
  }

  /**
   * @description clear last inserted
   * @return {Promise<*>}
   */
  static async clearLastInserted() {
    return []
  }

  _getNameToSave() {
    if (this.name()) {
      return this.name()
    }

    const firstQuestion = this.validQuestions()[0]
    const questionNl = firstQuestion.get('question_nl')
    const name = Array.isArray(questionNl)
      ? firstQuestion.get('question_nl').join(' ')
      : questionNl

    if (name) {
      return truncate(name, 47)
    }

    return ''
  }
}

Object.assign(Interaction.prototype, InteractionCheckProperty)
