import Vue from 'vue'

/**
 * Import Autosize
 */
const setWidth = function (el) {
  const paddingLeft = parseInt(
    window.getComputedStyle(el, null).getPropertyValue('padding-left')
  )
  const paddingRight = parseInt(
    window.getComputedStyle(el, null).getPropertyValue('padding-right')
  )
  const padding = paddingLeft + paddingRight
  el.style.width = (el.value.length + 1) * 8 + (padding || 0) + 'px'
  return el
}

const fn = function (el, timeout = 0) {
  setTimeout(() => setWidth(el), timeout)
}

export const Autowidth = {
  update(el, binding, vnode) {
    setWidth(el)
  },
  bind(el, binding, vnode) {
    el.addEventListener('keypress', function () {
      fn(el)
    })
    el.addEventListener('focus', function () {
      fn(el, 300)
    })
    el.addEventListener('blur', function () {
      fn(el)
    })
    setWidth(el)
  },
  unbind(el) {
    el.removeEventListener('keypress', function () {
      fn(el)
    })
    el.removeEventListener('focus', function () {
      fn(el, 300)
    })
    el.removeEventListener('blur', function () {
      fn(el)
    })
  },
}

Vue.directive('autowidth', Autowidth)
