import { Socket } from '../core/Socket'

export default function WebSocketSubscribeEvent(
  command: string,
  subcommand: string | undefined = undefined,
  {
    data,
  }: {
    data: string
  } = {
    data: '',
  }
) {
  return function (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    /* const childFn = descriptor.value */
    descriptor.value = function (cb: Function, cbError?: Function) {
      Socket.on(
        command,
        (_c: string, response: any) => {
          try {
            if (response.subcommand === subcommand) {
              if (
                Number(response.status) === 200 ||
                Number(response.statuscode) === 200
              ) {
                return cb(data ? response[data] : response.data)
              } else {
                throw new Error(response)
              }
            }
          } catch (e) {
            return cbError ? cbError(e) : null
          }
        },
        '',
        {
          callbackToRegister: cb,
        }
      )
    }
    return descriptor
  }
}
