import SocketModel from '@/assets/js/core/Socket/SocketModel'

class _SocketFlow extends SocketModel {
  /**
   * @description on socket flow command
   * @param callback
   */
  onCreate(callback) {
    this.on('store', callback)
    return this
  }

  /**
   * @description on socket flow load command
   * @param callback
   */
  onLoad(callback) {
    this.on('load', callback)
    return this
  }

  /**
   * @description on socket flow publish command
   * @param callback
   */
  onPublish(callback) {
    this.on('publish', callback)
    return this
  }

  /**
   * @description create flow
   * @param options
   * @param token
   */
  create(options, token) {
    this.send(`store ${token ? `<${token}>` : ''}`, options)
    return this
  }

  /**
   * @description load flow
   * @param id
   * @param token
   */
  load(id, token = '') {
    this.send(`load ${token ? `<${token}> ` : ''}${id}`)
    return this
  }

  /**
   * @description publish flow
   * @param id
   * @param data
   * @param token
   */
  publish(id, data, token = '') {
    this.send(`publish ${token ? `<${token}>` : ''}`, { id, data })
  }
}

export const SocketFlow = new _SocketFlow('flow')
