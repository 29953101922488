import { locale } from '../i18n'

interface YoutubeVideo {
  id: string
  duration: string
}

function getVideo(): YoutubeVideo {
  switch (locale) {
    case 'it':
      return {
        id: '7ghebzvMiUQ',
        duration: '7:25',
      }

    default:
      return {
        id: '6KGZkdVcuS8',
        duration: '6:40',
      }
  }
}

const video = getVideo()

export default video
