interface importTrainingState {
  flag: 0 | 1
  timestamp: string
}
export default {
  state: () => ({
    flag: 0,
    timestamp: '',
  }),
  mutations: {
    updateFlag(state: importTrainingState, value: 0 | 1) {
      state.flag = value
    },
    updateTimestamp(state: importTrainingState, value: string) {
      state.timestamp = value
    },
  },
}
