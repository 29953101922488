import { Socket } from '@/assets/js/core/Socket'
import ResponseVar from '@/assets/js/interfaces/Vars/ResponseVar'

export default class GenericVar {
  name: string
  socket: any
  callees: Function[]

  constructor(name: string) {
    this.name = name
    this.socket = Socket
    this.callees = []

    this.listen()
  }

  retrieve(fn: Function | null = null) {
    if (fn) {
      this.on(fn)
    }
    this.socket.send(`retrieve ${this.name} bot`)
  }

  store(value: string) {
    this.socket.send(`store ${this.name} bot ${value}`)
  }

  listen(fn: Function | null = null) {
    this.socket.on('retrieve', (_: string, data: ResponseVar) => {
      if (data.text.includes(this.name)) {
        this.callees.forEach((fn: Function) =>
          fn(data.record[0], data.record[0]?.value || null)
        )
        return fn ? fn() : null
      }
    })
  }

  on(fn: Function) {
    this.callees.push(fn)
    return this
  }
}
