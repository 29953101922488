import { FlowLoadAllByBotResponse } from '@/assets/js/interfaces/Flow'
import { Socket } from '@/assets/js/core/Socket'

class FlowService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly token: string) {}

  onLoad(callback: Function) {
    Socket.on('flow', (_: string, data: any) => {
      if (data.subcommand === 'loadAll') {
        if (data.statuscode === 200) {
          callback(JSON.parse(data.data))
        }
      }
    })
  }

  onCreate(callback: Function) {
    Socket.on('flow', (_: string, data: any) => {
      if (data.subcommand === 'store') {
        if (data.statuscode === 200) {
          callback(data.inserted)
        }
      }
    })
  }

  load() {
    Socket.send('flow loadAll')
  }

  loadAllByBotDomain(domain: string) {
    Socket.send(`flow loadAllByBot <${this.token}>`, { domain })
  }

  onLoadAllByBotDomain(cb: Function) {
    Socket.on('flow', (_: string, data: FlowLoadAllByBotResponse) => {
      if (data.subcommand === 'loadAllByBot') {
        if (data.token === this.token) {
          cb?.(data)
        }
      }
    })
  }
}

export default FlowService
