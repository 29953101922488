import { Bot } from '@/assets/js/models/Bot'
import BotOption from '@/assets/js/interfaces/BotOptions'

interface MUPStripe {
  minPlanId: 'pro' | 'corporate' | 'team'
}

interface PageState {
  MUPStripe: boolean | MUPStripe
  bot: Bot | null
  botOptions: BotOption[]
  defaultOptions: BotOption[]
  changingBot: string
  newBotSet: boolean
}

export default {
  state: () => ({
    MUPStripe: false,
    bot: null,
    botOptions: [],
    defaultOptions: [],
    changingBot: '',
    newBotSet: false,
  }),
  mutations: {
    // Trial Banner with Stripe Elements
    // If MUPStripe is true all Stripe Elements in page
    // must be removed
    setMUPStripe(state: PageState, value: boolean | MUPStripe) {
      state.MUPStripe = value
    },
    setBot(state: PageState, value: Bot) {
      state.bot = value
      state.newBotSet = true
    },
    setBotOptions(state: PageState, value: BotOption[]) {
      state.botOptions = value
    },
    setDefaultOptions(state: PageState, value: BotOption[]) {
      state.defaultOptions = value
    },
    updateBotOption(state: PageState, value: BotOption) {
      const index = state.botOptions.findIndex(option => option.id === value.id)
      if (index) {
        state.botOptions[index] = value
      } else {
        state.botOptions.push(value)
      }
    },
    removeBotOption(state: PageState, value: string) {
      const index = state.botOptions.findIndex(option => option.name === value)
      if (index) {
        state.botOptions.splice(index, 1)
      }
    },
    changeBot(state: PageState, value: string) {
      if (value) {
        state.newBotSet = false
      }
      state.changingBot = value
    },
  },
}
