export default {
  name: 'u-png',
  components: {},
  props: {
    src: {
      type: [String, Object],
      required: true,
      default: () => {},
    },
    alt: {
      type: String,
      required: false,
    },
    version: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    getSrcSet() {
      const version = this.version ? `?${this.version}` : ''

      if (typeof this.src === 'object') {
        return this.getSrcSetFromObject()
      } else {
        return `/images/${this.src}@4x.png${version} 4x, /images/${this.src}@3x.png${version} 3x, /images/${this.src}@2x.png${version} 2x, /images/${this.src}.png${version} 1x`
      }
    },

    /**
     * @description transform array of images in srcset string
     */
    getSrcSetFromObject() {
      let string
      const src = []
      for (const key in this.src) {
        src.push(`${this.src[key]} ${key}`)
      }
      string = src.reverse().join(', ')
      return string
    },
  },
}
