import type { Store } from 'vuex'
import HistoryChange from '@/components/flowchart-builder/interfaces/HistoryChange'

interface FlowBuilderState {
  isChanged: boolean
  isCursorOnInputConnector: boolean
  history: {
    container: HistoryChange[]
    redoContainer: HistoryChange[]
  }
}

const state = (): FlowBuilderState => ({
  isChanged: false,
  isCursorOnInputConnector: false,
  history: {
    container: [],
    redoContainer: [],
  },
})

const mutations = {
  onMouseHoverOperator(state: FlowBuilderState, value: boolean) {
    state.isCursorOnInputConnector = value
  },
  pushIntoHistory(state: FlowBuilderState, value: HistoryChange) {
    state.history.container.push(value)
  },
  setIsChanged(state: FlowBuilderState, value: boolean) {
    state.isChanged = value
  },
}

const actions = {
  changed({ commit }: Store<FlowBuilderState>, value = true) {
    commit('setIsChanged', value)
  },
}

export default {
  state,
  mutations,
  actions,
}
