import Cookie from 'js-cookie'
import { Navbar } from '@/assets/js/core/Navbar'

interface NavbarState {
  status: boolean
  fullOpened: boolean
  subMenuOpened: string
}

const initCookie = () => {
  const navbarCookie = Cookie.get('navbarStatus')
  return navbarCookie === undefined ? null : navbarCookie === 'true'
}

const status = initCookie()

export default {
  state: () => ({
    status,
    fullOpened: status ?? false,
    subMenuOpened: '',
  }),
  mutations: {
    setStatus(state: NavbarState, value: boolean) {
      state.status = value
      Cookie.set('navbarStatus', JSON.stringify(value))
      if (!value) {
        state.subMenuOpened = ''
      }
    },
    setFullOpened(state: NavbarState, value: boolean) {
      state.fullOpened = value
      Navbar.emit('completeAnimation')
    },
    setSubMenuOpened(state: NavbarState, value: string) {
      state.subMenuOpened = value
    },
  },
  actions: {
    updateFullOpened({ commit }: any, value: boolean) {
      setTimeout(() => {
        commit('setFullOpened', value)
      }, 400)
    },
    toggle({ commit, dispatch }: any, value: boolean) {
      commit('setStatus', value)
      dispatch('updateFullOpened', value)
    },
  },
}
