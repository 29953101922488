import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class ULoader extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  modelValue: boolean

  @Prop({ type: [String, Number], required: false, default: '' })
  size: string | number

  @Prop({ type: [String, Number], required: false, default: '' })
  border: string | number

  @Prop({ type: String, required: false, default: 'rgba(255,255,255,0.3)' })
  color: string

  @Prop({ type: String, required: false, default: '#fff' })
  spinColor: string

  @Prop({ type: Boolean, required: false, default: false })
  inline: boolean

  @Watch('modelValue', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localValue = val
    }
  }

  localValue: boolean = false

  private getVariableStyles() {
    return {
      '--loader-border-color': this.color,
      '--loader-spin-border-color': this.spinColor,
      '--loader-width':
        typeof this.size === 'number' ? `${this.size}px` : this.size,
      '--loader-border-width':
        typeof this.border === 'number' ? `${this.border}px` : this.border,
    }
  }

  private getRootClass() {
    return {
      'u-loader--inline': this.inline,
    }
  }
}
