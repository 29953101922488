import Cookies from 'js-cookie'

interface Data {
  language_user: string
  language_browser: string
  country: string
}

export default class AdapterDataAnalytics {
  private readonly data: any

  constructor(data: any) {
    this.data = data
  }

  async get(data = {}): Promise<Data> {
    const country = await this.getUserCountry()
    return {
      ...this.data,
      ...data,
      language_browser: this.getBrowserLanguage(),
      language_user: this.getUserLanguage(),
      country,
    }
  }

  private getBrowserLanguage(): string {
    return navigator.language || (navigator as any).userLanguage
  }

  private getUserLanguage(): string {
    return Cookies.get('lang') || 'en'
  }

  private async getUserCountry(): Promise<string> {
    if (localStorage?.getItem('userbot_country')) {
      return localStorage?.getItem('userbot_country') ?? ''
    }
    try {
      const response = await fetch('https://ipapi.co/json/')
      const data = await response.json()
      localStorage?.setItem('userbot_country', data.country_name)
      return data.country_name
    } catch (error) {
      return ''
    }
  }
}
