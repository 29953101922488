export default {
  name: 'click-outside',
  emits: ['click'],
  data() {
    return {
      isOver: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClick)
  },
  destroy() {
    document.removeEventListener('click', this.handleClick)
  },
  methods: {
    mouseleave() {
      this.isOver = false
    },
    mouseenter() {
      this.isOver = true
    },
    handleClick(e) {
      if (!this.isOver) {
        this.$emit('click', e)
      }
    },
  },
}
