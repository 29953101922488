export default {
  name: 'panel',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    disabled: {
      immediate: true,
      handler(val) {
        this.disabledStatus = val
      },
    },
  },
  data() {
    return {
      disabledStatus: false,
    }
  },
}
