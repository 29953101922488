import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class UProgressBar extends Vue {
  @Prop({ type: String, required: false, default: '' })
  label: string

  @Prop({ type: Number, required: false, default: 0 })
  completed: number

  @Prop({ type: Number, required: false, default: 0 })
  total: number

  @Prop({ type: String, required: false, default: '#5449C6' })
  color: string

  @Prop({ type: Boolean, required: false, default: false })
  showPercent: boolean

  @Prop({ type: Number, required: false, default: 0 })
  percentCompleted: number

  get barWidth () {
    return `${this.showPercent ? this.percentCompleted : this.completed / this.total * 100}%`
  }
}