import { mixins, Component } from 'nuxt-property-decorator'
import { UserAuthMixin } from '@/modules/auth'
import Logo from '@/components/logo/index.vue'

@Component({
  components: {
    Logo,
  },
})
export default class PageNotFound extends mixins(UserAuthMixin) {
  get hrefButton() {
    return this.user ? '/bots' : '/'
  }
}
