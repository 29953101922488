export default {
  name: 'loading-points',
  components: {},
  props: [],
  data() {
    return {
      points: '',
      interval: null,
    }
  },
  computed: {},
  mounted() {
    this.interval = setInterval(() => {
      this.points += '.'
      if (this.points === '....') this.points = '.'
    }, 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {},
}
