import getImageRegex from './getImageRegex'
import getVideoRegex from './getVideoRegex'

export default function convertImageUrlToEmoji(text: string) {
  if (text) {
    if (text.includes('<img')) {
      return text.replaceAll(/<img.*?>/g, ' 📷')
    }
    return String(text)
      .trim()
      .replaceAll(getImageRegex('all'), '📷')
      .replaceAll(getVideoRegex('all'), '🎬')
  }
  return text
}
