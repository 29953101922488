import ChatOption from './ChatOption'

class _ChatAiScoreOption extends ChatOption {
  name: 'ai-score'

  isEnabled() {
    return this.get() === 'true'
  }
}

const ChatAiScoreOption = new _ChatAiScoreOption()

export default ChatAiScoreOption
