import { render, staticRenderFns } from "./u-modal-title.html?vue&type=template&id=68ea73f1&scoped=true&external"
import script from "./u-modal-title.ts?vue&type=script&lang=ts&external"
export * from "./u-modal-title.ts?vue&type=script&lang=ts&external"
import style0 from "./u-modal-title.scss?vue&type=style&index=0&id=68ea73f1&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ea73f1",
  null
  
)

export default component.exports