interface AttachFileState {
  uploading: boolean
}

export default {
  state: () => ({
    uploading: false,
  }),
  mutations: {
    start(state: AttachFileState) {
      state.uploading = true
    },
    finish(state: AttachFileState) {
      state.uploading = false
    },
  },
}
