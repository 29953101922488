import { render, staticRenderFns } from "./u-modal-general-upgrade-plan.html?vue&type=template&id=e0696c5a&external"
import script from "./u-modal-general-upgrade-plan.ts?vue&type=script&lang=ts&external"
export * from "./u-modal-general-upgrade-plan.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports