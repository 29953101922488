import AdapterDataAnalytics from './AdapterDataAnalytics'
import { MIXPANEL_KEY, IS_DEBUG_ACTIVE } from '@/assets/js/config/mixpanel'
import mixpanel from 'mixpanel-browser'

class _Mixpanel {
  /**
   * @description track page viewed
   * @param pageLabel
   */
  pageViewed(pageLabel) {
    if (this.exist()) {
      this.event('Pagina', 'Visualizza', pageLabel)
    }
  }

  /**
   *
   * @param user
   */
  async alias(user) {
    if (this.exist()) {
      mixpanel.alias(user.get('email'))
      await this.setPeople(user)
    }
  }

  /**
   * @description set user properties
   * @param user
   */
  async setUser(user) {
    if (this.exist()) {
      await this.setPeople(user)
      mixpanel.identify(user.get('email'))
    } else {
      console.warn('Mixpanel is not loaded yet.')
    }
  }

  async setPeople(user) {
    if (this.exist()) {
      const data = user.getAnalyticsData()
      const allData = await new AdapterDataAnalytics({
        $email: data.email,
        $first_name: data.firstname,
        $last_name: data.lastname,
        $created: data.created_at,
        'Customer ID': data.customerId,
        Plan: data.plan,
        Role: data.groups,
      }).get()
      mixpanel.people.set(allData)
    }
    return this
  }

  /**
   * @description send to GA an Event
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   */
  event(eventCategory, eventAction, eventLabel) {
    if (this.exist()) {
      mixpanel.track(`${eventCategory} - ${eventAction} - ${eventLabel}`, {
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
      })
    }
  }

  /**
   * @description check if MixPanel is set
   * @return {boolean}
   */
  exist() {
    return MIXPANEL_KEY
  }

  init() {
    if (this.exist()) {
      mixpanel.init(MIXPANEL_KEY, { debug: IS_DEBUG_ACTIVE })
    }
  }
}

const Mixpanel = new _Mixpanel()

export default Mixpanel
