import Vue from 'vue'
import debounce from 'lodash/debounce'

/**
 * @description is in viewport element
 * @param elem
 * @returns {boolean}
 */
const isInViewport = function (elem) {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

const emit = (vnode, name, data) => {
  const handlers =
    (vnode.data && vnode.data.on) ||
    (vnode.componentOptions && vnode.componentOptions.listeners)

  if (handlers && handlers[name]) {
    handlers[name].fns(data)
  }
}

const appear = function (el, vnode) {
  if (isInViewport(el) && !!el.offsetParent) {
    emit(vnode, 'appear')
  }
}

export const Inview = {
  // When the bound element is inserted into the DOM...
  inserted(el, binding, vnode) {
    vnode.context.onWindowScroll = debounce(() => {
      appear(el, vnode)
    }, 1000)

    window.addEventListener('scroll', vnode.context.onWindowScroll, true)

    appear(el, vnode)
  },
  unbind(el, binding, vnode) {
    window.removeEventListener('scroll', vnode.context.onWindowScroll, true)
  },
}

Vue.directive('inViewport', Inview)
