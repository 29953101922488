import { Socket } from '@/assets/js/core/Socket'

export default class SocketModel {
  private cmd: string = ''

  constructor(cmd: string) {
    this.cmd = cmd
  }

  /**
   * @description on socket flow command
   * @param subcommand
   * @param callback
   */
  on(subcommand: string, callback: Function) {
    Socket.on(this.cmd, (_: string, data: any) => {
      if (data.statuscode === 200) {
        if (data.subcommand === subcommand) {
          callback(data)
        }
      }
    })
    return this
  }

  /**
   * @description send
   * @param cmd
   * @param options
   */
  send(subcmd: string, options: any) {
    Socket.send(`${this.cmd} ${subcmd}`, options)
    return this
  }

  /**
   * @description call command fake
   * @param subcmd
   * @param options
   * @return {SocketModel}
   */
  call(subcmd: string, options: any) {
    Socket.call(this.cmd, {
      _called: true,
      statuscode: 200,
      command: this.cmd,
      subcommand: subcmd,
      ...options,
    })
    return this
  }
}
