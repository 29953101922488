class _GA {
  /**
   * set user properties
   * @param user
   */
  setUser(user) {
    if (this.exist()) {
      ga('set', '&uid', user.get('id'))
    }
  }

  /**
   * send to GA an Event
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   */
  event(eventCategory, eventAction, eventLabel) {
    if (this.exist()) {
      ga('send', {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
      })
    }
    return this
  }

  /**
   * check if GoogleAnalytics is set
   * @return {boolean}
   */
  exist() {
    return typeof ga !== 'undefined'
  }
}

const GoogleAnalytics = new _GA()

export default GoogleAnalytics
