import { Vue, Component, Prop, Watch, Emit } from 'nuxt-property-decorator'

@Component
export default class ULinkComponent extends Vue {
  @Prop({ required: false, default: '#' })
  href: string

  @Watch('href', { immediate: true })
  onHrefChange(val: string) {
    this.localHref = val?.trim().length > 0 ? this.localePath(val) : '#'
  }

  static emits = ['click']

  localHref = '#'

  isLocalHrefValued() {
    return this.localHref !== '#'
  }

  isMailto() {
    return this.localHref.substring(0, 6) === 'mailto'
  }

  @Emit('click')
  onClick(event: Event) {
    if (!this.isLocalHrefValued()) {
      event.preventDefault()
    }
    return event
  }
}
