/* eslint-disable camelcase */
import moment from 'moment-timezone'
import { User } from './User'
import { Model } from '@/assets/js/core/Model'
import ChatService from '@/assets/js/services/ChatService'

const UPSCALED_CODE = '400'
const BOT_CODE = '200'

export class Chat extends Model {
  _user: null | false | User
  _noty: number
  _online: boolean
  _status: string
  _from_now: null
  _trace: any

  constructor(attributes: any) {
    super(attributes)

    this._user = null
    this._noty = attributes.counter ? attributes.counter : 0
    this._online = false
    this._status = attributes.archived ? 'archive' : ''
    this._from_now = null

    this._trace =
      attributes.json_trace && attributes.json_trace.trim().length
        ? JSON.parse(attributes.json_trace)
        : null

    if (this.isArchived() || this.isBot()) {
      this._noty = 0
    }
  }

  /**
   * @description get user image
   * @returns {string}
   */
  image() {
    if (this.user()) {
      return (this.user() as any).image()
    }
    return ''
  }

  user(): User | null | false {
    if (this._user === null) {
      this._user = false
      if (this.get('json_trace')) {
        this._user = new User({
          user: this.get('user'),
          id: this.get('userid'),
          json_trace: this.get('json_trace'),
        })
      }
    }
    return this._user
  }

  /**
   * @description get username chat
   * @returns {*}
   */
  username() {
    if (this.get('userid') === -1) {
      return this.get('user')
    }
    return this.user() ? (this.user() as any).completeName() : this.get('user')
  }

  /**
   * @description get dely from date
   * @param date
   * TODO: Remove subtract and substitute with timezone/utc
   */
  from_now(date: string | moment.Moment) {
    if (!date) {
      if (this.get('last_message')) date = this.get('last_message')
      else date = moment()
    }
    return moment(date).fromNow()
  }

  /**
   * @description check if has badge
   * @returns {number}
   */
  getNoty() {
    if (!this.isArchived() && !this.isBot()) {
      return this._noty ? this._noty : 0
    }
    return 0
  }

  /**
   * @description set badge
   * @return this;
   */
  setNoty(value: number) {
    if (value > 0) {
      this._noty = value || 1
    } else {
      this._noty = 0
    }
    return this
  }

  /**
   * @escription set last message
   * @param text
   * @returns {Chat}
   */
  setLastMessage(text: string) {
    this.set('last_chat_text', text)
    this.set('last_message', moment().format())
    return this
  }

  /**
   * @description get if chat is solved or not
   * @returns {boolean}
   */
  isUnsolved() {
    return this.get('last_status') !== BOT_CODE
  }

  /**
   * @description set status
   * @param status
   */
  setStatus(status: boolean) {
    this.set('last_status', status ? BOT_CODE : UPSCALED_CODE)
  }

  /**
   * @description
   * @returns {boolean}
   */
  isOnline() {
    return this.comeFromSocial() || this._online
  }

  /**
   * @description
   * @returns {boolean}
   */
  isOffline() {
    return !this.isOnline()
  }

  /**
   * @description check if chat is archived
   * @returns {boolean}
   */
  isArchived() {
    return this._status === 'archive'
  }

  /**
   * @description check if chat is archived
   * @returns {boolean}
   */
  isReactivated() {
    return this._status === 'reactivated'
  }

  /**
   * @description archive chat
   * @returns {Chat}
   */
  goArchive() {
    this._status = 'archive'
    return this
  }

  /**
   * @description reactivate chat
   * @returns {Chat}
   */
  reactivate() {
    this._status = 'reactivated'
    return this
  }

  /**
   * @description set chat offline
   * @returns {Chat}
   */
  goOffline() {
    this._online = false
    return this
  }

  /**
   * @description set chat offline
   * @returns {Chat}
   */
  goOnline() {
    this._online = true
    return this
  }

  /**
   * @description check if chat is in "deposito"
   * @returns {boolean}
   */
  isInStorage() {
    return this.get('robin_op') === -1
  }

  /**
   * @description check if chat is ever assigned
   * @returns {boolean}
   */
  isAssignedToOp() {
    return this.get('robin_op') > -1 || this.get('ticket_assigned')
  }

  isAssigned() {
    return (
      this.get('ticket_status') === 'assign' ||
      this.get('ticket_status') === 'open'
    )
  }

  assignToAgent(userEmail: string): Chat {
    this.set('ticket_assigned', userEmail)
    this.set('ticket_status', 'assign')
    this.set('last_status', UPSCALED_CODE)
    ChatService.assignToAgent({
      chatId: this.get('id'),
      userEmail,
    })
    return this
  }

  assignToBot(): Chat {
    this.set('ticket_assigned', '')
    this.set('ticket_status', 'open')
    this.set('last_status', BOT_CODE)
    ChatService.assignToBot({
      chatId: this.get('id'),
    })
    return this
  }

  getAgentEmailAssignedTo() {
    return this.isAssigned() && this.get('ticket_assigned')
  }

  /**
   * @description check if user come from facebook
   */
  comeFromFacebook() {
    return this.comeFrom('facebook')
  }

  /**
   * @description check if user come from twitter
   */
  comeFromTwitter() {
    return this.comeFrom('twitter')
  }

  comeFromWhatsapp() {
    return this.comeFrom('whatsapp')
  }

  /**
   * @description check if come from social
   * @return {Boolean}
   */
  comeFromSocial() {
    return (
      this.comeFromFacebook() ||
      this.comeFromTwitter() ||
      this.comeFromWhatsapp()
    )
  }

  /**
   * @description check if user come from a browser
   * @param string
   * @return {Boolean}
   */
  comeFrom(string: string) {
    if (this._trace) {
      if (this._trace.browser?.length) {
        return (this._trace as any).browser[0].toLowerCase() === string
      }
      return this.attributes.source === string
    }
    return false
  }

  /**
   * @description check if conversation is a Bot
   * @return {boolean}
   */
  isBot() {
    return this.get('last_status').toString() === BOT_CODE
  }

  /**
   * @description check if conversation is a Bot
   * @return {boolean}
   */
  isUpscaled() {
    return this.get('last_status').toString()[0] === '4'
  }

  isTest() {
    return this.get('test_chat')
  }

  /**
   * @description check if user has written
   * @returns {boolean}
   */
  hasUserWritten() {
    return !!this.get('isHumanMessage')
  }
}
