import getImageRegex from './getImageRegex'
import getVideoRegex from './getVideoRegex'

export default function getMediaUrl(text: string) {
  if (text.includes('<img')) {
    return []
  }
  const imageMatches = String(text).match(getImageRegex('all')) || []
  const videoMatches = String(text).match(getVideoRegex('all')) || []
  return [...new Set(imageMatches.concat(videoMatches))]
}
