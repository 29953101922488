import isObject from 'lodash/isObject'

export class LocalStorage {
  /**
   * @description save in localstorage
   * @param key
   * @param value
   */
  static save(key: string, value: Object | string) {
    let valueToSave = value
    if (isObject(value)) {
      valueToSave = JSON.stringify(value)
    }
    localStorage?.setItem(key, valueToSave.toString())
    return this
  }

  /**
   * @description return key value from storage
   * @param {string} key
   * @return {any}
   */
  static get(key: string) {
    const value = localStorage?.getItem(key) ?? ''
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  /**
   * @description remove key->value from localstorage
   * @param {string} key
   */
  static remove(key: string) {
    if (isObject(key)) {
      this.removeByRegex(key)
    } else localStorage?.removeItem(key)
    return this
  }

  /**
   * @description remove all values by regex
   * @param regex
   */
  static removeByRegex(regex: RegExp) {
    const entries = Object.entries(localStorage)
    entries.forEach((item) => {
      const key = item[0]
      if (key.match(regex)) {
        localStorage?.removeItem(key)
      }
    })
    return this
  }
}
