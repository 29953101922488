import uniqueId from 'lodash/uniqueId'
import Key from '@/assets/js/core/Key'
import UPopover from '@/components/UPopover'

export default {
  name: 'badges',
  components: {
    UPopover,
  },
  props: {
    modelValue: {},
    placeholder: {},
    autocomplete: {
      type: Array,
      default: null,
      required: false,
    },
    editable: {},
    selectable: {},
    selected: {},
    visibleItems: {
      type: [Number, null],
      required: false,
      default: null,
    },
    minWidthInput: {},
    doubles: {
      type: Boolean,
      required: false,
      default: false,
    },
    connector: {},
    addOnBlur: {
      type: Boolean,
      required: false,
      default: false,
    },
    addOnSpace: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    backspaceDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    responsiveLimit: {
      type: Boolean,
      required: false,
    },
    activeMention: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: [
    'add',
    'blur',
    'focus',
    'input',
    'keydown',
    'keyup',
    'remove',
    'selected',
  ],
  data() {
    return {
      /**
       * @description array of badges
       */
      items: [],

      /**
       * @description visible badges limit
       */
      previewLimit: null,

      /**
       * @description popover enable status
       */
      popoverEnabled: false,

      /**
       * @description popover items counter
       */
      popoverBadgeQuantity: 0,

      /**
       * @description
       */
      popover: false,

      /**
       * @description
       */
      isWriting: false,

      /**
       * @description
       */
      upDownSelection: null,

      /**
       * @description mouse in autocomplete box
       */
      onAutocompleteBox: false,

      /**
       * @description focus
       */
      onFocus: false,

      /**
       * @description dropdown status
       */
      dropdownStatus: false,

      /**
       * @description disabled status
       */
      disabledStatus: false,

      /**
       * @description unique id for badges
       * @type string
       */

      id: uniqueId('badges-'),
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.items = val.filter((item) => item)
        }
      },
    },
    minWidthInput() {
      this.$forceUpdate()
    },
    visibleItems: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.previewLimit = val
          this.popoverEnabled = this.items.length > val
        } else {
          this.popoverEnabled = false
        }
      },
    },
    disabled: {
      immediate: true,
      handler(val) {
        this.disabledStatus = val
      },
    },
  },
  created() {
    document.addEventListener('click', this.clickDocumentHandler)
  },
  destroyed() {
    document.removeEventListener('click', this.clickDocumentHandler)
  },
  computed: {
    /**
     * @description filtered autocomplete options
     * @returns {Array}
     */
    filteredAutocomplete() {
      if (!this.isWriting || !this.autocomplete) return []
      return this.autocomplete.filter((item) => {
        return item.name.toLowerCase().includes(this.isWriting.toLowerCase())
      })
    },

    /**
     * @description generate previewBadges array
     * @returns {Array}
     */
    previewBadges() {
      if (this.previewLimit !== null) {
        return this.items.slice(0, this.previewLimit)
      }
      return this.items
    },

    /**
     * @description popover badges
     */
    popoverBadges() {
      return this.items.slice(this.previewLimit, this.items.length)
    },
  },
  mounted() {
    /**
     * @description bind move of arrow keys on autocomplete if exist
     */
    if (this.autocomplete) this._bindArrowKeyEvents()

    if (this.responsiveLimit) {
      this.setBadgesLimit()
    }
  },
  methods: {
    /**
     * @description keydown event handler
     * @param e
     * @param text
     */
    keydownEvent(e, text) {
      if (
        Key.isADeleteKey(e) &&
        !text &&
        !text.length &&
        this.backspaceDelete
      ) {
        this.removeItem(this.items.length - 1)
        this.dropdownStatus = false
      } else {
        this.$emit('keydown', e)
        this.dropdownStatus = true
      }
    },

    /**
     * @description focus on input
     * @param e
     */
    focusEvent(e) {
      this.onFocus = true
      this.dropdownStatus = true
      this.$emit('focus', e)
    },

    /**
     * @description focus on input
     * @param e
     */
    blurEvent(e) {
      this.onFocus = false
      this.$emit('blur', e)
    },

    /**
     * @description add event
     * @param event
     */
    add(event) {
      if (this._isInsertedWordDoubleAndNotAllowed(event)) {
        return
      }

      this.dropdownStatus = false

      if (this.autocomplete && this.autocomplete.length) {
        if (this.upDownSelection !== null) {
          if (this.filteredAutocomplete.length) {
            const selected = this.filteredAutocomplete[this.upDownSelection]
            if (!this.exist(selected)) this.items.push(selected)
            this.upDownSelection = null
            return
          }
        }

        if (this.exist(event)) {
          this.upDownSelection = null
          return
        }
      }

      this.items.push(event)
      this.$emit('add', event)
      this.$emit('update:modelValue', this.items)
      this.upDownSelection = null
      this.isWriting = false
      if (this.$refs.input) {
        this.$refs.input.empty()
      }
    },

    /**
     * @description add new item
     */
    addNew() {
      this.add({ name: this.isWriting })
      this.dropdownStatus = false
    },

    /**
     * @description clicked item
     * @param item
     */
    selectedItem(item) {
      this.$emit('selected', item)
      this.dropdownStatus = false
    },

    /**
     * @description deselect all badges
     */
    deselectAll() {
      this.items.forEach((item) => {
        this.$refs['badge' + item.id][0].deselect()
      })
    },

    /**
     * @description check if element exist
     * @param el
     */
    exist(el) {
      return this.items.some(
        (item) =>
          item.name.toLowerCase().trim() === el.name.toLowerCase().trim()
      )
    },

    /**
     * @description add event
     * @param event
     */
    select(event) {
      if (!this.exist(event)) {
        this.items.push(event)
        this.$emit('add', event)
        this.$emit('update:modelValue', this.items)
      }
      this.upDownSelection = null
      this.dropdownStatus = false
      this.$refs.input.name = ''
      this.setWriting(false)
    },

    /**
     * @description remove element
     * @param key
     */
    removeItem(key) {
      if (this.disabledStatus) return
      const item = this.items.splice(key, 1)
      this.$emit('remove', item)
      this.$emit('update:modelValue', this.items)
    },

    /**
     * @description check if user is writing
     * @param val
     */
    setWriting(val) {
      this.isWriting = val
      if (val) this.upDownSelection = null
    },

    /**
     * @description check up/down arrow selection
     * @param n
     */
    isOverArrowKey(n) {
      return n === this.upDownSelection
    },

    /**
     * @description check if input is visible
     * @returns {boolean|*}
     */
    isInputVisible() {
      return this.editable !== false
    },

    /**
     * @description bind move of arrow keys on autocomplete
     * @private
     */
    _bindArrowKeyEvents() {
      document.addEventListener('keyup', (e) => {
        if (Key.isUpArrow(e)) {
          if (this.upDownSelection === null || this.upDownSelection === 0) {
            this.upDownSelection = this.filteredAutocomplete.length - 1
          } else {
            this.upDownSelection--
          }
        }

        if (Key.isDownArrow(e)) {
          if (
            this.upDownSelection === null ||
            this.upDownSelection >= this.filteredAutocomplete.length - 1
          ) {
            this.upDownSelection = 0
          } else {
            this.upDownSelection++
          }
        }

        if (Key.isEsc(e)) {
          this.upDownSelection = null
          this.dropdownStatus = false
          this.popover = false
        }
      })
    },

    /**
     * @description focus on add new badge
     */
    focus() {
      this.onFocus = true

      if (this.$refs.input) {
        setTimeout(() => {
          if (this.$refs.input) {
            this.$refs.input.focus()
          }
        }, 400)
      } else {
        this.$emit('focus', new FocusEvent(this.$el))
      }
    },

    /**
     * @description get classes
     * @return {json}
     */
    getClass() {
      return {
        'has-autocomplete': this.autocomplete,
        'is-autocomplete-visible': this.isWriting,
        'badges__is-focus': this.onFocus,
        'badge__has-connectors': this.connector,
        'badges__is-empty': !this.items.length,
      }
    },

    /**
     * @description document click handler
     * @param e
     */
    clickDocumentHandler() {
      if (this.$refs.input) {
        if (!this.onAutocompleteBox) {
          this.blurEvent()
          this.$refs.input.deselect()
          this.setWriting('')
        }
      }
    },

    /**
     * @description check if inserted word is doubled and is allowed
     * @param word
     * @return {boolean}
     * @private
     */
    _isInsertedWordDoubleAndNotAllowed(word) {
      return (
        this.doubles === false &&
        this.items.find((item) => word.name === item.name)
      )
    },

    setBadgesLimit() {
      const badges = this.$refs.badges.children
      let sum = 0
      let badgesLimit = 0
      let overflow = false
      if (badges) {
        Array.from(badges).forEach((badge) => {
          const badgeWidth = badge.clientWidth + 14
          if (sum + badgeWidth < this.$refs.badges?.clientWidth) {
            sum += badgeWidth || 0
            badgesLimit++
          } else {
            overflow = true
          }
        })
      }
      if (overflow) {
        badgesLimit = badgesLimit - 2
      }
      this.previewLimit = badgesLimit
      this.popoverEnabled = this.items.length > badgesLimit
    },
  },
}
