import { Store } from 'vuex'
import GroupService from '@/assets/js/services/GroupService'
import { Response } from '@/assets/js/core/Http/Response'
import Customer from '@/assets/js/interfaces/Customer'

type Group = Customer

type GroupsState = {
  all: Group[] | null
  loading: boolean
}

export default {
  state: (): GroupsState => ({
    all: null,
    loading: true,
  }),
  mutations: {
    setAll(state: GroupsState, value: Group[]) {
      state.all = value || []
      state.loading = false
    },
    enable(state: GroupsState, id: number) {
      const index = state.all?.findIndex((a: Group) => a.id === id)
      if (index && state.all?.[index]) {
        state.all[index].enabled = 1
      }
    },
    disable(state: GroupsState, id: number) {
      const index = state.all?.findIndex((a: Group) => a.id === id)
      if (index && state.all?.[index]) {
        state.all[index].enabled = 0
      }
    },
  },
  actions: {
    async load({ commit }: Store<GroupsState>): Promise<void> {
      const groups = await GroupService.load()
      commit('setAll', groups.data())
    },
    async enable(
      { commit }: Store<GroupsState>,
      customerId: number
    ): Promise<Response> {
      const res = await GroupService.enable([customerId])
      if (res.succeed()) {
        commit('enable', customerId)
      }
      return res
    },
    async disable(
      { commit }: Store<GroupsState>,
      customerId: number
    ): Promise<Response> {
      const res = await GroupService.disable([customerId])
      if (res.succeed()) {
        commit('disable', customerId)
      }
      return res
    },
  },
}
