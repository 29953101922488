import { Model } from '../core/Model'

export class CreditCard extends Model {
  /**
   * @description validate property
   * @return {}
   */
  get validate() {
    return {
      name: 'required',
      holder: 'required',
      card: 'required',
    }
  }

  /**
   * @description get brand
   * @return {string}
   */
  brand() {
    return this.get('brand').toLowerCase().replace(' ', '-')
  }

  /**
   * @description get number
   */
  number() {
    return `XXXX-XXXX-XXXX-${this.get('last4')}`
  }

  /**
   * @description get full expiration date
   * @return {string}
   */
  expiration() {
    return `${this.expMonth()}/${this.expYear()}`
  }

  /**
   * @description get expiration month format
   * @return {string}
   */
  expMonth() {
    const month = this.get('exp_month')
    return month <= 9 ? '0' + month : month
  }

  /**
   * @description get expiration year format
   * @return {string}
   */
  expYear() {
    return this.get('exp_year').toString().substring(2)
  }

  /**
   * @description get holder Stripe Card
   * @returns {*}
   */
  holder() {
    return this.get('holder')
  }

  /**
   * @description get holder first name
   * @returns {Document.last_name|string|*}
   */
  holderName() {
    return this.holder().first_name
  }

  /**
   * @description get holder last name
   * @returns {Document.last_name|string|*}
   */
  holderLastname() {
    return this.holder().last_name
  }

  /**
   * @description get token Stripe Card
   * @returns {*}
   */
  token() {
    return this.get('token')
  }

  /**
   * @description get id Stripe Card
   * @returns {*}
   */
  id() {
    return this.get('card').id
  }
}
