const middleware = {}

middleware['base'] = require('../middleware/base.ts')
middleware['base'] = middleware['base'].default || middleware['base']

middleware['logout'] = require('../middleware/logout.ts')
middleware['logout'] = middleware['logout'].default || middleware['logout']

middleware['preload-bot'] = require('../middleware/preload-bot.ts')
middleware['preload-bot'] = middleware['preload-bot'].default || middleware['preload-bot']

middleware['preload-user'] = require('../middleware/preload-user.ts')
middleware['preload-user'] = middleware['preload-user'].default || middleware['preload-user']

middleware['redirect-home'] = require('../middleware/redirect-home.ts')
middleware['redirect-home'] = middleware['redirect-home'].default || middleware['redirect-home']

middleware['reset-bot'] = require('../middleware/reset-bot.ts')
middleware['reset-bot'] = middleware['reset-bot'].default || middleware['reset-bot']

export default middleware
