import moment from 'moment-timezone'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import env from '@/env'

type JWTData = any

const bearerKey = 'auth._token.local'

class _Bearer {
  private token = ''

  getToken() {
    return this.token ?? Cookies.get(bearerKey)
  }

  setToken(token: string) {
    this.token = token
    Cookies.set(bearerKey, token)
    if (process.client) {
      localStorage?.setItem(bearerKey, token)
    }
    return this
  }

  get data(): JWTData {
    return this.getToken()
      ? jwtDecode(this.getToken())
      : {
          userperms: [],
        }
  }

  removeToken() {
    Cookies.remove(bearerKey)
    this.token = ''
    return this
  }

  isExpired() {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NUXT_ENV_USERBOT_API_ENDPOINT_MOCKED === 'true'
    ) {
      return false
    }
    return moment.unix(this.data.exp).diff(moment(), 'minutes') < 3
  }
}

export const Bearer = new _Bearer()
