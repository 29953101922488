import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c55367f8 = () => interopDefault(import('../pages/agency.vue' /* webpackChunkName: "pages/agency" */))
const _8bf7bce6 = () => interopDefault(import('../pages/agents.vue' /* webpackChunkName: "pages/agents" */))
const _261ce00e = () => interopDefault(import('../pages/bots/index.vue' /* webpackChunkName: "pages/bots/index" */))
const _0f869726 = () => interopDefault(import('../pages/customers.vue' /* webpackChunkName: "pages/customers" */))
const _91f9fcba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b999086 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _3c8a09ee = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _347a2150 = () => interopDefault(import('../pages/password-recovery.vue' /* webpackChunkName: "pages/password-recovery" */))
const _6d69189c = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _087d968c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _453096e5 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _6ac0febc = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _ecce2258 = () => interopDefault(import('../pages/trial-ended.vue' /* webpackChunkName: "pages/trial-ended" */))
const _564941fc = () => interopDefault(import('../pages/register/agency.vue' /* webpackChunkName: "pages/register/agency" */))
const _30f9c4b7 = () => interopDefault(import('../pages/sales/login.vue' /* webpackChunkName: "pages/sales/login" */))
const _2cd8fbd5 = () => interopDefault(import('../pages/sales/password-recovery.vue' /* webpackChunkName: "pages/sales/password-recovery" */))
const _182404c5 = () => interopDefault(import('../pages/sales/register.vue' /* webpackChunkName: "pages/sales/register" */))
const _5ea13c9c = () => interopDefault(import('../pages/sales/onboarding/_step/index.vue' /* webpackChunkName: "pages/sales/onboarding/_step/index" */))
const _b911b794 = () => interopDefault(import('../pages/bots/_botKey/index.vue' /* webpackChunkName: "pages/bots/_botKey/index" */))
const _5c14515a = () => interopDefault(import('../pages/bots/_botKey/agents.vue' /* webpackChunkName: "pages/bots/_botKey/agents" */))
const _57929c74 = () => interopDefault(import('../pages/bots/_botKey/ai.vue' /* webpackChunkName: "pages/bots/_botKey/ai" */))
const _2dd81550 = () => interopDefault(import('../pages/bots/_botKey/audience.vue' /* webpackChunkName: "pages/bots/_botKey/audience" */))
const _7006a895 = () => interopDefault(import('../pages/bots/_botKey/automated-messages.vue' /* webpackChunkName: "pages/bots/_botKey/automated-messages" */))
const _380bdb7c = () => interopDefault(import('../pages/bots/_botKey/chats/index.vue' /* webpackChunkName: "pages/bots/_botKey/chats/index" */))
const _0fec490a = () => interopDefault(import('../pages/bots/_botKey/dashboard/index.vue' /* webpackChunkName: "pages/bots/_botKey/dashboard/index" */))
const _c97a7aa8 = () => interopDefault(import('../pages/bots/_botKey/flows/index.vue' /* webpackChunkName: "pages/bots/_botKey/flows/index" */))
const _4a00905c = () => interopDefault(import('../pages/bots/_botKey/getting-started.vue' /* webpackChunkName: "pages/bots/_botKey/getting-started" */))
const _d33249a4 = () => interopDefault(import('../pages/bots/_botKey/integrations/index.vue' /* webpackChunkName: "pages/bots/_botKey/integrations/index" */))
const _96a08022 = () => interopDefault(import('../pages/bots/_botKey/settings.vue' /* webpackChunkName: "pages/bots/_botKey/settings" */))
const _1b802781 = () => interopDefault(import('../pages/bots/_botKey/visitors-identikit.vue' /* webpackChunkName: "pages/bots/_botKey/visitors-identikit" */))
const _b0051676 = () => interopDefault(import('../pages/bots/_botKey/dashboard/customer.vue' /* webpackChunkName: "pages/bots/_botKey/dashboard/customer" */))
const _23324eb5 = () => interopDefault(import('../pages/bots/_botKey/dashboard/sales.vue' /* webpackChunkName: "pages/bots/_botKey/dashboard/sales" */))
const _0009c7e4 = () => interopDefault(import('../pages/bots/_botKey/integrations/facebook/success.vue' /* webpackChunkName: "pages/bots/_botKey/integrations/facebook/success" */))
const _7feebf43 = () => interopDefault(import('../pages/bots/_botKey/integrations/twitter/success.vue' /* webpackChunkName: "pages/bots/_botKey/integrations/twitter/success" */))
const _bb505f84 = () => interopDefault(import('../pages/bots/_botKey/integrations/web-chat/preview.vue' /* webpackChunkName: "pages/bots/_botKey/integrations/web-chat/preview" */))
const _1d580ad6 = () => interopDefault(import('../pages/bots/_botKey/chats/_sessionId/index.vue' /* webpackChunkName: "pages/bots/_botKey/chats/_sessionId/index" */))
const _0f8c2f65 = () => interopDefault(import('../pages/bots/_botKey/flows/_flowId/index.vue' /* webpackChunkName: "pages/bots/_botKey/flows/_flowId/index" */))
const _a722e75a = () => interopDefault(import('../pages/bots/_botKey/chats/_sessionId/_messageId/index.vue' /* webpackChunkName: "pages/bots/_botKey/chats/_sessionId/_messageId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agency",
    component: _c55367f8,
    name: "agency___en"
  }, {
    path: "/agents",
    component: _8bf7bce6,
    name: "agents___en"
  }, {
    path: "/bots",
    component: _261ce00e,
    name: "bots___en"
  }, {
    path: "/customers",
    component: _0f869726,
    name: "customers___en"
  }, {
    path: "/fr",
    component: _91f9fcba,
    name: "index___fr"
  }, {
    path: "/it",
    component: _91f9fcba,
    name: "index___it"
  }, {
    path: "/login",
    component: _4b999086,
    name: "login___en"
  }, {
    path: "/logout",
    component: _3c8a09ee,
    name: "logout___en"
  }, {
    path: "/password-recovery",
    component: _347a2150,
    name: "password-recovery___en"
  }, {
    path: "/password-reset",
    component: _6d69189c,
    name: "password-reset___en"
  }, {
    path: "/profile",
    component: _087d968c,
    name: "profile___en"
  }, {
    path: "/register",
    component: _453096e5,
    name: "register___en"
  }, {
    path: "/sign-up",
    component: _6ac0febc,
    name: "sign-up___en"
  }, {
    path: "/trial-ended",
    component: _ecce2258,
    name: "trial-ended___en"
  }, {
    path: "/fr/agency",
    component: _c55367f8,
    name: "agency___fr"
  }, {
    path: "/fr/agents",
    component: _8bf7bce6,
    name: "agents___fr"
  }, {
    path: "/fr/bots",
    component: _261ce00e,
    name: "bots___fr"
  }, {
    path: "/fr/customers",
    component: _0f869726,
    name: "customers___fr"
  }, {
    path: "/fr/login",
    component: _4b999086,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _3c8a09ee,
    name: "logout___fr"
  }, {
    path: "/fr/password-recovery",
    component: _347a2150,
    name: "password-recovery___fr"
  }, {
    path: "/fr/password-reset",
    component: _6d69189c,
    name: "password-reset___fr"
  }, {
    path: "/fr/profile",
    component: _087d968c,
    name: "profile___fr"
  }, {
    path: "/fr/register",
    component: _453096e5,
    name: "register___fr"
  }, {
    path: "/fr/sign-up",
    component: _6ac0febc,
    name: "sign-up___fr"
  }, {
    path: "/fr/trial-ended",
    component: _ecce2258,
    name: "trial-ended___fr"
  }, {
    path: "/it/agency",
    component: _c55367f8,
    name: "agency___it"
  }, {
    path: "/it/agents",
    component: _8bf7bce6,
    name: "agents___it"
  }, {
    path: "/it/bots",
    component: _261ce00e,
    name: "bots___it"
  }, {
    path: "/it/customers",
    component: _0f869726,
    name: "customers___it"
  }, {
    path: "/it/login",
    component: _4b999086,
    name: "login___it"
  }, {
    path: "/it/logout",
    component: _3c8a09ee,
    name: "logout___it"
  }, {
    path: "/it/password-recovery",
    component: _347a2150,
    name: "password-recovery___it"
  }, {
    path: "/it/password-reset",
    component: _6d69189c,
    name: "password-reset___it"
  }, {
    path: "/it/profile",
    component: _087d968c,
    name: "profile___it"
  }, {
    path: "/it/register",
    component: _453096e5,
    name: "register___it"
  }, {
    path: "/it/sign-up",
    component: _6ac0febc,
    name: "sign-up___it"
  }, {
    path: "/it/trial-ended",
    component: _ecce2258,
    name: "trial-ended___it"
  }, {
    path: "/register/agency",
    component: _564941fc,
    name: "register-agency___en"
  }, {
    path: "/sales/login",
    component: _30f9c4b7,
    name: "sales-login___en"
  }, {
    path: "/sales/password-recovery",
    component: _2cd8fbd5,
    name: "sales-password-recovery___en"
  }, {
    path: "/sales/register",
    component: _182404c5,
    name: "sales-register___en"
  }, {
    path: "/fr/register/agency",
    component: _564941fc,
    name: "register-agency___fr"
  }, {
    path: "/fr/sales/login",
    component: _30f9c4b7,
    name: "sales-login___fr"
  }, {
    path: "/fr/sales/password-recovery",
    component: _2cd8fbd5,
    name: "sales-password-recovery___fr"
  }, {
    path: "/fr/sales/register",
    component: _182404c5,
    name: "sales-register___fr"
  }, {
    path: "/it/register/agency",
    component: _564941fc,
    name: "register-agency___it"
  }, {
    path: "/it/sales/login",
    component: _30f9c4b7,
    name: "sales-login___it"
  }, {
    path: "/it/sales/password-recovery",
    component: _2cd8fbd5,
    name: "sales-password-recovery___it"
  }, {
    path: "/it/sales/register",
    component: _182404c5,
    name: "sales-register___it"
  }, {
    path: "/",
    component: _91f9fcba,
    name: "index___en"
  }, {
    path: "/fr/sales/onboarding/:step",
    component: _5ea13c9c,
    name: "sales-onboarding-step___fr"
  }, {
    path: "/it/sales/onboarding/:step",
    component: _5ea13c9c,
    name: "sales-onboarding-step___it"
  }, {
    path: "/fr/bots/:botKey",
    component: _b911b794,
    name: "bots-botKey___fr"
  }, {
    path: "/it/bots/:botKey",
    component: _b911b794,
    name: "bots-botKey___it"
  }, {
    path: "/sales/onboarding/:step",
    component: _5ea13c9c,
    name: "sales-onboarding-step___en"
  }, {
    path: "/fr/bots/:botKey/agents",
    component: _5c14515a,
    name: "bots-botKey-agents___fr"
  }, {
    path: "/fr/bots/:botKey/ai",
    component: _57929c74,
    name: "bots-botKey-ai___fr"
  }, {
    path: "/fr/bots/:botKey/audience",
    component: _2dd81550,
    name: "bots-botKey-audience___fr"
  }, {
    path: "/fr/bots/:botKey/automated-messages",
    component: _7006a895,
    name: "bots-botKey-automated-messages___fr"
  }, {
    path: "/fr/bots/:botKey/chats",
    component: _380bdb7c,
    name: "bots-botKey-chats___fr"
  }, {
    path: "/fr/bots/:botKey/dashboard",
    component: _0fec490a,
    name: "bots-botKey-dashboard___fr"
  }, {
    path: "/fr/bots/:botKey/flows",
    component: _c97a7aa8,
    name: "bots-botKey-flows___fr"
  }, {
    path: "/fr/bots/:botKey/getting-started",
    component: _4a00905c,
    name: "bots-botKey-getting-started___fr"
  }, {
    path: "/fr/bots/:botKey/integrations",
    component: _d33249a4,
    name: "bots-botKey-integrations___fr"
  }, {
    path: "/fr/bots/:botKey/settings",
    component: _96a08022,
    name: "bots-botKey-settings___fr"
  }, {
    path: "/fr/bots/:botKey/visitors-identikit",
    component: _1b802781,
    name: "bots-botKey-visitors-identikit___fr"
  }, {
    path: "/it/bots/:botKey/agents",
    component: _5c14515a,
    name: "bots-botKey-agents___it"
  }, {
    path: "/it/bots/:botKey/ai",
    component: _57929c74,
    name: "bots-botKey-ai___it"
  }, {
    path: "/it/bots/:botKey/audience",
    component: _2dd81550,
    name: "bots-botKey-audience___it"
  }, {
    path: "/it/bots/:botKey/automated-messages",
    component: _7006a895,
    name: "bots-botKey-automated-messages___it"
  }, {
    path: "/it/bots/:botKey/chats",
    component: _380bdb7c,
    name: "bots-botKey-chats___it"
  }, {
    path: "/it/bots/:botKey/dashboard",
    component: _0fec490a,
    name: "bots-botKey-dashboard___it"
  }, {
    path: "/it/bots/:botKey/flows",
    component: _c97a7aa8,
    name: "bots-botKey-flows___it"
  }, {
    path: "/it/bots/:botKey/getting-started",
    component: _4a00905c,
    name: "bots-botKey-getting-started___it"
  }, {
    path: "/it/bots/:botKey/integrations",
    component: _d33249a4,
    name: "bots-botKey-integrations___it"
  }, {
    path: "/it/bots/:botKey/settings",
    component: _96a08022,
    name: "bots-botKey-settings___it"
  }, {
    path: "/it/bots/:botKey/visitors-identikit",
    component: _1b802781,
    name: "bots-botKey-visitors-identikit___it"
  }, {
    path: "/fr/bots/:botKey/dashboard/customer",
    component: _b0051676,
    name: "bots-botKey-dashboard-customer___fr"
  }, {
    path: "/fr/bots/:botKey/dashboard/sales",
    component: _23324eb5,
    name: "bots-botKey-dashboard-sales___fr"
  }, {
    path: "/it/bots/:botKey/dashboard/customer",
    component: _b0051676,
    name: "bots-botKey-dashboard-customer___it"
  }, {
    path: "/it/bots/:botKey/dashboard/sales",
    component: _23324eb5,
    name: "bots-botKey-dashboard-sales___it"
  }, {
    path: "/fr/bots/:botKey/integrations/facebook/success",
    component: _0009c7e4,
    name: "bots-botKey-integrations-facebook-success___fr"
  }, {
    path: "/fr/bots/:botKey/integrations/twitter/success",
    component: _7feebf43,
    name: "bots-botKey-integrations-twitter-success___fr"
  }, {
    path: "/fr/bots/:botKey/integrations/web-chat/preview",
    component: _bb505f84,
    name: "bots-botKey-integrations-web-chat-preview___fr"
  }, {
    path: "/it/bots/:botKey/integrations/facebook/success",
    component: _0009c7e4,
    name: "bots-botKey-integrations-facebook-success___it"
  }, {
    path: "/it/bots/:botKey/integrations/twitter/success",
    component: _7feebf43,
    name: "bots-botKey-integrations-twitter-success___it"
  }, {
    path: "/it/bots/:botKey/integrations/web-chat/preview",
    component: _bb505f84,
    name: "bots-botKey-integrations-web-chat-preview___it"
  }, {
    path: "/fr/bots/:botKey/chats/:sessionId",
    component: _1d580ad6,
    name: "bots-botKey-chats-sessionId___fr"
  }, {
    path: "/fr/bots/:botKey/flows/:flowId",
    component: _0f8c2f65,
    name: "bots-botKey-flows-flowId___fr"
  }, {
    path: "/it/bots/:botKey/chats/:sessionId",
    component: _1d580ad6,
    name: "bots-botKey-chats-sessionId___it"
  }, {
    path: "/it/bots/:botKey/flows/:flowId",
    component: _0f8c2f65,
    name: "bots-botKey-flows-flowId___it"
  }, {
    path: "/fr/bots/:botKey/chats/:sessionId/:messageId",
    component: _a722e75a,
    name: "bots-botKey-chats-sessionId-messageId___fr"
  }, {
    path: "/it/bots/:botKey/chats/:sessionId/:messageId",
    component: _a722e75a,
    name: "bots-botKey-chats-sessionId-messageId___it"
  }, {
    path: "/bots/:botKey",
    component: _b911b794,
    name: "bots-botKey___en"
  }, {
    path: "/bots/:botKey/agents",
    component: _5c14515a,
    name: "bots-botKey-agents___en"
  }, {
    path: "/bots/:botKey/ai",
    component: _57929c74,
    name: "bots-botKey-ai___en"
  }, {
    path: "/bots/:botKey/audience",
    component: _2dd81550,
    name: "bots-botKey-audience___en"
  }, {
    path: "/bots/:botKey/automated-messages",
    component: _7006a895,
    name: "bots-botKey-automated-messages___en"
  }, {
    path: "/bots/:botKey/chats",
    component: _380bdb7c,
    name: "bots-botKey-chats___en"
  }, {
    path: "/bots/:botKey/dashboard",
    component: _0fec490a,
    name: "bots-botKey-dashboard___en"
  }, {
    path: "/bots/:botKey/flows",
    component: _c97a7aa8,
    name: "bots-botKey-flows___en"
  }, {
    path: "/bots/:botKey/getting-started",
    component: _4a00905c,
    name: "bots-botKey-getting-started___en"
  }, {
    path: "/bots/:botKey/integrations",
    component: _d33249a4,
    name: "bots-botKey-integrations___en"
  }, {
    path: "/bots/:botKey/settings",
    component: _96a08022,
    name: "bots-botKey-settings___en"
  }, {
    path: "/bots/:botKey/visitors-identikit",
    component: _1b802781,
    name: "bots-botKey-visitors-identikit___en"
  }, {
    path: "/bots/:botKey/dashboard/customer",
    component: _b0051676,
    name: "bots-botKey-dashboard-customer___en"
  }, {
    path: "/bots/:botKey/dashboard/sales",
    component: _23324eb5,
    name: "bots-botKey-dashboard-sales___en"
  }, {
    path: "/bots/:botKey/integrations/facebook/success",
    component: _0009c7e4,
    name: "bots-botKey-integrations-facebook-success___en"
  }, {
    path: "/bots/:botKey/integrations/twitter/success",
    component: _7feebf43,
    name: "bots-botKey-integrations-twitter-success___en"
  }, {
    path: "/bots/:botKey/integrations/web-chat/preview",
    component: _bb505f84,
    name: "bots-botKey-integrations-web-chat-preview___en"
  }, {
    path: "/bots/:botKey/chats/:sessionId",
    component: _1d580ad6,
    name: "bots-botKey-chats-sessionId___en"
  }, {
    path: "/bots/:botKey/flows/:flowId",
    component: _0f8c2f65,
    name: "bots-botKey-flows-flowId___en"
  }, {
    path: "/bots/:botKey/chats/:sessionId/:messageId",
    component: _a722e75a,
    name: "bots-botKey-chats-sessionId-messageId___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
