import { mixins, Component, Prop, Watch } from 'nuxt-property-decorator'
import { defineAsyncComponent } from 'vue'
import Mixpanel from '@/assets/js/core/Analytics/Mixpanel'
import UModalGeneralUpgradePlan from '@/components/modals/u-modal-general-upgrade-plan/index.vue'
// eslint-disable-next-line camelcase
import { get_query_param } from '@/assets/js/core/helpers'
import { BotAuthMixin, hasPermission } from '@/modules/auth'
import UWebChatSupport from '@/components/u-webchat-support/index.vue'
import { Socket } from '@/assets/js/core/Socket'
import { eventBus } from '@/modules/events/bus'
import UDocumentsUploading from '@/components/u-documents-uploading/index.vue'
import PluginService from '@/assets/js/services/Plugins/PluginService'

const UModalUpgradePlan = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/modals/u-modal-upgrade-plan/index.vue' /* webpackChunkName: 'components/u-modal-upgrade-plan' */
      ).then(resolve)
    })
)

const UModalDeletedBot = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/modals/u-modal-deleted-bot/index.vue' /* webpackChunkName: 'components/u-modal-u-modal-deleted-bot' */
      ).then(resolve)
    })
)

const UAuthSwitcher = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-auth-switcher/index.vue' /* webpackChunkName: 'components/u-auth-switcher' */
      ).then(resolve)
    })
)

@Component({
  components: {
    UModalGeneralUpgradePlan,
    UModalUpgradePlan,
    UAuthSwitcher,
    UModalDeletedBot,
    UWebChatSupport,
    UDocumentsUploading,
  },
})
export default class UPageComponent extends mixins(BotAuthMixin) {
  @Prop({ type: Boolean, required: false, default: true })
  modelValue: boolean

  @Prop({ type: String, default: '' })
  title: string

  @Prop({ type: String, default: '' })
  pageId: string

  @Prop({ type: Boolean, required: false, default: false })
  appendButtonToBody: boolean

  @Watch('modelValue', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.loaded = val
    }
  }

  @Watch('$route.fullPath', { immediate: true })
  onFullPathChange(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.$store.commit('documents/updateShowOutsideOfBox', true)
    }
  }

  loaded: boolean = false
  service = new PluginService('statusAll')

  get modalUpgradePlan(): boolean {
    return !!this.$store?.state.page.MUPStripe
  }

  set modalUpgradePlan(value: boolean) {
    if (value) {
      value = this.$store?.state.page.MUPStripe
    }
    this.$store?.commit('page/setMUPStripe', value)
  }

  get navbarStatus() {
    return this.$store?.state.navbar.status
  }

  beforeMount() {
    Mixpanel.pageViewed(this.pageId)

    if (this.isDeepLinkMUP()) {
      this.modalUpgradePlan = true
    }
  }

  mounted() {
    Socket.send(`dashboard ${this.pageId}`)
    this.$store?.commit('page/changeBot', '')

    if (
      !this.$store?.state.bots.all &&
      (this.$route.params.botKey || this.pageId === 'bots')
    ) {
      this.$store?.dispatch('bots/load')
    }

    Socket.on('generative_ai', (_: string, data: any) => {
      if (data.subcommand === 'store-doc') {
        if (data.status === 200) {
          const doc = data.data
          let document: any = {}
          const uploading =  [...this.$store.state.documents.documentsUploading]
          const i = uploading.findIndex((docu: any) => docu.id === doc.token)
          document = uploading[i]
          if (!doc) {
            return
          }
          this.$store.commit('documents/removeDocument', doc.token)
          this.$notice.success(
            this.$t('doc-uploaded', { filename: document.name })
          )
          eventBus.$emit('document-uploaded', {
            name: document.name,
            id: doc.id,
            uploading: false,
            removing: false,
            updatedAt: doc.created_at,
            uploadedText: 'uploaded',
            link: {
              type: 'emit',
              data: doc.id
            }
          })
        } else if (data.error) {
          const doc = data.error.data.docs[0]
          this.$store.commit('documents/removeDocument', doc.token)
          this.$notice.alert(this.$t('error-during-upload-doc'))
        }
      }
    })

    this.service.statusAll()
    this.service.onStatusAll(this.onStatusAll)
  }

  destroyed() {
    this.service.off(this.onStatusAll)
  }

  isDeepLinkMUP(): boolean {
    return get_query_param('upgrade') === 'true'
  }

  hasPermission = hasPermission

  isMockMode() {
    return process.env.NUXT_ENV_USERBOT_API_ENDPOINT_MOCKED === 'true'
  }

  onStatusAll(data: []) {
    this.$store?.commit('plugins/setPlugins', data)
  }
}
