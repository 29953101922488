export default class Eventer {
  private callbacks: {
    [key: string]: Function[]
  } = {}

  on(name: string, callback: Function) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = []
    }
    if (typeof callback === 'function') {
      this.callbacks[name].push(callback)
    }
    return this
  }

  emit(name: string, ...args: []) {
    this.callbacks[name]?.forEach((c: Function) => {
      c.call(this, ...args)
    })
    return this
  }
}
