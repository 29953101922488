import { User } from '../models/User'
import GoogleAnalytics from '@/assets/js/core/Analytics/GoogleAnalytics'
import Mixpanel from '@/assets/js/core/Analytics/Mixpanel'
import { Auth } from '@/modules/auth'
import env from '@/env'

class _Analytics {
  /**
   * @description start to track user behaviour
   * @param user
   */
  async init(user: User) {
    GoogleAnalytics.setUser(user)
    this.trackCrossdomainLinks()

    window.addEventListener('unload', () => {
      this.event('Browser', 'Close', 'Card')
    })

    await Mixpanel.setUser(user)

    return this
  }

  /**
   * Update user data
   * @param {*} data
   */
  updateUserData(_data: any) {
    // empty because there was encharge
    return this
  }

  async updateLoggedUserData() {
    await Mixpanel.setPeople(Auth.user())
  }

  async alias(user: User) {
    await Mixpanel.alias(user)
    GoogleAnalytics.setUser(user)
    return this
  }

  /**
   * @description track an Event
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   */
  event(eventCategory: string, eventAction: string, eventLabel: string) {
    /* console.log('event', eventCategory, eventAction, eventLabel) */
    Mixpanel.event(eventCategory, eventAction, eventLabel)
    GoogleAnalytics.event(eventCategory, eventAction, eventLabel)
    this.debug(`${eventCategory} - ${eventAction} - ${eventLabel}`)
  }

  /**
   * @description track future and present links crossdomains
   */
  trackCrossdomainLinks() {
    document.querySelector('body')?.addEventListener(
      'click',
      (evt: any) => {
        if (evt?.target?.tagName === 'A') {
          // detect click to outside site
          if (evt.target.target === '_blank') {
            const category = evt.target.dataset.analyticsCategory
              ? evt.target.dataset.analyticsCategory
              : 'Crossdomains'
            this.event(category, 'Click', evt.target.textContent)
          }

          // detect mailto event
          if (evt.target.href.includes('mailto:')) {
            const category = evt.target.dataset.analyticsCategory
              ? evt.target.dataset.analyticsCategory
              : 'Email'
            const text = evt.target.dataset.analyticsText
              ? evt.target.dataset.analyticsText
              : evt.target.textContent
            this.event(category, 'Click', text)
          }
        }
      },
      true
    )
  }

  debug(string: string) {
    if (process.env.USERBOT_DEBUG === 'true') {
      console.log('[DEBUG ANALYTICS]', string)
    }
  }
}

const Analytics = new _Analytics()

export default Analytics
