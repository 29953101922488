import { CreditCard } from '../CreditCard'
import { Model } from '@/assets/js/core/Model'

export class PaymentMethod extends Model {
  /**
   * @description get card
   * @return {CreditCard}
   */
  card() {
    if (!this._card) {
      this._card = new CreditCard(this.get('card'))
    }
    return this._card
  }

  /**
   * @description check if is default source
   * @return {boolean}
   */
  isDefault() {
    return !!this.get('defaultSource')
  }

  /**
   * @description set source as default based on value
   * @param value
   */
  setDefault(value) {
    this.set('defaultSource', !!value)
    return this
  }
}
