/* global Audio */
class _Browser {
  constructor() {
    this.bip = process.server ? null : new Audio('/media/notification.mp3')
    this.title = process.server ? '' : document.title
    this.faviconOriginal = '/favicon.ico'
    this.faviconAlert = '/favicon-dot.ico'
  }

  /**
   * notify Browser
   * @param params
   */
  notify(params) {
    if (params.badge) {
      this.changeBadgeTitle(params.badge)
    }

    if (params.popup) {
      this.webNotification(params)
    }

    if (params.silent !== true) {
      this.bip?.play().catch((err) => {
        // this.webNotification(params);
        console.log(err)
      })
    }

    return this
  }

  /**
   * ask to user to enable permission request notification
   */
  askNotifyPermissions() {
    return new Promise((resolve, reject) => {
      if ('Notification' in window) {
        if (window.Notification.permission !== 'granted') {
          return window.Notification.requestPermission()
            .then(() => {
              resolve(true)
            })
            .catch((e) => reject(e))
        } else {
          return resolve(true)
        }
      }
      return reject(new Error('Notification is not supported'))
    })
  }

  /**
   * change badge value on title
   * @param value
   */
  changeBadgeTitle(value) {
    if (value <= 0) {
      this.removeBadgeTitle()
    } else {
      document.title = `(${value}) ${this.title}`
      if (value > 0) {
        document.getElementById('favicon').href = this.faviconAlert
      }
    }
    return this
  }

  /**
   * remove badge title
   */
  removeBadgeTitle() {
    document.title = this.title
    document.getElementById('favicon').href = this.faviconOriginal
    return this
  }

  /**
   * web notification
   * @param params
   */
  webNotification(params) {
    this.askNotifyPermissions()
      .then(() => {
        if (params.title || params.body) {
          const notification = new window.Notification(params.title, {
            icon: '/images/logo-notifiche.png',
            body: params.body,
            requestInteraction: true,
          })

          notification.onclick = function () {
            if (window.location.href.includes('/chats')) {
              window.open(window.location.href, '_self')
            }
          }
        }
      })
      .catch(() => {
        console.warn('Notification are disabled or unavailable')
      })
  }

  /**
   * Set title browser
   * @param value
   */
  setTitle(value) {
    this.title = value
    document.title = value
  }
}

const Browser = new _Browser()

export default Browser
