import { render, staticRenderFns } from "./u-button-load.vue?vue&type=template&id=03d48251&scoped=true"
import script from "./u-button-load.vue?vue&type=script&setup=true&lang=ts"
export * from "./u-button-load.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./u-button-load.scss?vue&type=style&index=0&id=03d48251&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d48251",
  null
  
)

export default component.exports