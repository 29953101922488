import { Document } from '@/assets/js/interfaces/DocumentsAI'

type State = {
  documentsUploading: Document[],
  showOutsideOfBox: boolean,
  cardCollapsed: boolean
}

export default {
  state: (): State => ({
    documentsUploading: [],
    showOutsideOfBox: false,
    cardCollapsed: false,
  }),
  mutations: {
    addDocument(state: State, value: Document) {      
      state.documentsUploading.push(value)
    },
    removeDocument(state: State, value: string | number) {
      const index = state.documentsUploading.findIndex(doc => doc.id === value)
      if (index > -1) {
        state.documentsUploading.splice(index, 1)
      }
    },
    resetDocuments(state: State) {
      state.documentsUploading = []
      state.showOutsideOfBox = false
    },
    updatePercentual(state: State, value: Document) {
      const index = state.documentsUploading.findIndex(doc => doc.id === value.id)
      if (index > -1) {
        state.documentsUploading[index].percentCompleted = value.percentCompleted
      }
    },
    updateShowOutsideOfBox(state: State, value: boolean) {
      state.showOutsideOfBox = value
    },
    toggleCard(state: State) {
      state.cardCollapsed = !state.cardCollapsed
    }
  },
}
