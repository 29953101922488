import { LocalStorage } from '../services/LocalStorage'
import { Auth, hasPermission, user } from '@/modules/auth'
import { Model } from '@/assets/js/core/Model'
import env from '@/env'
import Analytics from '@/assets/js/core/Analytics'
import getArgument from '../helpers/getArgument'
import { DispatchEvents } from '../enum/DispatchEvents'
import dispatchNewEvent from '../helpers/dispatchNewEvent'

export class Bot extends Model {
  userbot: any
  firstTestChat: null
  _ondestroy: null | (() => void)
  _initialized: boolean
  lastArgument: string
  events: {
    onOpenChat: Array<() => void>
    onCloseChat: Array<() => void>
    onAsideDisanchor: Array<() => void>
    onAsideAnchor: Array<() => void>
  }

  /**
   * @param attributes
   */
  constructor(attributes: unknown) {
    super(attributes || {})

    this.firstTestChat = null
    this.userbot = null
    this._ondestroy = null
    this._initialized = false
    this.lastArgument = ''
    this.events = {
      onOpenChat: [],
      onCloseChat: [],
      onAsideDisanchor: [],
      onAsideAnchor: [],
    }
  }

  /**
   * get id Bot
   * @return {string}
   */
  id() {
    return this.get('argument')
  }

  /**
   * Key
   * @return {any}
   */
  key() {
    return this.get('server_key')
  }

  /**
   * get name bot
   * @return {string}
   */
  name() {
    return this.get('nickname')
  }

  /**
   * check if bot is suspended
   */
  isSuspended() {
    return Number(this.get('suspended')) === 1
  }

  /**
   * get socket
   * @return {*}
   */
  getSocket() {
    return (process.env.NUXT_ENV_USERBOT_AI_SERVER ?? '').split('//')[1]
  }

  /**
   * get hostname
   * @return {string}
   */
  getHostname() {
    return process.env.NUXT_ENV_USERBOT_WIDGET_CHAT ?? ''
  }

  /**
   * on destroy
   * @param fn
   */
  onDestroy(fn: () => void) {
    this._ondestroy = fn
  }

  /**
   * add on open chat
   * @param fn
   */
  onOpenChat(fn: () => void) {
    this.events.onOpenChat.push(fn)
  }

  /**
   * add on open chat
   * @param fn
   */
  onCloseChat(fn: () => void) {
    this.events.onCloseChat.push(fn)
  }

  /**
   * add on aside anchor chat
   * @param fn
   */
  onAsideAnchor(fn: () => void) {
    this.events.onAsideAnchor.push(fn)
  }

  /**
   * add on aside disanchor chat
   * @param fn
   */
  onAsideDisanchor(fn: () => void) {
    this.events.onAsideDisanchor.push(fn)
  }

  isSupportActive() {
    return env.supportChat.key && env.supportChat.customerToken
  }

  /**
   * Test bot
   * @return {Promise<void>}
   */
  test({ cookies, pageId }: { cookies: boolean; pageId: string }) {
    const tabTitle = document.title
    
    const id = this.key()
    
    if (cookies === false) {
      LocalStorage?.removeByRegex(/(userbot_).*/g)
      Analytics.event('Test AI', 'Click', 'Avvia nuova conversazione')
    } else {
      Analytics.event('Test AI', 'Click', 'Avvia vecchia conversazione')
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const self = this

      if (this.userbot) {
        await this.userbot.destroy({ cookies: false, withoutAnimation: true })
      }

      this.userbot = window.Userbot({
        key: id,
        customerToken: Auth.user().customerId(),
        debug: {
          enabled: hasPermission('AIDeepLearning'),
        },
        app: {
          socket: self.getSocket(),
          hostname: self.getHostname(),
        },
        chat: {
          cookies: true,
          opened: true,
          aside: {
            onload: true,
          },
        },
        answer_sources: true,
        operatorRole: 'Test Chat',
        /* operatorName: 'Userbot',
        operatorIcon: `https://${window.location.hostname}/images/icons/bot.svg`, */
        onSystemReady() {
          if (this.socket) {
            this.socket().send(`dashboard ${pageId}`)
          }
          self.lastArgument = self.id()

          addEventListener(DispatchEvents.URL_CHANGE, () => {

            const argument = getArgument()
          
            if (argument !== self.lastArgument) {
              self.lastArgument = argument
              dispatchNewEvent(DispatchEvents.BOT_CHANGE)

              if (self.userbot) {
                self.userbot.destroy({ cookies: false, withoutAnimation: true })
              }
            }
          });
          return resolve(true)
        },
        onBeforeCloseChatWindow: () => {
          self.events.onCloseChat.forEach((fn) => fn())
        },
        async onCloseChatWindow() {
          if (self._ondestroy) {
            self._ondestroy()
          }
          await this.destroy({ cookies: false, withoutAnimation: true })
          if (self.isSupportActive()) {
            self.support({ opened: false })
          }
        },
        onOpenChatWindow: () => {
          document.title = tabTitle
          self.events.onOpenChat.forEach((fn) => fn())
        },
        onAsideAnchor: () => {
          self.events.onAsideAnchor.forEach((fn) => fn())
        },
        onAsideDisanchor: () => {
          self.events.onAsideDisanchor.forEach((fn) => fn())
        },
      })
    })
  }

  support({ opened }: { opened: boolean }) {
    const self = this
    if (this.isSupportActive()) {
      return new Promise(() => {
        this.userbot = window.Userbot({
          key: env.supportChat.key,
          customerToken: env.supportChat.customerToken,
          chat: {
            opened,
          },
          app: {
            hostname: self.getHostname(),
            socket: self.getSocket(),
          },
          onSystemReady() {
            self.userbot.addUserInfo({
              user_name: user().firstname(),
              user_surname: user().lastname(),
              user_id: user().customerId(),
              user_email: user().email(),
            })
          },
        })
      })
    }
  }
}
