import Variable from '../models/Variable'
import { Socket } from '@/assets/js/core/Socket'
import BotVarsGetAll from '@/assets/js/interfaces/Services/BotVarsGetAll'

class _VariableService {
  load() {
    Socket.send('bot vars', {
      action: 'getAll',
    })
  }

  onLoad(cb: Function) {
    Socket.on('bot', (_: string, data: BotVarsGetAll) => {
      if (data.subcommand === 'vars') {
        if (data.params.action === 'getAll') {
          const vars = data.data.map((item: any) => {
            item.label = item.label || ''
            return new Variable(item)
          })
          cb(vars)
        }
      }
    })
  }

  create(variable: Variable) {
    Socket.send(`bot vars`, {
      action: 'create',
      name: variable.getName(),
      type: variable.type,
    })
  }
}

const VariableService = new _VariableService()

export default VariableService
