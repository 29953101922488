import { Vue, Component } from 'nuxt-property-decorator'
import { Bot } from '@/assets/js/models/Bot'

@Component
export default class BotAuthMixin extends Vue {
  get bot() {
    return this.$store?.state?.page?.bot
      ? new Bot(this.$store.state.page.bot)
      : null
  }
}
