import { Vue, Component } from 'nuxt-property-decorator'
import MUPOptions from '@/assets/js/interfaces/MUPOptions'
import { plans, ProfessionalPlan } from '@/assets/js/config/prices'
import { Plan } from '@/assets/js/models/Plan'

@Component
export default class UModalGeneralUpgradePlan extends Vue {
  get minPlan(): Plan | null {
    if (this.$store?.state.page.MUPStripe?.minPlanId) {
      const plan = plans.find((p: any) => {
        return p.id === this.$store?.state.page.MUPStripe.minPlanId
      })

      if (plan) {
        return new Plan(plan)
      }
    }
    return ProfessionalPlan
  }

  get modalUpgradePlanOptions(): MUPOptions {
    return {
      icon: 'dashboard',
      title: this.$t('upgrade.title').toString(),
      description: this.$t('upgrade.description').toString(),
      highlights: [
        {
          icon: 'brain',
          title: this.$t('upgrade.highlights.1.title').toString(),
          description: this.$t('upgrade.highlights.1.description').toString(),
        },
        {
          icon: 'user',
          title: this.$t('upgrade.highlights.2.title').toString(),
          description: this.$t('upgrade.highlights.2.description').toString(),
        },
        {
          icon: 'chart-line',
          title: this.$t('upgrade.highlights.3.title').toString(),
          description: this.$t('upgrade.highlights.3.description').toString(),
        },
      ],
    }
  }
}
