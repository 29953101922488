export default {
  name: 'form-checkbox',
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: [Boolean, Number],
      required: true,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    disabled(val, oldVal) {
      this.disabledStatus = val
    },
    modelValue: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.checked = val
        }
      },
    },
  },
  emits: ['click', 'input'],
  data() {
    return {
      checked: false,
      disabledStatus: false,
    }
  },
  computed: {},
  mounted() {
    this.disabledStatus = this.disabled
  },
  methods: {
    emitInput(e) {
      this.update(e.target.checked)
    },

    /**
     * @description update checkbox value
     * @param value
     */
    update(value = null) {
      if (this.canChangeValue()) {
        value = value === null ? this.checked : value
        this.checked = value
        this.$emit('update:modelValue', value)
      }
    },

    /**
     * @description check if has label
     * @return {*|VNode[]}
     */
    hasLabel() {
      return (this.label && this.label.length) || this.$slots.label
    },

    /**
     * @description can change value
     * @return {boolean}
     */
    canChangeValue() {
      return !this.disabledStatus && !this.readonly
    },

    /**
     * @description on click on label
     * @param e
     * @return {null|void}
     */
    onClickLabel(e) {
      /* e.stopPropagation(); */
      if (e.target.tagName === 'A') {
        window.open(e.target.href, e.target.target)
        e.preventDefault()
        return
      }
      e.preventDefault()
      if (this.canChangeValue()) {
        this.checked = !this.checked
        this.update()
      }
      this.$emit('click', e)
    },
  },
}
