import * as Sentry from '@sentry/vue'
import { isProduction } from '../assets/js/core/helpers'
import { User } from '@/assets/js/models/User'

type AuthenticationState = {
  user: User | null
  subscription: any | null
  billing: any | null
  profile: any | null
}

export default {
  state: (): AuthenticationState => ({
    user: null,
    subscription: null,
    billing: null,
    profile: null,
  }),
  mutations: {
    decreaseUsersUsed(state: AuthenticationState) {
      state.billing.userLimits.used--
    },
    increaseUsersUsed(state: AuthenticationState) {
      state.billing.userLimits.used++
    },
    setLogo(state: AuthenticationState, path: string) {
      state.profile.logo = path
    },
    setBilling(state: AuthenticationState, billing: any) {
      state.billing = billing
    },
    setSubscription(state: AuthenticationState, subscription: any) {
      state.billing = subscription
    },
    setProfile(state: AuthenticationState, profile: any) {
      state.profile = profile
    },
    setUser(state: AuthenticationState, user: User) {
      state.user = user
    },
  },
  actions: {
    async setUser({ dispatch }: any, user: User) {
      if (isProduction()) {
        Sentry.setUser({
          email: user.email(),
          id: user.get('id'),
        })
      }
      await dispatch('reloadSubscription')
    },
    async reloadSubscription({ commit, state }: any) {
      const user = new User(state.user)
      const billing = await user.billing()
      commit('setBilling', billing)
      const subscription = await user.subscription()

      if (state.billing) {
        subscription.addLimits(state.billing)
      }

      if (!location.href.includes('trial-ended')) {
        if (subscription.getTrialDaysRemaining() <= 0 && subscription.isOff()) {
          location.href = '/trial-ended/'
        }
      }

      commit('setSubscription', subscription)
    },
    async reload({ dispatch, state }: any) {
      state.user.load('billing')
      state.user.load('subscription')
      await dispatch('reloadSubscription')
    },
  },
}
