import { Vue, Component } from 'nuxt-property-decorator'
import { User } from '~/assets/js/models/User'

@Component
export default class UserAuthMixin extends Vue {
  get user() {
    return this.$store.state.authentication.user
      ? new User(this.$store.state.authentication.user)
      : null
  }
}
