import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class UModalTitle extends Vue {
  @Prop({ type: String, required: false, default: '' })
  title: string

  @Prop({ type: String, required: false, default: '' })
  description: string
}
