import {
  Vue,
  Component,
  Prop,
  Inject,
  Emit,
  Watch,
} from 'nuxt-property-decorator'

interface SubItem {
  title: string
  route: string
  active: boolean
  visible: boolean
}

@Component
export default class UNavbarMenuElement extends Vue {
  @Inject()
  readonly isDesktopHD: () => boolean

  @Prop({ type: String, required: false, default: '' })
  title: string

  @Prop({ type: Boolean, required: false, default: false })
  active: string

  @Prop({ type: String, required: false, default: '' })
  route: string

  @Prop({ type: String, required: false, default: '' })
  icon: string

  @Prop({ type: String, required: false, default: '' })
  iconId: string

  @Prop({ type: Object, required: false, default: () => {} })
  options: string

  @Prop({ type: String, required: false, default: 'li' })
  tag: string

  @Prop({ type: Boolean, required: false, default: false })
  expanded: boolean

  @Prop({ type: Array, required: false, default: () => [] })
  subItems: SubItem[]

  @Watch('navbarCheck', { immediate: true })
  onNavbarCheckChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      if (val && this.active && this.subItems.length) {
        this.subMenuExpanded = true
        this.$store.commit('navbar/setSubMenuOpened', this.title)
      }
    }
  }

  static emits = ['click']

  subMenuExpanded = false
  dropdownStatus = false

  get navbarStatus() {
    return this.$store.state.navbar.status
  }

  get navbarCheck() {
    return this.$store.state.navbar.fullOpened
  }

  get subMenuOpened() {
    if (this.$store.state.navbar.subMenuOpened === this.title) {
      return true
    } else {
      this.subMenuExpanded = false
      return false
    }
  }

  async mounted() {
    await this.$nextTick()
    setTimeout(() => {
      this.$emit('mounted')
    }, 300)
  }

  @Emit('click')
  emitClick(e: MouseEvent) {
    return e
  }

  toggle() {
    if (!this.subMenuExpanded) {
      this.$store.commit('navbar/setSubMenuOpened', this.title)
    }
    this.subMenuExpanded = !this.subMenuExpanded
    if (!this.subMenuExpanded) {
      this.$store.commit('navbar/setSubMenuOpened', '')
    }
  }

  closeDropdown() {
    this.dropdownStatus = false
    this.subMenuExpanded = false
  }
}
