import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { Permission } from '../permissions'
import { Bearer } from '@/assets/js/core/Http/Bearer'

type JwtPayload = {
  exp: number
  user: null | {
    email: string
    nome: string
    cognome: string
    session: boolean
    logged: boolean
    // eslint-disable-next-line camelcase
    customer_id: string
    // eslint-disable-next-line camelcase
    group_id: null | string
    id: string
    lastip: string
    lastlogin: string
    username: string
  }
  usergroups: string
  userplan: string
  userperms: Permission[]
}

class Jwt {
  get token() {
    return Bearer.getToken()
  }

  get payload(): JwtPayload {
    return this.token
      ? jwtDecode(this.token)
      : {
          exp: 0,
          user: null,
          userperms: [],
          userplan: '',
          usergroups: '',
        }
  }

  get permissions(): Permission[] {
    if (this.token) {
      return this.payload?.userperms ?? []
    }
    return []
  }

  isExpired() {
    return moment.unix(this.payload.exp).diff(moment(), 'minutes') < 3
  }

  delete() {
    Bearer.removeToken()
  }
}

const jwt = new Jwt()

export default jwt
