import { render, staticRenderFns } from "./loading-points.html?vue&type=template&id=f6a0ef28&scoped=true&external"
import script from "./loading-points.js?vue&type=script&lang=js&external"
export * from "./loading-points.js?vue&type=script&lang=js&external"
import style0 from "./loading-points.scss?vue&type=style&index=0&id=f6a0ef28&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6a0ef28",
  null
  
)

export default component.exports