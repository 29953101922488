import { BaseService } from '../BaseService'
import Analytics from '@/assets/js/core/Analytics'

export class SubscriptionService extends BaseService {
  /**
   * @description get subscription
   * @return {*}
   */
  get() {
    return this.http.mock().get('subscriptions')
  }

  /**
   * @description upgrade plan
   * @param id
   * @param checkout
   * @return {Promise}
   */
  upgrade(id, checkout) {
    Analytics.event('Payments', 'Click', 'Upgrade')
    return this.http.mock().post(`subscriptions/${id}/checkout`, { checkout })
  }
}
