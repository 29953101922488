import { Bot } from '@/assets/js/models/Bot'
import BotService from '@/assets/js/services/BotService'

interface BotsState {
  all: null | Bot[]
  loading: boolean
  limit: null | number
  counter: number
}

export const state = () => ({
  all: null,
  loading: true,
  limit: null,
  counter: 0,
})

export const mutations = {
  setAll(state: BotsState, value: Bot[]) {
    state.all = value
    state.loading = false
  },
  addFirst(state: BotsState, value: Bot) {
    state.all?.unshift(value)
  },
  removeBot(state: BotsState, index: number) {
    state.all?.slice(index)
    BotService.load()
  },
  updateLimit(state: BotsState, value: number | null) {
    state.limit = value
  },
  updateCounter(state: BotsState, value: number) {
    state.counter = value
  },
}

export const actions = {
  load({ commit }: any) {
    BotService.onList((data: any[]) => {
      commit('setAll', data)
    })

    BotService.load()
  },
}

export default {
  state,
  mutations,
  actions,
}
