import { render, staticRenderFns } from "./page-not-found.html?vue&type=template&id=f079715e&scoped=true&external"
import script from "./page-not-found.ts?vue&type=script&lang=ts&external"
export * from "./page-not-found.ts?vue&type=script&lang=ts&external"
import style0 from "./page-not-found.scss?vue&type=style&index=0&id=f079715e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f079715e",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports