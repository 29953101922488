import TagType from './types/TagType'
import TextType from './types/TextType'
import DeepLearningType from './types/DeepLearningType'
import { Model } from '@/assets/js/core/Model'

export class Question extends Model {
  /**
   * @param attrs
   */
  constructor(attrs) {
    super(attrs)

    if (!this.type()) {
      this._initType()
    }
  }

  /**
   * @description return type of question
   */
  type() {
    return this.get('type')
  }

  text() {
    return this.get('question_nl')
  }

  /**
   * @description set new type
   * @param newType
   */
  setType(newType) {
    if (this.hasTokenfield() && !(newType instanceof TagType)) {
      this.set('question_nl', this.get('question_nl').join(' '))
    } else if (!this.hasTokenfield() && newType instanceof TagType) {
      this.set('question_nl', this.get('question_nl').split(' '))
    }
    this.set('type', newType)
    this.set('deep_learning', this.isDeepLearning())
  }

  /**
   * @description Check if Question has a word
   * @param {*} word
   */
  contains(word) {
    if (this.hasTokenfield()) {
      return this.get('question_nl').some(
        (q) => q.toLowerCase() === word.toLowerCase()
      )
    }
    return this.get('question_nl').toLowerCase().includes(word)
  }

  /**
   * @description check if is TagType
   * @return {boolean}
   */
  hasTokenfield() {
    return this.type() instanceof TagType
  }

  /**
   * @default check if is deep learning question
   * @return {boolean}
   */
  isDeepLearning() {
    return this.type() instanceof DeepLearningType
  }

  /**
   * @description check if question is valid
   * @return {*}
   */
  isValid() {
    return Array.isArray(this.get('question_nl'))
      ? this.get('question_nl').length
      : this.get('question_nl').trim()
  }

  /**
   * @description init question type
   * @private
   */
  _initType() {
    if (Array.isArray(this.get('question_nl'))) {
      this.set('type', new TagType())
    } else if (!this.get('deep_learning')) {
      this.set('type', new TextType())
    } else {
      this.set('type', new DeepLearningType())
    }
  }
}
