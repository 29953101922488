import { Vue } from 'vue-property-decorator'
import QuillEditor from 'vue-quill-editor'
import Quill from 'quill'
import MagicUrl from 'quill-magic-url'
import CustomIcons from './icons'

const Link = Quill.import('formats/link')

Link.sanitize = function (url: string) {
  return url.substring(0, 4) !== 'http' &&
    !/^\S+@\S+\.\S+$/.test(url) &&
    !url.startsWith('@')
    ? `https://${url}`
    : url
}

const Inline = Quill.import('blots/inline')
const Break = Quill.import('blots/break')
const Embed = Quill.import('blots/embed')

class TagBlot extends Inline {
  static create() {
    const node = super.create()
    node.setAttribute('contenteditable', true)
    return node
  }
}
TagBlot.blotName = 'var'
TagBlot.tagName = 'var'

class SmartBreak extends Break {
  length() {
    return 1
  }

  value() {
    return '\n'
  }

  insertInto(parent: any, ref: any) {
    Embed.prototype.insertInto.call(this, parent, ref)
  }
}

SmartBreak.blotName = 'break'
SmartBreak.tagName = 'BR'

Quill.register(SmartBreak)
Quill.register(TagBlot)

const icons = Quill.import('ui/icons')
icons.bold = CustomIcons.bold
icons.italic = CustomIcons.italic
icons.underline = CustomIcons.underline
icons.list = CustomIcons.list
icons.link = CustomIcons.link

Quill.register(Link)
Quill.register('modules/magicUrl', MagicUrl)

Vue.use(QuillEditor)
