import RadialProgressBar from 'vue-radial-progress'
import Vue from 'vue'
import Notice from './models/Notice'
import { Socket } from '@/assets/js/core/Socket'
import { Message } from '@/assets/js/models/Message'
import { Chat } from '@/assets/js/models/Chat'
import Browser from '@/assets/js/core/Helpers/Browser'

/**
 * Get notification value
 */
function getNotificationValue(data) {
  if (data.robin_stats && data.robin_stats.notifications > 0) {
    return data.robin_stats.notifications
  } else if (data.counter_nota) {
    return data.counter_nota
  }
  return 0
}

export default {
  name: 'u-notice',
  components: {
    RadialProgressBar,
  },
  props: {
    widescreen: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      messages: [],
      browser: Browser,
      disabledChatNotification: false,
    }
  },
  computed: {
    visibileMessages() {
      return this.messages.filter((m) => !m.get('dismissed'))
    },
  },
  beforeCreate() {
    Vue.prototype.$notice = this
  },
  mounted() {
    /**
     * @description show notification
     */
    Socket.on('chat-notification', (_, data) => {
      if (data.data.bot_argument === this.$route.params.botKey) {
        Socket.send(`chat-notification-received ${data.notificationId}`)
        // this.browser.changeBadgeTitle(data.robin_stats.total);
        const chat = new Chat(data.session)
        const message = new Message(data.data.message)
        let name = null
        let content = null
        const badge = getNotificationValue(data)

        if (chat.isUpscaled() && message.isUser()) {
          name = message.username()
          content = message.html()
        } else if (message.isFallback()) {
          name = chat.username()
          content = chat.get('humanMessage')
        }

        if (name && content) {
          this.sendChatIfPageNotificationIsEnabled(chat, {
            name,
            content,
            badge,
          })
        }
      }
    })
  },
  methods: {
    /**
     * @description dismiss alert message
     */
    dismiss(i) {
      this.messages.splice(i, 1)
    },

    /**
     * @description notice
     * @param props
     * @return {*|void}
     */
    notice(props) {
      if (typeof props === 'string') {
        props = { content: props }
      }
      props.type = 'alert-info'
      return this.addNotice(new Notice(props))
    },

    /**
     * @description notice a new chat message
     * @param props
     */
    chat(props) {
      if (typeof props === 'string') {
        props = { content: props }
      }
      props.icon = 'notification-2'
      props.type = 'check'

      this.browser.notify({
        title: props.title,
        body: props.content,
        popup: document.hidden,
        badge: props.badge,
      })

      return this.addNotice(new Notice(props))
    },

    /**
     * @description add success notice
     * @param props
     * @return {*|void}
     */
    success(props) {
      if (typeof props === 'string') {
        props = { content: props }
      }
      props.type = 'check'
      return this.addNotice(new Notice(props))
    },

    /**
     * @description add alert notice
     * @param props
     * @return {*|void}
     */
    alert(props) {
      if (typeof props === 'string') {
        props = { content: props }
      }
      props.type = 'alert'
      return this.addNotice(new Notice(props))
    },

    /**
     * @description add a new message
     * @param message
     */
    addNotice(message) {
      this.messages.unshift(message)

      if (message.isTimed()) {
        this.$nextTick(() => {
          message.countdown()
        })
      }

      return message
    },

    /**
     * @description get radial start color
     * @return {*|string}
     */
    getRadialStartColor(type) {
      switch (type) {
        case 'alert':
          return '#EF3600'

        case 'check':
          return '#00A6CA'

        case 'chat':
          return '#00A6CA'

        default:
          return '#B6B0E5'
      }
    },

    /**
     * @description get radial stop color
     * @return {*|string}
     */
    getRadialStopColor(type) {
      return this.getRadialStartColor(type)
    },

    /**
     * @description get radial inner color
     * @return {string}
     */
    getRadialInnerColor(type) {
      switch (type) {
        case 'alert':
          return '#FAC2B2'

        case 'check':
          return '#B2E4EF'

        case 'chat':
          return '#B2E4EF'

        default:
          return '#E5E2FF'
      }
    },

    /**
     * @description get content
     * @param message
     */
    getContent(message) {
      if (message) {
        if (message.get('trim')) {
          const maxLength = 50
          if (message.get('content').length > maxLength) {
            let trimmedString = message.get('content').substr(0, maxLength)
            trimmedString = trimmedString.substr(
              0,
              Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            )
            return `${trimmedString}...`
          }
        }
        return message.get('content')
      }
      return ''
    },

    getIcon(message) {
      return message.get('icon')
        ? `/images/icons/${message.get('icon')}.svg`
        : `/images/icons/${message.get('type')}.svg`
    },

    /**
     * @description is desktop
     */
    isDesktop() {
      return document.body.clientWidth >= 1367
    },

    disableChatNotification() {
      this.disabledChatNotification = true
    },

    enableChatNotification() {
      this.disabledChatNotification = false
    },

    sendChatIfPageNotificationIsEnabled(_, { name, content, badge }) {
      if (!this.disabledChatNotification) {
        this.chat({
          title: this.$t('name-wrote-you', { name }),
          content,
          badge,
          trim: true,
        })
      }
    },
  },
}
