import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import random from 'lodash/random'
import { gravatar } from '@/assets/js/core/helpers'
import { User } from '@/assets/js/models/User'

@Component
export default class UUserPicture extends Vue {
  @Prop({ type: String, required: false })
  src: string

  @Prop({ type: [Number, String], required: false })
  size: number | string

  @Prop({ type: [Number, String], required: false })
  width: number | string

  @Prop({ type: [Number, String], required: false })
  height: number | string

  @Prop({ type: Number, required: false, default: 0 })
  index: number

  @Prop({ type: Boolean, required: false, default: true })
  random: boolean

  @Prop({ type: Object, required: false, default: () => null })
  user: User | null

  @Prop({ type: Boolean, required: false, default: false })
  loading: boolean

  @Prop({ type: Boolean, required: false, default: null })
  online: boolean | null

  @Watch('online', { immediate: true })
  onOnlineChange(val: boolean, oldVal: boolean | undefined) {
    if (val !== null && val !== oldVal) {
      this.onlineStatus = val
    }
  }

  onlineStatus: null | boolean = null

  get image() {
    if (this.src) {
      if (this.src.startsWith('http')) {
        return this.src
      }
      return `/${this.src}`
    }

    return this.getStringImage()
  }

  get widthImage() {
    return this.size || this.width
  }

  get heightImage() {
    return this.size || this.height
  }

  /**
   * @description get string image
   * @return {string}
   */
  getStringImage() {
    const _default = `/images/avatars/user${this.getIndex()}.png`
    if (this.user && this.user.email()) {
      return gravatar(this.user.email(), {
        default: this.user.image(),
      })
    }
    return _default
  }

  /**
   * @description get index random or not on props
   * @return {number}
   */
  getIndex() {
    if (this.random) {
      return random(0, 6)
    }
    return this.index
  }
}
