import { render, staticRenderFns } from "./u-chevron.html?vue&type=template&id=b635774e&scoped=true&external"
import script from "./u-chevron.ts?vue&type=script&lang=ts&external"
export * from "./u-chevron.ts?vue&type=script&lang=ts&external"
import style0 from "./u-chevron.scss?vue&type=style&index=0&id=b635774e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b635774e",
  null
  
)

export default component.exports