const bold =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path d="M6.02911 19H13.3857C16.0553 19 17.9709 17.4512 17.9709 15.2096C17.9709 13.6608 17.0335 12.3566 15.4032 11.623C16.6463 10.9709 17.3595 9.93159 17.3595 8.50509C17.3595 6.46725 15.6274 5 13.1208 5H6.02911V19ZM9.02475 10.6448V7.56769H12.3668C13.4876 7.56769 14.2824 8.2198 14.2824 9.09607C14.2824 9.99272 13.4876 10.6448 12.3668 10.6448H9.02475ZM9.02475 16.4323V13.0087H12.8355C14.0378 13.0087 14.8937 13.722 14.8937 14.7205C14.8937 15.7191 14.0378 16.4323 12.8355 16.4323H9.02475Z" fill="inerith"/></svg>'
const italic =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path d="M14.2241 6.68C14.1287 6.68 14.0466 6.74737 14.0279 6.84092L11.9876 17.0809C11.963 17.2046 12.0576 17.32 12.1838 17.32H14.3983C14.5238 17.32 14.6183 17.4342 14.5948 17.5574L14.351 18.8374C14.333 18.9318 14.2505 19 14.1545 19H7.1217C6.99623 19 6.90175 18.8858 6.92523 18.7626L7.16904 17.4826C7.18701 17.3882 7.26948 17.32 7.36551 17.32H9.77623C9.87148 17.32 9.95352 17.2528 9.9723 17.1594L12.0318 6.91943C12.0568 6.79562 11.9621 6.68 11.8358 6.68H9.6017C9.47623 6.68 9.38175 6.56582 9.40523 6.44258L9.64904 5.16258C9.66701 5.06825 9.74948 5 9.84551 5H16.8783C17.0038 5 17.0983 5.11418 17.0748 5.23742L16.831 6.51742C16.813 6.61175 16.7305 6.68 16.6345 6.68H14.2241Z" fill="inerith"/></svg>'
const underline =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path d="M12 18.6396C15.3626 18.6396 17.7672 16.1958 17.7672 12.755V4.97415H15.7731V12.7159C15.7731 15.1206 14.2091 16.841 12 16.841C9.77127 16.841 8.20727 15.1206 8.20727 12.7159V4.97415H6.23273V12.755C6.23273 16.1958 8.61782 18.6396 12 18.6396Z" fill="inerith"/><path d="M5.95984 21.3436C5.95984 20.9846 6.25085 20.6936 6.60984 20.6936H17.3901C17.7491 20.6936 18.0401 20.9846 18.0401 21.3436V21.3436C18.0401 21.7026 17.7491 21.9936 17.3901 21.9936H6.60984C6.25085 21.9936 5.95984 21.7026 5.95984 21.3436V21.3436Z" fill="inerith"/></svg>'
const list =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path d="M4 7.16498C4 6.88884 4.22386 6.66498 4.5 6.66498H5.5C5.77614 6.66498 6 6.88884 6 7.16498V8.16498C6 8.44112 5.77614 8.66498 5.5 8.66498H4.5C4.22386 8.66498 4 8.44112 4 8.16498V7.16498Z" fill="inerith"/><path d="M8 7.16498C8 6.88884 8.22386 6.66498 8.5 6.66498H19.5C19.7761 6.66498 20 6.88884 20 7.16498V8.16498C20 8.44112 19.7761 8.66498 19.5 8.66498H8.5C8.22386 8.66498 8 8.44112 8 8.16498V7.16498Z" fill="inerith"/><path d="M4 11.5C4 11.2239 4.22386 11 4.5 11H5.5C5.77614 11 6 11.2239 6 11.5V12.5C6 12.7761 5.77614 13 5.5 13H4.5C4.22386 13 4 12.7761 4 12.5V11.5Z" fill="inerith"/><path d="M4 15.835C4 15.5589 4.22386 15.335 4.5 15.335H5.5C5.77614 15.335 6 15.5589 6 15.835V16.835C6 17.1112 5.77614 17.335 5.5 17.335H4.5C4.22386 17.335 4 17.1112 4 16.835V15.835Z" fill="inerith"/><path d="M8 11.5C8 11.2239 8.22386 11 8.5 11H19.5C19.7761 11 20 11.2239 20 11.5V12.5C20 12.7761 19.7761 13 19.5 13H8.5C8.22386 13 8 12.7761 8 12.5V11.5Z" fill="inerith"/><path d="M8 15.835C8 15.5589 8.22386 15.335 8.5 15.335H19.5C19.7761 15.335 20 15.5589 20 15.835V16.835C20 17.1112 19.7761 17.335 19.5 17.335H8.5C8.22386 17.335 8 17.1112 8 16.835V15.835Z" fill="inerith"/></svg>'
const link =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.6486 5.00628C14.4665 3.25051 17.3561 3.27562 19.1432 5.06271C20.9303 6.8498 20.9554 9.7394 19.1996 11.5573L19.1907 11.5665L16.8557 13.9014C15.9053 14.8521 14.5889 15.3431 13.2481 15.2471C11.9073 15.1511 10.6743 14.4774 9.86917 13.401C9.62395 13.0732 9.69092 12.6086 10.0187 12.3634C10.3466 12.1182 10.8111 12.1852 11.0563 12.513C11.6039 13.245 12.4423 13.703 13.354 13.7683C14.2658 13.8336 15.1609 13.4997 15.8072 12.8532L18.1375 10.5229C19.3271 9.28654 19.3086 7.32481 18.0949 6.11103C16.8808 4.89701 14.9186 4.87877 13.6822 6.06912L12.3475 7.3961C12.0571 7.68474 11.5878 7.68337 11.2992 7.39305C11.0105 7.10272 11.0119 6.63337 11.3022 6.34473L12.6486 5.00628Z" fill="inerith"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.14456 10.0985C8.0949 9.14788 9.41126 8.65686 10.752 8.75288C12.0928 8.8489 13.3258 9.52254 14.131 10.599C14.3762 10.9268 14.3092 11.3913 13.9814 11.6365C13.6536 11.8818 13.189 11.8148 12.9438 11.487C12.3963 10.755 11.5578 10.2969 10.6461 10.2316C9.73436 10.1663 8.83921 10.5003 8.19298 11.1467L5.86267 13.477C4.67303 14.7134 4.6915 16.6752 5.90528 17.8889C7.11906 19.1027 9.08076 19.1212 10.3172 17.9316L11.6433 16.6054C11.9328 16.3159 12.4022 16.3159 12.6917 16.6054C12.9811 16.8949 12.9811 17.3642 12.6917 17.6537L11.3516 18.9938C9.53373 20.7495 6.64405 20.7243 4.85696 18.9373C3.06987 17.1502 3.04477 14.2606 4.80053 12.4427L4.80948 12.4334L7.14456 10.0985C7.1446 10.0985 7.14453 10.0985 7.14456 10.0985Z" fill="inerith"/></svg>'
const emoji =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="#A5A4AD" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="inerith"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="inerith"/><path d="M10.7059 10.1765C10.7059 10.7287 10.2582 11.1765 9.70587 11.1765C9.15359 11.1765 8.70587 10.7287 8.70587 10.1765C8.70587 9.62417 9.15359 9.17645 9.70587 9.17645C10.2582 9.17645 10.7059 9.62417 10.7059 10.1765Z" fill="inerith"/><path d="M15.7059 10.1765C15.7059 10.7287 15.2582 11.1765 14.7059 11.1765C14.1536 11.1765 13.7059 10.7287 13.7059 10.1765C13.7059 9.62417 14.1536 9.17645 14.7059 9.17645C15.2582 9.17645 15.7059 9.62417 15.7059 10.1765Z" fill="inerith"/><path d="M12 16.4603C13.8537 16.4603 15.3945 15.4555 15.7069 14.1324C15.7704 13.8637 15.5409 13.6368 15.2647 13.6368H8.7353C8.45916 13.6368 8.22966 13.8637 8.29311 14.1324C8.60548 15.4555 10.1463 16.4603 12 16.4603Z" fill="inerith"/></svg>'

export default {
  bold,
  italic,
  underline,
  list,
  link,
  emoji,
}
