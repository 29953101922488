/* eslint-disable new-cap */
import Collection from '@/assets/js/core/Collection'
import { Api, Api2 } from '@/assets/js/core/Api'
import { Socket } from '@/assets/js/core/Socket'

export class BaseService {
  http = Api
  http2 = Api2
  name = ''
  model: any
  socket = Socket

  /**
   * @description return collection of Model
   * @param array
   */
  collection(array: []) {
    const tmp = new Collection([])
    array.forEach((item) => {
      tmp.add(new this.model(item))
    })
    return tmp
  }

  off(cb: Function) {
    Socket.off(this.name, cb)
  }
}
