/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Sortable from 'sortablejs'

let _sortable = null
let _count_sortable = 0

Vue.directive('sortable', {
  bind(el, binding) {
    if (binding.value.sort === false) return

    const options = Object.assign(
      {},
      {
        group: {
          name: `sortable${_count_sortable}`,
          pull: false,
          put: false,
        },
        sort: true,
      },
      binding.value
    )

    _sortable = new Sortable(el, options)
    _count_sortable++
  },
})
