import allPlans from './plans'
import free from '@/assets/js/config/prices/free'
import team from '@/assets/js/config/prices/team'
import pro from '@/assets/js/config/prices/professional'
import corporate from '@/assets/js/config/prices/corporate'

import freeChats from '@/assets/js/config/prices/chats/free'
import teamChats from '@/assets/js/config/prices/chats/team'
import proChats from '@/assets/js/config/prices/chats/professional'

import freeAgents from '@/assets/js/config/prices/agents/free'
import teamAgents from '@/assets/js/config/prices/agents/team'
import proAgents from '@/assets/js/config/prices/agents/professional'

import { Plan } from '@/assets/js/models/Plan'

const stripeKeyFree = 'products.license.sandbox.month'
const stripeKeyTeamMonth = 'products.license.starter.month'
const stripeKeyProMonth = 'products.license.professional.month'

free.stripe_key = { month: stripeKeyFree }
team.stripe_key = { month: stripeKeyTeamMonth }
pro.stripe_key = { month: stripeKeyProMonth }

const freeChatsArray = freeChats.map((c) => {
  c.plan_id = stripeKeyFree
  return c
})
const teamChatsArray = teamChats.map((c) => {
  c.plan_id = stripeKeyTeamMonth
  return c
})
const proChatsArray = proChats.map((c) => {
  c.plan_id = stripeKeyProMonth
  return c
})

const freeAgentsArray = freeAgents.map((c) => {
  c.plan_id = stripeKeyFree
  return c
})

const teamAgentsArray = teamAgents.map((c) => {
  c.plan_id = stripeKeyTeamMonth
  return c
})
const proAgentsArray = proAgents.map((c) => {
  c.plan_id = stripeKeyProMonth
  return c
})

export const plans = [free, team, pro, corporate, ...allPlans]

export const visiblePlans = [free, team, pro, corporate]

export const FreePlan = new Plan(free)
export const CorporatePlan = new Plan(corporate)
export const ProfessionalPlan = new Plan(pro)
export const TeamPlan = new Plan(team)

export const chats = [...freeChatsArray, ...teamChatsArray, ...proChatsArray]

export const agents = [
  ...freeAgentsArray,
  ...teamAgentsArray,
  ...proAgentsArray,
]
