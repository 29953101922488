/* eslint-disable camelcase */
import {
  Component,
  Prop,
  mixins,
  Watch,
  Emit,
  Ref,
} from 'nuxt-property-decorator'
import { TweenLite, ScrollToPlugin } from 'gsap/all'
import moment from 'moment-timezone'
import { uniqueId } from 'lodash'
import components from './db-ai.components'
import ExportCategory from './interfaces/ExportCategory'
import { Interaction } from '@/assets/js/models/Interaction/Interaction'
import { Socket } from '@/assets/js/core/Socket'
import {
  BotAuthMixin,
  UserAuthMixin,
  hasPermission,
  hasPermissions,
} from '@/modules/auth'
import AnimationListMixins from '@/assets/js/mixins/animationList'
import Analytics from '@/assets/js/core/Analytics'
import Debounce from '@/assets/js/decorators/Debounce'
import InteractionGroup from '@/assets/js/interfaces/Interaction/InteractionGroup'
import AiInteractionComponent from '@/components/ai-interaction/ai-interaction.interface'
import UQuestionsComponent from '@/components/u-questions/u-questions.interface'
import DeepLearningType from '@/assets/js/models/Interaction/types/DeepLearningType'
import TrainingService from '@/assets/js/services/TrainingService'
import { Message } from '@/assets/js/models/Message'
import GenerativeAiService from '@/assets/js/services/GenerativeAI'
import AttachFileService from '@/assets/js/services/AttachFileService'
import { eventBus } from '@/modules/events/bus'
import GptService from '@/assets/js/services/GptService'
import UrlPage from '~/assets/js/interfaces/UrlPage'
import UrlAsDoc from '@/assets/js/interfaces/UrlAsDoc'
import isPastDate from '@/assets/js/helpers/isPastDate'
import {
  TypeNotification,
  NotifyCommand,
  NotifyMessageKey,
  DocTransformationType,
  DocTransformationStatus,
  getDocType,
} from '@/assets/js/interfaces/DocumentsAI'
import axios from 'axios'
import { Document } from '@/assets/js/interfaces/DocumentsAI'
import { Plugin as PluginInfo } from '@/store/plugins'
import HttpRequest from '~/assets/js/interfaces/HttpRequest'
import { defautlCollections } from '@/assets/js/interfaces/Plugins/Sharepoint'

const scrollPlugin = ScrollToPlugin

type AiType = 0 | 1 | 2 | 3
export enum ErrorType {
  STOP_BY_USER = 'stop by user',
}

@Component({
  components,
})
export default class PageDbAi extends mixins(
  AnimationListMixins,
  BotAuthMixin,
  UserAuthMixin
) {
  @Ref('newInteraction')
  refNewInteraction: AiInteractionComponent

  @Ref('questions')
  refQuestions: UQuestionsComponent

  @Ref('interactions')
  refInteractions: AiInteractionComponent[]

  @Prop({ type: String })
  serviceServer: string

  static emits = ['loaded']

  // Ref counter page
  refCounter = 0

  /**
   * @description check if page is disabled
   */
  disabled = !hasPermission('AIEdit')

  /**
   * @description array of Interaction
   */
  interactions: Interaction[] = []

  /**
   * @description check if first element is added now
   */
  addedNow = false

  /**
   * @description boolean modal remove
   */
  modalRemoveInteractionsSelected = false

  /**
   * @description boolean modal remove
   */
  modalMoveInteractionsSelected = false

  /**
   * @description modal new interaction
   */
  modalNewInteraction = false

  /**
   * @description new Interaction
   */
  newInteraction: Interaction | null = null

  /**
   * @description array of groups
   */
  groups: InteractionGroup[] = []
  selectedGroups: InteractionGroup[] = []

  /**
   * @description group to move in
   */
  groupToMoveIn: InteractionGroup | null = null

  /**
   * @description get totals length
   */
  totals = 0

  /**
   * @description filters selected
   */
  filters = []

  /**
   * @description filter panel status open/close
   */
  filterPanel = true

  fromSearch = 0
  toSearch = 25
  searchString = ''
  countSearch = 0
  searchAI = [2]
  showSearch = false
  showFilters = false
  noMoreLoading = false

  // Import
  importFiles = []

  // Export
  exportFilter = null
  exportFormat = 'json'
  exportFormats = ['json', 'xlsx']
  exportCategory: ExportCategory | null = null
  exportCategories: ExportCategory[] = [
    {
      name: 'Tutte',
      value: 'all',
    },
  ]

  // First loading
  loading = true

  // Searching status
  searching = false

  // Exporting status
  // 0 is not exporting
  // 1 is exporting
  // 2 has completed export
  exporting = 0

  // exported file
  exportDownloadFile: string = ''

  /**
   * @description select all checked
   */
  selectAllChecked = false
  service: TrainingService

  /**
   * @description waiting socket response
   */
  newInteractionAdding = false

  /**
   * @description counter key interactions
   */
  counterKeyInteractions = 0

  /**
   * @description tourer steps test chat
   */
  startTestChatSteps = false

  /**
   * @description training status
   */
  trainingStarted = false

  /**
   * @description icon empty box
   */
  iconEmptyBox = require('./assets/interactions.svg')

  private moreLoading = true
  previewChatModalStatus = false
  conversation: any[] = []
  userConversation = ''
  chatId = 0

  modalUpgradePlanOptions = {
    icon: 'addestramento',
    title: this.$t('upgrade.title'),
    description: this.$t('upgrade.description'),
    highlights: [
      {
        icon: 'comments',
        title: this.$t('upgrade.highlights.1.title'),
        description: this.$t('upgrade.highlights.1.description'),
      },
      {
        icon: 'bug',
        title: this.$t('upgrade.highlights.2.title'),
        description: this.$t('upgrade.highlights.2.description'),
      },
      {
        icon: 'brain',
        title: this.$t('upgrade.highlights.3.title'),
        description: this.$t('upgrade.highlights.3.description'),
      },
    ],
  }

  private scrolling: boolean = false
  private questionToApproveFromInteraction: any
  modalImportTrainingStatus = false
  trainingFromImport = false
  isThereAtLeastOneInteractionInTotal = false
  generativeAiConfigurationStatus = false
  documents: Document[] = []
  sharepointDocuments: Document[] = []
  url: any[] = []
  genService: GenerativeAiService
  gptService: GptService
  urlToEdit = ''
  documentsLoading = true
  urlLoading = true
  isAlanEnabled = false
  generativeAIConfigurationRequired = false
  webPageInterval: ReturnType<typeof setInterval> | undefined = undefined
  httpData: HttpRequest = {
    method: 'GET',
    url: '',
    params: {},
    headers: {},
    body: '',
  }

  get subscription() {
    return this.$store?.state.authentication.subscription
  }

  get interactionsAvailable() {
    if (this.subscription && this.subscription.get('max_interactions') > -1) {
      return this.subscription.get('max_interactions') - this.totals
    }
    return -1
  }

  get upgradeText() {
    const string = `${this.$t('upgrade-page-text')} `
    if (hasPermission('Buy')) {
      return string + this.$t('upgrade-page-text-default')
    } else if (this.user?.hasAgency()) {
      return string + this.$t('upgrade-page-text-agency')
    } else if (this.user?.isUsingSaleProduct()) {
      return string + this.$t('upgrade-page-text-ovy')
    }
    return string + this.$t('upgrade-page-text-ltd')
  }

  /**
   * @description get selected interactions
   */
  get selectedInteractions() {
    return this.interactions.filter((i: Interaction) => i.get('selected'))
  }

  /**
   * @description flows active and published
   * @return {Array}
   */
  get flowsActive() {
    return this.flows.filter((item: any) => item.isVisible && item.publishedAt)
  }

  get flows() {
    return this.$store?.state.flows.all
  }

  get groupsFilter() {
    return this.groups.filter(
      (g: InteractionGroup) => g.name !== 'userbot-none'
    )
  }

  get chatLink() {
    return `/bots/${this.bot?.id()}/chats/${this.chatId}`
  }

  @Watch('searchString')
  onSearchStringChange(val: string) {
    this.showSearch = true
    this.fromSearch = 0
    this.search()

    if (val && val.length) {
      Analytics.event('Addestramento AI', 'Ricerca', `"${val}"`)
    }
  }

  @Watch('modalNewInteraction')
  onModalNewInteractionChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      if (val) {
        this.$nextTick(() => {
          this.$el.scrollTop = 0
        })
      } else if (this.questionToApproveFromInteraction) {
        this.questionToApproveFromInteraction.hide = 0
      }
    }
  }

  get lastImportTraining() {
    return this.$store?.state.importTraining
  }

  get isSharepointActive() {
    const plugins: PluginInfo[] = this.$store?.state.plugins.plugins
    return !!plugins.find(plugin => plugin.name === 'sharepoint')
  }

  head() {
    return {
      title: this.$t('training-ai'),
    }
  }

  mounted() {
    this.genService = new GenerativeAiService(this.bot?.get('id'))
    this.service = new TrainingService(this.bot?.get('id'))
    this.gptService = new GptService(this.bot?.get('id'))

    this.openGenerativeAiTab()

    if (this.lastImportTraining.flag) {
      this.trainingFromImport = true
      if (this.loading) {
        this.loaded()
        this.loading = false
      }
    } else if (this.lastImportTraining.timestamp) {
      const finishTraining = moment(new Date(this.lastImportTraining.timestamp))
        .add(5, 'm')
        .toDate()
      const now = new Date()
      if (finishTraining > now) {
        this.trainingFromImport = true

        if (this.loading) {
          this.loaded()
          this.loading = false
        }

        this.startCountdownFinishTraining()
      }
    }

    if (!hasPermission('AIEdit')) {
      this.toSearch = 20
    }
    this.exportCategory = this.exportCategories[0]

    this.newInteraction = this._getNewInteraction()

    this.bindSocketCalls()
    this.getGroupsList()
  }

  beforeDestroy() {
    clearInterval(this.webPageInterval)
    eventBus.$off('document-uploaded')
  }

  onListEnterExtend(el: HTMLElement, done: Function) {
    this.onListEnter(el, () => {
      this.noMoreLoading = this.moreLoading
      done()
    })
  }

  /**
   * @description capture socket calls
   */
  bindSocketCalls() {
    Socket.on('robin', (_cmd: string, data: any) => {
      if (data.subcommand === 'get') {
        this.conversation =
          data.sessions.map((item: any) => new Message(item)) || []
      }
    })

    Socket.on('session', (_cmd: string, data: any) => {
      if (data.subcommand === 'get-by-id') {
        this.userConversation = data.data.user
      }
    })

    Socket.on('import-training', (_: string, data: any) => {
      if (data.status === 200) {
        this.$store?.commit('importTraining/updateTimestamp', data.timestamp)
        this.startCountdownFinishTraining()
        this.interactions = []
        if (data.reloadFlows) {
          Socket.send('flow loadAll')
        }
      } else {
        this.trainingFromImport = false
        this.$notice.alert(this.$t('training-import.error'))
      }
    })

    eventBus.$on('merge-interactions', (data: any) => {
      this.selectedInteractions.forEach((item) => this._removeInteraction(item))
      data.data.insertedRecord.key = this.counterKeyInteractions
      this.counterKeyInteractions += 1
      this.interactions.unshift(new Interaction(data.data.insertedRecord))
      this.totals++
      Interaction.clearLastInserted()
      this.refCounter += 1
    })

    Socket.on('export', (data: any) => {
      if (data.status === 200) {
        this.exporting = 2
        this.exportDownloadFile = `/fileuploads/export.${this.exportFormat}`
      }
    })

    Socket.on('flow', (_: string, data: any) => {
      if (data.subcommand === 'store') {
        this.flows.push(data.inserted)
      }
    })

    eventBus.$on('document-uploaded', (data: any) => {
      this.documents.unshift(data)
    })

    Socket.on('notify', (_, data) => {
      const notify: NotifyCommand = data.data
      if (
        (notify.messageKey === NotifyMessageKey.complete ||
          notify.messageKey === NotifyMessageKey.failed) &&
        this.documents?.length
      ) {
        const i = this.documents.findIndex(
          (document) => document.id === notify.messageArgs?.docId
        )
        if (i > -1) {
          this.documents[i].imgAnalyzed = notify.type === TypeNotification.info
          this.documents[i].images = notify.messageArgs?.imagesNo || null
          this.documents[i].uploadingTxt = ''
          this.documents[i].uploading = false
        }
      }
    })

    this.$store.dispatch('flows/load')
    this.search()
  }

  @Debounce(2000)
  search(withLoading = true) {
    if (!this.trainingFromImport) {
      const text = this.searchString ? this.searchString.trim() : ''
      if (!this.searching) {
        if (withLoading !== false) {
          this.searching = true
        }

        setTimeout(() => {
          this.getInteractions({
            text,
            ai: this.searchAI,
            groups: this.selectedGroups.map((g) => g.name),
            limit_from: this.fromSearch,
            limit_to: this.toSearch,
          })
        }, 1000)
      }
    }
  }

  /**
   * @description scroll search
   */
  scrollSearch() {
    if (this.scrolling === false) {
      this.scrolling = true
      this.fromSearch = this.interactions.length
      this.search(false)
    }
  }

  /**
   * @description check if search form is visible
   */
  isFiltering() {
    return (
      this.searchString ||
      this.searchAI.includes(1) ||
      this.selectedGroups.length
    )
  }

  /**
   * @description add a new interaction
   */
  addInteraction(interaction: Interaction | null = null) {
    if (!interaction) {
      interaction = this._getNewInteraction()
    }
    this.saveInteraction(interaction)
    this.newInteractionAdding = true
    this.startTestChatSteps = true
    this.totals++
    Analytics.event('Addestramento AI', 'Click', 'Salva nuova interazione')
  }

  /**
   * @description update interaction
   * @param interaction
   */
  updateInteraction(interaction: Interaction) {
    this.saveInteraction(interaction)
  }

  /**
   * @description copy interaction
   * @param interaction
   */
  copyInteraction(interaction: Interaction) {
    const copied = interaction.getCopy({
      expanded: true,
    })
    this.newInteraction = null

    this.$nextTick(() => {
      this.newInteraction = copied
      const lapse = window.scrollY > 1000 ? 1 : 1000 / window.scrollY
      TweenLite.to(window, 1 / lapse, {
        scrollTo: 0,
        onComplete: () => {
          this.modalNewInteraction = true

          setTimeout(() => {
            ;(this.refNewInteraction.$refs.name as any).focus()
          }, 600)
        },
      })
    })
    // this.addInteraction(copied);
  }

  /**
   * @description remove interaction from array
   * @param item
   * @private
   */
  _removeInteraction(item: Interaction) {
    const key = this.interactions.findIndex((int) => item.id() === int.id())
    this.interactions.splice(key, 1)
    this.totals--
  }

  /**
   * @description remove interaction
   * @param item
   */
  removeInteraction(item: Interaction) {
    this.deleteInteraction([item.name()])
    this._removeInteraction(item)
    this.$notice.success(this.$t('interaction-removed'))

    if (!this.totals) {
      this.isThereAtLeastOneInteractionInTotal = false
    }
    if (item.getDeepLearningQuestions().length && !this.isAlanEnabled) {
      Socket.send('qeafilter start-training', {
        botId: this.bot?.get('id'),
        token: 'delete-training',
      })
    }
  }

  /**
   * @description remove multiple interactions
   * @param item
   */
  async removeInteractions(item: Interaction[]) {
    const intsName = item.map((int) => int.name())
    await this.deleteInteraction(intsName)
    item.forEach((int) => {
      this._removeInteraction(int)
    })
    const deepLearningInteraction = item.find(
      (int) => !!int.getDeepLearningQuestions().length
    )
    if (deepLearningInteraction && !this.isAlanEnabled) {
      Socket.send('qeafilter start-training', {
        botId: this.bot?.get('id'),
        token: 'delete-training',
      })
    }
    this.$notice.success(this.$t('interaction-removed'))
  }

  selectAll() {
    this.toggleSelectionAll(true)
  }

  deselectAll() {
    this.toggleSelectionAll(false)
  }

  private toggleSelectionAll(value = false) {
    this.selectAllChecked = value
    const interactionsSelected = this.interactions.map((int) => {
      int.set('selected', value)
      return int
    })

    this.$set(this, 'interactions', interactionsSelected)
    this.refInteractions?.forEach((i) => i.$forceUpdate())
  }

  /**
   * @description cancel search
   */
  cancelSearch() {
    this.searchString = ''
    this.search()
    Analytics.event('Addestramento AI', 'Ricerca', 'Annulla')
  }

  private getVideoUrl() {
    switch (this.$i18n.locale) {
      case 'it':
        return 'd4qcKMVhHbU'

      default:
        return '-D2Po9qPPo0'
    }
  }

  /**
   * @description toggle ai search
   * @param ai_value
   */
  @Debounce(100)
  toggleAi(ai_value: AiType) {
    this.searchString = ''
    if (!hasPermission('AIEdit')) {
      return
    }
    if (ai_value === 1 && !hasPermission('AIToApprove')) {
      return
    }

    const alreadyClicked = this.searchAI.includes(ai_value)
    this.fromSearch = 0
    this.searchAI = [ai_value]
    this.deselectAll()

    if (!alreadyClicked) {
      this.loading = true
      this.interactions = []
      if (ai_value !== 1) {
        this.noMoreLoading = false
        this.search()
      }
    }

    if (ai_value === 3) {
      this.getWebpages()
      this.getDocuments()
      this.webPageInterval = setInterval(() => {
        if (this.isSearchAI(3)) {
          this.getWebpages()
        }
      }, 300000)
      this.$store.commit('documents/updateShowOutsideOfBox', false)
    } else {
      clearInterval(this.webPageInterval)
      this.$store.commit('documents/updateShowOutsideOfBox', true)
    }

    if (ai_value === 2) {
      Analytics.event('Addestramento AI', 'Click', 'Filtro - addestrate')
    } else {
      Analytics.event('Addestramento AI', 'Click', 'Filtro - da approvare')
    }
  }

  /**
   * @description check if AI is search
   * @param ai_value
   * @returns {*|number}
   */
  isSearchAI(ai_value: AiType) {
    return this.searchAI.includes(ai_value)
  }

  // MODALS
  /**
   *
   */
  showModalRemoveInteractions() {
    this.modalRemoveInteractionsSelected = true
    Analytics.event('Addestramento AI', 'Click', 'Bulk - Apri elimina')
  }

  /**
   * @description hide modal
   */
  hideModalNewInteraction() {
    return new Promise((resolve) => {
      this.modalNewInteraction = false
      this.newInteraction = null
      this.$nextTick(() => {
        this.newInteraction = this._getNewInteraction()
        resolve(true)
      })
    })
  }

  /**
   * @description new modal interaction
   */
  showModalNewInteraction() {
    if (this.disabled) {
      return
    }
    return new Promise((resolve) => {
      this.modalNewInteraction = true
      this.$nextTick(() => {
        setTimeout(() => {
          ;(this.refNewInteraction.$refs.name as any).focus()
          resolve(true)
        }, 800)
      })
    })
  }

  showModalMoveInteractions() {
    this.modalMoveInteractionsSelected = true
    Analytics.event('Addestramento AI', 'Click', 'Bulk - sposta in gruppo')
  }

  /**
   *
   */
  hideModalMoveInteractions() {
    this.modalMoveInteractionsSelected = false
    this.groupToMoveIn = null
  }

  /**
   * @description move to group and hide modal
   */
  async moveToGroup() {
    if (this.groupToMoveIn) {
      this.selectedInteractions.forEach((item) => {
        const groups: InteractionGroup[] = item.get('groups')

        if (!groups.find((g) => g.name === this.groupToMoveIn!.name)) {
          groups.push(this.groupToMoveIn!)
        }
      })

      const res = await this.service.addGroups({
        label: this.selectedInteractions.map((item) => item.name()),
        groups: [this.groupToMoveIn.name],
      })

      const data = res.data

      if (data.status === 200) {
        this.selectedInteractions.map((item) => item.set('selected', false))
        Analytics.event('Addestramento AI', 'Click', 'Bulk - sposta in gruppo')
        this.$notice.success(
          this.$t('interactions-moved-into', {
            name: this.groupToMoveIn.name,
          })
        )
      } else {
        this.$notice.alert(this.$t('error'))
      }
      this.hideModalMoveInteractions()
    }
  }

  /**
   * @description get a new Interaction
   * @returns {Interaction}
   * @private
   */
  _getNewInteraction() {
    const interaction = new Interaction({
      to_approve: false,
      records: [],
      answers: [],
      groups: [],
      buttons: [],
      expanded: true,
      label: '',
      key: this.counterKeyInteractions,
    })

    const type: any = hasPermission('AIDeepLearning')
      ? new DeepLearningType()
      : null
    interaction.addEmptyQuestion(type)

    this.counterKeyInteractions++

    return interaction
  }

  /**
   * @description get a new Id
   * @returns {*}
   * @private
   */
  _getNewId() {
    if (this.interactions.length) {
      const lastInteraction = this.interactions[0]
      if (lastInteraction) {
        const lastId = lastInteraction.id()
        return lastId + 1
      }
    }
    return 1
  }

  /**
   * @description add group
   * @param event
   */
  addGroup(event: InteractionGroup) {
    if (!this.existGroup(event)) {
      this.groups.push({
        id: this.groups.length + 1,
        name: event.name,
      })
    }
  }

  hasPermissions = hasPermissions
  hasPermission = hasPermission

  /**
   * @description check if group exist
   * @param group
   * @returns {*}
   * @private
   */
  private existGroup(group: InteractionGroup) {
    return this.groups.filter(
      (item) => item.name.toLowerCase() === group.name.toLowerCase()
    ).length
  }

  // EXPORT
  /**
   * @description export
   */
  exportDB() {
    if (this.exportCategory) {
      const uq = `export export ${this.exportFilter ? this.exportFilter : ''} ${
        this.exportCategory.value
      }`
      Socket.send(uq)
      this.exporting = 1
    }
  }

  /**
   * @description update
   */
  update() {
    this.$forceUpdate()
  }

  /**
   * @description on upgrade user permissions
   */
  onUpgrade() {
    this.disabled = false
    this.bindSocketCalls()
    this.refCounter++
  }

  /**
   * @description create new Interaction
   */
  createNewInteractionFromQuestion({
    interaction,
    question,
  }: { interaction?: Interaction; question?: any } = {}) {
    if (interaction) {
      this.newInteraction = interaction
    }

    if (question) {
      this.questionToApproveFromInteraction = question
    }

    this.modalNewInteraction = true
  }

  onClickSelectAll() {
    this.selectAll()
    Analytics.event('Addestramento AI', 'Click', 'Seleziona tutto')
  }

  onClickDeselectAll() {
    this.deselectAll()
    Analytics.event('Addestramento AI', 'Click', 'Deseleziona tutto')
  }

  onCancelBulkGroupMove() {
    Analytics.event(
      'Addestramento AI',
      'Click',
      'Bulk - annulla sposta in gruppo'
    )
  }

  onSubmitSearch() {
    if (this.hasPermission('AIEdit')) {
      this.search()
      if (this.searchString.length) {
        Analytics.event(
          'Addestramento AI',
          'Ricerca',
          `Enter - "${this.searchString}"`
        )
      }
    }
  }

  onClickNewInteraction() {
    this.modalNewInteraction = !this.modalNewInteraction
    Analytics.event('Addestramento AI', 'Click', 'Aggiungi nuovo')
  }

  /**
   * @description on filter groups checkbox click
   * @param groups
   */
  onGroupFilter(groups: InteractionGroup[]) {
    this.selectedGroups = groups
    Analytics.event('Addestramento AI', 'Click', 'Filtri - aggiunto Gruppo')
    this.search()
  }

  @Emit('loaded')
  loaded() {
    return true
  }

  /**
   * Get real index element list because of pagination
   * @param {number} key
   */
  getIndexInteractionElement(key: number) {
    if (key > this.fromSearch) {
      return key - this.fromSearch
    }
    return -1
  }

  canAddNewInteractions() {
    if (this.bot?.get('argument') === 'edd2c2067f1a5a62f47d4933ace20775') {
      // momentary block to Toccarelli's account
      return false
    } else if (this.interactionsAvailable === -1) {
      return true
    }
    return (
      this.subscription &&
      this.subscription.get('max_interactions') > this.totals
    )
  }

  onSubmitBulkRemove(interactions: Interaction[]) {
    this.removeInteractions(interactions)
  }

  onDeselectOneInteraction(interaction: Interaction) {
    const interactionDeselectedIndex = this.interactions.findIndex(
      (int) => int.id() === interaction.id()
    )
    if (interactionDeselectedIndex > -1) {
      this.interactions[interactionDeselectedIndex].set('selected', false)
      this.$set(this.interactions, interactionDeselectedIndex, interaction)
      this.refInteractions[interactionDeselectedIndex]?.$forceUpdate()
    }
  }

  async getInteractions(params: any) {
    try {
      const res = await this.service.getInteractions(params)

      const data = res.data.data

      this.scrolling = false
      let result = data.result

      result = result.map((item: any) => {
        item.key = `interaction-${this.counterKeyInteractions}`
        this.counterKeyInteractions++
        return new Interaction(item)
      })

      if (result.length) {
        this.isThereAtLeastOneInteractionInTotal = true
      }

      this.countSearch = data.countFiltered
      this.totals = data.count

      this.searching = false

      if (this.selectAllChecked) this.selectAll()

      if (!result.length || result.length < this.toSearch) {
        this.moreLoading = true
      } else {
        this.moreLoading = false
      }

      this.noMoreLoading = true

      setTimeout(() => {
        if (this.loading) {
          this.loaded()
          this.loading = false
        }

        if (this.fromSearch) {
          this.interactions = this.interactions.concat(result)
        } else {
          this.interactions = result
        }
      }, 300)
    } catch (e) {
      this.$notice.alert(this.$t('error-get-interactions'))
      this.loaded()
      this.noMoreLoading = true
      this.loading = false
      console.log(e)
    }
  }

  async saveInteraction(interaction: Interaction) {
    const res = await this.service.saveInteraction(interaction)
    const data = res.data
    eventBus.$emit('save-interaction', data)
    if (data.status === 200) {
      if (this.newInteractionAdding) {
        data.data.insertedRecord.key = `interaction-${this.counterKeyInteractions}`
        const int = new Interaction(data.data.insertedRecord)
        this.$notice.success(
          this.$t('interaction-added', {
            name: int.name(),
          })
        )
        this.interactions.unshift(int)
        this.hideModalNewInteraction()
        this.newInteractionAdding = false
        this.counterKeyInteractions++
      }

      if (this.questionToApproveFromInteraction) {
        this.refQuestions.hide(this.questionToApproveFromInteraction)
        this.questionToApproveFromInteraction = null
      }
      Interaction.clearLastInserted()
      this.getGroupsList()
    } else {
      this.$notice.alert(data.data.message)
    }
  }

  async getGroupsList() {
    try {
      const res = await this.service.getGroupsList()

      const data = res.attrs.data
      this.groups = data
        .sort((a: InteractionGroup, b: InteractionGroup) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        .map((item: any, index: number) => {
          item.selected = false
          item.id = index
          return item
        })
    } catch (e) {
      console.log(e)
    }
  }

  async deleteInteraction(labels: string[]) {
    try {
      const res = await this.service.deleteInteraction(labels)

      const data = res.data
      if (data.bulk) {
        this.selectedInteractions.map((item) => item.set('selected', false))
      }
      Interaction.clearLastInserted()
    } catch (e) {
      console.log(e)
    }
  }

  openImportTrainingModal() {
    this.modalImportTrainingStatus = true
  }

  importTraining(file: ArrayBuffer) {
    Socket.send('import-training', {
      file: Buffer.from(file),
    })

    this.modalImportTrainingStatus = false
    this.trainingFromImport = true
  }

  startCountdownFinishTraining() {
    const finishTraining = moment(new Date(this.lastImportTraining.timestamp))
      .add(5, 'm')
      .toDate()

    const TrainingInterval = setInterval(async () => {
      const now = new Date()
      if (finishTraining < now) {
        this.interactions = []
        await this.getInteractions({
          text: '',
          ai: this.searchAI,
          groups: this.selectedGroups.map((g) => g.name),
          limit_from: this.fromSearch,
          limit_to: this.toSearch,
        })
        this.trainingFromImport = false
        clearInterval(TrainingInterval)
      }
    }, 10000)
  }

  showPreviewChat(id: number) {
    this.chatId = id
    this.conversation = []
    this.userConversation = ''
    Socket.send(`robin sv 1 get ${id}`)
    Socket.send('session get-by-id', { id })
    this.previewChatModalStatus = true
  }

  async addDocument(data: any) {
    const momentId = uniqueId('file-')
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    this.$store.commit('documents/addDocument', {
      name: data.filename,
      size: data.size,
      id: momentId,
      uploading: true,
      removing: false,
      percentCompleted: 0,
      cancelToken: source,
    })

    try {
      const res = await AttachFileService.getUploadUrl({
        botId: this.bot?.get('id'),
        reference: data.md5,
        fileType: data.fileType,
      })
      const presignedUrl: string = res.attrs.url

      await axios
        .put(presignedUrl, data.file, {
          headers: {
            'Content-Type': data.file.type,
          },
          cancelToken: source.token,
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / e.total)
            this.$store.commit('documents/updatePercentual', {
              id: momentId,
              percentCompleted,
            })
          },
        })
        .catch((err: Error) => {
          if (axios.isCancel(err)) {
            throw new Error(ErrorType.STOP_BY_USER)
          } else {
            throw new Error(err.message)
          }
        })

      Socket.send('generative_ai store-doc', {
        docs: [
          {
            reference: data.md5,
            filename: data.filename,
            mimetype: data.mime,
            createdAt: Date.now(),
            token: momentId,
          },
        ],
      })
    } catch (e: Error | any) {
      if (e.message === ErrorType.STOP_BY_USER) {
        this.$notice.success(
          this.$t('stop-uploading-file', { filename: data.filename })
        )
      } else {
        this.$notice.alert(this.$t('errors.general'))
      }
      this.$store.commit('documents/removeDocument', momentId)
      console.log(e)
    }
  }

  async analizeImg(id: number) {
    const index = this.documents.findIndex((doc) => doc.id === id)
    try {
      this.documents[index].uploading = true
      this.documents[index].uploadingTxt = this.$t('analyzing-img').toString()
      const res = await this.genService.pdfConvert(id)
      const status = res.status
      if (!status || (status < 200 && status >= 300)) {
        throw new Error(`status error: ${status}`)
      }
    } catch (error) {
      this.documents[index].uploadingTxt = ''
      this.documents[index].uploading = false
      console.log(error)
      this.$notice.alert(this.$t('errors.general'))
    }
  }

  async viewFile(id: number, sharepointDocs = false) {
    const docsType = sharepointDocs ? this.sharepointDocuments : this.documents
    const index = docsType.findIndex((doc) => doc.id === id)
    try {
      docsType[index].uploading = true
      docsType[index].uploadingTxt = this.$t('getting-file').toString()
      const res = await this.genService.getBotDocUrl(id)
      const data = res.data()
      window.open(data.url, '_blank')
    } catch (error) {
      console.log(error)
      this.$notice.alert(this.$t('errors.general'))
    }
    docsType[index].uploadingTxt = ''
    docsType[index].uploading = false
  }

  async removeDocument(id: number) {
    const index = this.documents.findIndex((doc) => doc.id === id)
    this.documents[index].removing = true
    try {
      const res = await this.genService.deleteDocuments(id)
      const data = res.data()
      if (!data.error) {
        this.$notice.success(
          this.$t('doc-remove', { filename: this.documents[index].name })
        )
      } else {
        throw new Error(data.error.message)
      }
      this.documents = this.documents.filter((doc) => doc.id !== data.id)
    } catch (error) {
      this.documents[index].removing = false
      console.log(error)
      this.$notice.alert(this.$t('error-doc-remove'))
    }
  }

  async addUrl(dataUrl: UrlAsDoc) {
    const url = dataUrl.http.url
    const temporaryId = uniqueId('url-')
    this.url.unshift({
      name: url,
      link: {
        type: 'url',
        data: url,
      },
      id: temporaryId,
      uploading: true,
    })
    try {
      const res = await this.genService.addWebPages(dataUrl)
      const data = res.data()
      const itemJustAdded = this.url.findIndex((url) => url.name === data.url)
      this.url[itemJustAdded].id = data.id
      this.url[itemJustAdded].uploading = false
      this.url[itemJustAdded].updatedAt = data.created_at
      this.url[itemJustAdded].name = data.title
      this.url[itemJustAdded].link.data = data.url
      this.url[itemJustAdded].uploadedText = 'uploaded'
      this.url[itemJustAdded].url = data.url
      this.url[itemJustAdded].config = {
        http: data.http ?? { url: data.url, method: 'GET' },
        mapJson: data.mapJson ?? {},
      }
      this.resetHttpData()
      this.$notice.success(this.$t('url-added'))
    } catch (error) {
      this.url = this.url.filter((item) => item.id !== temporaryId)
      this.urlToEdit = url
      console.log(error)
      this.$notice.alert(this.$t('error-during-url-upload'))
    }
  }

  async removeUrl(id: number) {
    const index = this.url.findIndex((url) => url.id === id)
    this.url[index].removing = true
    try {
      const res = await this.genService.deleteWebPages(id)
      const data = res.data()
      console.log(data, this.url)
      this.url = this.url.filter((item) => item.id !== data.id)
      this.$notice.success(this.$t('url-remove'))
    } catch (error) {
      this.url[index].removing = false
      console.log(error)
      this.$notice.alert(this.$t('error-during-url-remove'))
    }
  }

  addTraining(type: 'qA' | 'documents' | 'websites') {
    if (type === 'qA') {
      if (!this.isSearchAI(2) || !this.isSearchAI(1)) {
        this.toggleAi(2)
      }
      this.modalNewInteraction = true
    } else {
      this.modalNewInteraction = false
      this.toggleAi(3)
    }
  }

  async getDocuments() {
    try {
      const res = await this.genService.getDocuments()
      const data: getDocType[] = res.data()
      const documents = data.map((doc) => {
        return {
          name: doc.filename,
          id: doc.id,
          uploading: this.IsImgAnalysisProcessing(doc),
          removing: false,
          updatedAt: doc.created_at,
          images: doc.imagesNo,
          imgAnalyzed:
            doc.status === DocTransformationStatus.completed &&
            (doc.statusType === DocTransformationType.mineru ||
              doc.statusType === DocTransformationType.mineru_post_processing),
          countdown:
            this.IsImgAnalysisProcessing(doc) && doc.eta && !isPastDate(doc.eta)
              ? doc.eta
              : null,
          uploadedText: 'uploaded',
          uploadingTxt: this.IsImgAnalysisProcessing(doc)
            ? doc.eta && !isPastDate(doc.eta)
              ? this.$t('analyzing-img-eta').toString()
              : this.$t('analyzing-img').toString()
            : '',
          errorMessage:
            doc.status === DocTransformationStatus.failed
              ? this.$t('analyzing-img-failed').toString()
              : '',
          link: {
            type: 'emit',
            data: doc.id,
          },
          sharepoint: doc.metadata?.some(meta => meta.key === "origin_label" && meta.value === 'Sharepoint'),
          path: this.getPath(doc.metadata),
        }
      })
      this.documents = documents.filter(doc => !doc.sharepoint)
      this.sharepointDocuments = documents.filter(doc => doc.sharepoint)
      this.documentsLoading = false
    } catch (error) {
      console.log(error)
    }
  }

  async getWebpages() {
    try {
      const res = await this.genService.getWebPages()
      const data: UrlPage[] = res.data()
      this.url = data.map((url: UrlPage) => {
        return {
          name: url.title || url.url,
          id: url.id,
          uploading: false,
          removing: false,
          updatedAt: url.updated_at || url.created_at,
          config: url.config,
          link: {
            type: 'url',
            data: url.url,
          },
          uploadedText: 'last-change',
          errorMessage:
            url.last_status === 'failure' ? this.$t('url-not-updated') : '',
        }
      })
      this.urlLoading = false
    } catch (error) {
      console.log(error)
    }
  }

  async updateSingleUrl(urlData: { id: number; config: UrlAsDoc }) {
    const index = this.url.findIndex((item) => item.id === urlData.id)
    try {
      this.url[index].uploading = true
      const res = await this.genService.refreshWebPage(urlData)
      const data: UrlPage[] = res.data()
      const urlUpdated = data[0]
      this.url[index].name = urlUpdated.title ?? urlUpdated.url
      this.url[index].updatedAt = urlUpdated.updated_at
      this.url[index].link.data = urlUpdated.url
      // this.url[index].uploadedText = 'uploaded'
      this.url[index].url = urlUpdated.url
      this.url[index].config = {
        http: urlUpdated.config,
        mapJson: urlUpdated.config?.mapJson ?? {},
      }
      this.$notice.success(this.$t('update-url', { url: urlUpdated.title }))
    } catch (error) {
      this.$notice.alert(this.$t('update-url-error'))
      this.url[index].errorMessage = this.$t('url-not-updated')
    }
    this.url[index].uploading = false
    this.resetHttpData()
  }

  onSaveGenerativeAI() {
    this.generativeAIConfigurationRequired = false
    this.generativeAiConfigurationStatus = false
  }

  async openGenerativeAiTab() {
    const res = await this.gptService.getConfig()
    const data = res.data()
    if (data) {
      this.isAlanEnabled = !!data.enabled
      if (data.enabled) {
        const res = await this.genService.getGeneralData()
        const data = res.data()
        if (!data) {
          this.generativeAIConfigurationRequired = true
          this.generativeAiConfigurationStatus = true
        }
      }
    }
  }

  IsImgAnalysisProcessing(data: getDocType) {
    if (
      (data.status === DocTransformationStatus.pending ||
        data.status === DocTransformationStatus.processing) &&
      (data.statusType === DocTransformationType.mineru ||
        data.statusType === DocTransformationType.mineru_post_processing)
    ) {
      return true
    }
    return false
  }

  getPath(metadata: {key: string, value: string}[] | undefined) {
    if (metadata) {
      const index = metadata.findIndex(item => item.key === 'sharepoint_collection_name')
      if (index >= 0) {
        const pathName = defautlCollections.includes(metadata[index].value)
          ? this.$t(`sharepoint.${metadata[index].value}`)
          : metadata[index].value
        return `${pathName} /`
      }
      return undefined
    }
    return undefined
  }

  resetHttpData() {
    this.httpData = {
      method: 'GET',
      url: '',
      params: {},
      headers: {},
      body: '',
      responseMatchMap: {},
    }
  }
}
