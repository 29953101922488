import { render, staticRenderFns } from "./u-dropdown.html?vue&type=template&id=e386d282&scoped=true&external"
import script from "./u-dropdown.ts?vue&type=script&lang=ts&external"
export * from "./u-dropdown.ts?vue&type=script&lang=ts&external"
import style0 from "./u-dropdown.scss?vue&type=style&index=0&id=e386d282&prod&scoped=true&lang=scss&external"
import style1 from "./body.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e386d282",
  null
  
)

export default component.exports