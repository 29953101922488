import { render, staticRenderFns } from "./u-badge-pro.vue?vue&type=template&id=0b5a2778&scoped=true"
import script from "./u-badge-pro.vue?vue&type=script&setup=true&lang=ts"
export * from "./u-badge-pro.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./u-badge-pro.scss?vue&type=style&index=0&id=0b5a2778&prod&scoped=true&lang=scss&external"
import style1 from "./u-badge-pro.vue?vue&type=style&index=1&id=0b5a2778&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5a2778",
  null
  
)

/* custom blocks */
import block0 from "./u-badge-pro.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports