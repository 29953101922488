// @ts-nocheck
import debounce from 'lodash/debounce'
import { Socket } from '@/assets/js/core/Socket'
import { BotAuthMixin, hasPermission, user } from '@/modules/auth'
import AvatarMenu from '@/components/u-navbar/avatar-menu/index.vue'
import UNavbarMenu from '@/components/u-navbar/u-navbar-menu/index.vue'
import UNavbarMenuElement from '@/components/u-navbar/u-navbar-menu-element/index.vue'
import UNavbarMenuCategory from '@/components/u-navbar/u-navbar-menu-category/index.vue'
import UNavbarBots from '@/components/u-navbar/u-navbar-bots/index.vue'
import Logo from '@/components/logo/index.vue'
import UOnboardingPanel from '@/components/u-onboarding/u-onboarding-panel/index.vue'
import { DispatchEvents } from '@/assets/js/enum/DispatchEvents'
import env from '@/env'

export default {
  name: 'u-navbar',
  inject: ['screenWidth', 'chatRates'],
  components: {
    AvatarMenu,
    UNavbarMenu,
    UNavbarMenuCategory,
    UNavbarMenuElement,
    UOnboardingPanel,
    UNavbarBots,
    Logo,
  },
  mixins: [BotAuthMixin],
  head() {
    return {
      bodyAttrs: {
        class: `${this.$store.state.navbar.status ? 'navbar--expanded' : ''} ${this.$store.state.webchat.isAside ? 'userbot___body-left' : ''}`,
      },
    }
  },
  props: {
    /**
     * show toggle arrow aside collapser
     */
    toggleAside: {
      default: true,
    },

    /**
     * @description
     */
    title: {
      required: false,
      default: '',
    },

    /**
     * @description
     */
    status: {
      default: false,
    },

    /**
     * @description
     */
    avatar: {
      default: true,
    },

    /**
     * route active
     */
    route: {
      type: String,
      required: false,
      default: '',
    },

    initials: {
      default: false,
    },

    teamleader: {
      default: false,
    },

    admin: {
      default: false,
    },
  },
  data() {
    return {
      folded: false,
      fullscreenStatus: false,
      userStatus: null,

      /**
       * loading Vue Test Chat status
       */
      loadingChatTest: false,

      /**
       * disable click on button
       */
      disableLoadChatTest: false,

      /**
       * dropdown status of advanced test ai menu
       */
      dropdownTestAiAdvanced: false,

      localRoute: '',
    }
  },
  computed: {
    command() {
      if (this.teamleader || this.admin) return 'tl'
      // if(this.admin) return 'sv';
      return 'op'
    },
    logo() {
      return this.$store?.state.authentication.profile?.logo || ''
    },
    bots() {
      return this.$store.state.bots.all || []
    },
    navbarStatus() {
      return this.$store.state.navbar.status
    },
    canShowNavbarBots() {
      return (
        this.$store.state.bots.all?.length > 0 &&
        this.route !== 'bots' &&
        this.route !== 'agents'
      )
    },
    isTrialBannerVisible() {
      return (
        this.$store.state.bots.all?.length === 1 &&
        this.planName !== 'ENTERPRISE'
      )
    },
    planName() {
      return this.chatRates()?.customer?.plan
    },
  },
  mounted() {
    addEventListener('click', this.closeNavbar)
    addEventListener('resize', this.onResize)

    this.userStatus = localStorage?.getItem('robin-status') === 'online'

    if (!this.userStatus && this.admin) {
      this.userStatus = true
      this.updateRobinStatus()
    }

    if (this.navbarStatus === null) {
      this.$store.dispatch('navbar/toggle', innerWidth >= 1365)
    }

    addEventListener(DispatchEvents.BOT_CHANGE, this.removeAside);
  },
  beforeDestroy() {
    removeEventListener('click', this.closeNavbar)
    removeEventListener('resize', this.onResize)
    removeEventListener(DispatchEvents.BOT_CHANGE, this.removeAside)
  },
  methods: {
    onLogout() {
      this.isLoggingOut = true
    },

    /**
     * toggle status
     */
    toggleStatus() {
      this.userStatus = !this.userStatus
      this.$emit('status', this.userStatus)
      localStorage.setItem('robin-status', this._getStatusString())
      this.updateRobinStatus()
    },

    /**
     * toggle menubar
     * @param {MouseEvent} event
     */
    toggleMenubar(event) {
      this.folded ? this.unfold() : this.fold()
      if (event) event.preventDefault()
    },

    /**
     * toggle fullscreen
     */
    toggleFullscreen() {
      this.fullscreenStatus = !this.fullscreenStatus
    },

    /**
     * fold left menu
     */
    fold() {
      this.folded = true
      this.$store.dispatch('navbar/toggle', true)
    },

    /**
     * unfold left menu
     */
    unfold() {
      this.folded = false
      this.$store.dispatch('navbar/toggle', false)
    },

    /**
     * update robin status
     * @param status
     */
    updateRobinStatus() {
      if (user()) {
        Socket.send(
          `robin ${this.command}`,
          `${user().get('id')} ${this._getStatusString()}`
        )
        Socket.send(`robin sv ${user().get('id')} open`)
      }
    },

    /**
     * toggle bot test
     */
    toggleBotTest(options = {}) {
      if (this.disableLoadChatTest || !this.bot) return
      this.loadingChatTest = true

      const self = this

      this.bot
        .test({
          ...options,
          pageId: this.route,
        })
        .then(() => {
          self.loadingChatTest = false
          self.disableLoadChatTest = true
        })

      this.bot.onDestroy(() => {
        setTimeout(() => {
          this.disableLoadChatTest = false
        }, 2000)
      })

      this.bot.onAsideAnchor(() => {
        this.$store.commit('webchat/setAside', true)
      })

      this.bot.onAsideDisanchor(() => {
        this.$store.commit('webchat/setAside', false)
      })
    },

    removeAside() {
      this.$store.commit('webchat/setAside', false)
      this.disableLoadChatTest = false
    },

    /**
     * get status string
     * @returns {string}
     * @private
     */
    _getStatusString() {
      return this.userStatus ? 'online' : 'away'
    },

    /**
     * update language
     * @param lang
     */
    updateLang(lang: string) {
      this.$i18n.locale = lang
    },

    /**
     * get link chat
     * @returns {string}
     */
    getLinkChat() {
      return this.localePath(
        `/bots/${this.bot.id()}/integrations/web-chat/preview/`
      )
    },

    showDropdownTestAi() {
      if (!this.disableLoadChatTest) {
        this.dropdownTestAiAdvanced = true
      }
    },

    /**
     * Check if is desktop
     * @returns {boolean}
     */
    isDesktop() {
      return this.screenWidth() >= 1366
    },

    toggleNavbarStatus() {
      this.$store.dispatch('navbar/toggle', !this.navbarStatus)
    },

    openSupportTypeform() {
      try {
        window.open(env.support.link, '_blank')
      } catch (error) {
        console.log(error)
      }
    },

    closeNavbar(e) {
      if (!this.isDesktop()) {
        if (!this.$el.contains(e.target)) {
          this.$store.dispatch('navbar/toggle', false)
        }
      }
    },

    onResize: debounce(function () {
      if (window.innerWidth <= 1365) {
        this.$store.dispatch('navbar/toggle', false)
      }
    }, 200),

    hasPermission: hasPermission,
  },
}
