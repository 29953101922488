import { CancelTokenSource } from 'axios'

export enum TypeNotification {
  info = 'Info',
  warn = 'Warn',
  error = 'Error',
}

export enum NotifyMessageKey {
  complete = 'pdf-transformation-job-complete',
  failed = 'pdf-transformation-job-failed',
}

export type NotifyCommand = {
  messageKey: NotifyMessageKey
  messageArgs: any
  type: TypeNotification
  durationMs?: number
  mode: 'Toast' | string
}

export enum DocTransformationType {
  mineru = 'mineru',
  textract = 'textract',
  mineru_post_processing = 'mineru_post_processing',
}

export enum DocTransformationStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
}

export type getDocType = {
  id: number
  status?: DocTransformationStatus
  statusType?: DocTransformationType
  created_at: string
  eta?: string
  statusUpdatedAt?: string
  imagesNo?: number
  filename: string
  metadata: Metadata[]
}

export type Document = {
  name: string
  id: number | string
  size?: string
  uploading?: boolean
  removing?: boolean
  percentCompleted?: number
  cancelToken?: CancelTokenSource
  imgAnalyzed?: boolean
  images?: number | null
  uploadingTxt?: string
  uploadedText?: string
  errorMessage?: string
  countdown?: string | null
  link?: {
    type: string
    data: number
  }
  path?: string
}

type Metadata = {
  key: string
  value: string
}