export class Loader {
  /**
   * @description register n of times appears
   * @type {number}
   */
  // private appearsCount: number = 0;

  /**
   * @description html template
   * @type {string}
   */
  // private html: string = '<div id="loader"><div class="loader-spinner"></div></div>';

  constructor() {
    this.appearsCount = 0
    this.html = '<div id="loader"><div class="loader-spinner"></div></div>'
  }

  /**
   * @description appear loading
   * @return void
   */
  appear() {
    if (!this.isOnScreen()) {
      $('body').append(this.html)
      this.el().animate({ opacity: 1 })
    }
    this.appearsCount++
  }

  /**
   * @description disappear loading
   * @return void
   */
  disappear() {
    this.appearsCount--
    if (this.appearsCount === 0) {
      this.el().fadeOut({
        complete: () => {
          this.el().remove()
        },
      })
    }
  }

  /**
   * @description check if is on screen
   * @return boolean
   */
  isOnScreen() {
    return !!this.el().length
  }

  /**
   * @description return jQuery HTML element
   * @return {JQuery}
   */
  el() {
    return $('#loader')
  }
}
