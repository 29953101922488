import { Vue, Component } from 'vue-property-decorator'
import { Socket } from '../core/Socket'
import OnboardingVar from '../models/Vars/OnboardingVar'
import { user } from '@/modules/auth'
import OnboardingConfig from '@/components/containers/onboarding-sales/interfaces/OnboardingConfig'

// TODO: add a cache system

/**
 * If user has not completed onboarding process and he doesn't skipped it
 * than redirect him to the Onboarding
 */

@Component
export default class OnboardingGuardMixin extends Vue {
  mounted() {
    Socket.on('domain', () => {
      if (user().isUsingSaleProduct()) {
        const onboardingVariable = new OnboardingVar()
        onboardingVariable.retrieve(
          (data: { optionName: string; optionValue: string }) => {
            if (data.optionValue) {
              try {
                const value: OnboardingConfig = JSON.parse(data.optionValue)

                // If not is page /sales/onboarding/ I check user has completed/skipped it
                if (!location.href.includes('onboarding')) {
                  if (!value.complete && !value.skipped) {
                    location.href = this.localePath('/sales/onboarding/step-1/')
                  }
                  // User is on a /sales/onboarding/ page
                } else if (value.complete || value.skipped) {
                  location.href = this.localePath('/bots/')
                }
              } catch (e) {
                console.trace(e)
              }
            }
          }
        )
      }
    })
  }
}
