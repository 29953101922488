import { render, staticRenderFns } from "./u-notice.html?vue&type=template&id=72d181bd&scoped=true&external"
import script from "./u-notice.js?vue&type=script&lang=js&external"
export * from "./u-notice.js?vue&type=script&lang=js&external"
import style0 from "./u-notice.scss?vue&type=style&index=0&id=72d181bd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d181bd",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports