import { DispatchEvents } from '../enum/DispatchEvents'
import dispatchNewEvent from '../helpers/dispatchNewEvent'

export default () => {
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;
  const event = DispatchEvents.URL_CHANGE

  history.pushState = function (...args) {
    const result = originalPushState.apply(this, args);
    dispatchNewEvent(event)
    return result;
  }

  history.replaceState = function (...args) {
    const result = originalReplaceState.apply(this, args);
    dispatchNewEvent(event)
    return result;
  }

  addEventListener('popstate', () => {
    dispatchNewEvent(event)
  })
}