/* eslint-disable camelcase */
import moment from 'moment-timezone'
import { sanitize, ucfirst } from '../utils'
import { extract_json, remove_json, remove_media_links } from '../core/helpers'
import AiScoreHint from '../interfaces/AiScoreHint'
import MessageButton from '../interfaces/MessageButton'
import BBCodeParser from '@/assets/js/core/BBCodeParser'
import { Model } from '@/assets/js/core/Model'
import getImageRegex from '@/assets/js/helpers/getImageRegex'
import getVideoRegex from '@/assets/js/helpers/getVideoRegex'
import getMediaUrl from '@/assets/js/helpers/getMediaUrl'

export class Message extends Model {
  private _textJsonFromInput: any
  private _forms: any
  private _buttons: MessageButton[]
  /**
   * Constructor
   * @param attrs
   */
  constructor(attrs: any) {
    // attrs.chat = '{\"type\":\"calendly\",\"data\":{\"url\":\"https://calendly.com/test-userb/30min\",\"text\":\"Prendi appuntamento con il tuo Pokemon del cuore\",\"buttonText\":\"Prenota\",\"evalTrueGoto\":\"597-operator19-1-0\",\"operatorUUID\":\"e638a5c2-388e-4dbf-9e21-2d65469c4486\",\"evalFalseGoto\":\"597-operator20-1-0\"}}'
    // attrs.chat = '{"type":"meeting","data":{"type":"schedule","status":"scheduled","meeting":{"firstname":"Marco Mantovani","lastname":"Mantovani","email":"asd@awda.it","startTime":"2022-07-05T09:00:00.000000Z","endTime":"2022-07-05T09:30:00.000000Z","userid":853,"name":"30 Minute Meeting"}}}'
    super(attrs)

    this._textJsonFromInput = null
    this._forms = null

    this._setup()
  }

  /**
   * setup attributes
   * @private
   */
  _setup() {
    if (this.get('username')) {
      const username = ucfirst(
        this.get('username')
          .replace(/(_[0-9]+)|_/g, ' ')
          .trim()
      )
      this.set('username', username)
    }
  }

  /**
   * get chat
   * @returns {any}
   */
  text() {
    try {
      return (this.get('chat') ? this.get('chat') : this.get('ctext'))
        .replace('”', '"')
        .trim()
    } catch (e) {
      return ''
    }
  }

  /**
   * get datetime creation conversation
   * @returns moment
   */
  datetime() {
    //  let date = this.get('quando_chat').replace('Z', '').replace('T', ' ');
    return moment(this.get('quando_chat'))
  }

  /**
   * check if two Conversation has same date
   * @param conversation
   * @returns {boolean}
   */
  isSameDateHas(conversation: this) {
    return (
      this.datetime().format('DD/MM/YYYY') ===
      conversation.datetime().format('DD/MM/YYYY')
    )
  }

  /**
   * get username name
   * @returns {*}
   */
  username() {
    return this.get('username')
  }

  /**
   * get date string
   * @returns string
   */
  dateString() {
    return this.datetime().format('DD/MM/YYYY')
  }

  /**
   * get time string format date creation
   * @returns string
   */
  timeString() {
    return this.datetime().format('HH:mm')
  }

  /**
   * check if is user or operator/bot
   * @returns {boolean}
   */
  isUser() {
    return this.get('hbo') === 'h' || this.get('hbo') === 'l'
  }

  /**
   * check if message is a direct of User and not an autoreply button
   * @returns {boolean}
   */
  isUserButNotAutoreply() {
    return this.get('hbo') === 'h'
  }

  /**
   * parse the text in chat
   * @returns {string | *}
   */
  html() {
    if (!this.text()) return ''

    // eslint-disable-next-line no-useless-escape
    const _regex = /\{(\"buttons\"\:)(\[((([^\n]*\n+)|)+.*)\])\}/g
    let testoMessaggio = this.text()
    if (!testoMessaggio.includes('<img')) {
      testoMessaggio = remove_media_links(testoMessaggio).trim()
    }
    if (testoMessaggio.match(_regex)) {
      testoMessaggio = this._cleanFromJsonObjects(testoMessaggio)
    }

    const sanitizedText = sanitize(remove_json(testoMessaggio))

    if (
      !sanitizedText.replace(/<\/?[^>]+(>|$)/g, '').length &&
      !sanitizedText.includes('<img')
    ) {
      return ''
    }

    return new BBCodeParser(sanitizedText).toHTML()
  }

  /**
   * get JSON buttons
   */
  buttons() {
    if (typeof this._buttons === 'undefined') {
      try {
        const matches = this.text().match(
          // eslint-disable-next-line no-useless-escape
          /\{(\"buttons\"\:)(\[((([^\n]*\n+)|)+.*)\])\}/g
        )
        if (matches) {
          const json = matches[0]
          const buttons = JSON.parse(json)
          this._buttons = buttons.buttons
          this.assignScoreToButtons()
        } else {
          this._buttons = []
        }
      } catch (e) {
        console.warn(e)
        this._buttons = []
      }
    }
    return this._buttons
  }

  /**
   * clean from json objects
   * @param string
   */
  _cleanFromJsonObjects(string: string) {
    return remove_json(string.toString()).trim()
  }

  /**
   * check if conversation has {"input": ... }
   * @returns {boolean}
   */
  hasInput() {
    return !!this.getJsonInText().input
  }

  /**
   * get only json from text
   * @returns {*}
   */
  getJsonInText() {
    if (this._textJsonFromInput === null) {
      this._textJsonFromInput = extract_json(
        this.get('chat')
          ? this.get('chat').replaceAll('”', '"')
          : this.get('ctext').replaceAll('”', '"')
      )
    }
    return this._textJsonFromInput
  }

  /**
   * set is trained
   * @returns {Message}
   */
  setTrained() {
    this.set('trained', true)
    return this
  }

  /**
   * check if is trained
   * @returns {boolean}
   */
  isTrained() {
    return !!this.get('trained')
  }

  /**
   * check if text is a media link
   * @private
   */
  isMedia() {
    const text = this.text()
    const imageMatches = text.match(getImageRegex()) || []
    const videoMatches = text.match(getVideoRegex()) || []
    const matches = imageMatches.concat(videoMatches)
    const { data } = this.getJSON() ?? {}
    return matches.length ? matches : data?.image ?? false
  }

  /**
   * is text
   * @returns {boolean}
   */
  isText() {
    return !this.isForm() && !this.isMedia() && !this.isJSON()
  }

  isBadge() {
    try {
      const { type, plugin } = this.getJSON()
      if (type === 'file' || type === 'qrcode') {
        return false
      }
      return type || plugin || this.isForm()
    } catch (e) {
      return false
    }
  }

  /**
   * is text
   * @returns {boolean}
   */
  isForm() {
    if (this._forms === null) {
      let _return = false
      try {
        _return = JSON.parse(this.get('chat')).forms
      } catch (e) {}
      this._forms = _return
      return _return
    }
    return !!this._forms
  }

  /**
   * is text
   * @returns {boolean}
   */
  isType(type: string) {
    try {
      return JSON.parse(this.get('chat').replaceAll('”', '"')).type === type
    } catch (e) {
      return false
    }
  }

  /**
   * Check if is JSON
   * @returns {boolean}
   */
  isJSON() {
    try {
      return Object.values(JSON.parse(this.get('chat').replaceAll('”', '"')))
        .length
    } catch (e) {
      return false
    }
  }

  /**
   * check if form is filled
   * @param form
   * @returns {boolean}
   */
  isFormFilled(form: any) {
    return form.fields.some((field: any) => field.value)
  }

  /**
   * get forms
   * @returns {Array}
   */
  getForms() {
    if (this.isForm()) {
      return this._forms
    }
    return false
  }

  /**
   * get condition
   * @returns
   */
  getData() {
    try {
      return JSON.parse(this.get('chat')).data
    } catch (e) {
      return null
    }
  }

  /**
   * is text
   * @returns {string}
   */
  getType() {
    try {
      return JSON.parse(this.get('chat')).type
    } catch (e) {
      return false
    }
  }

  /**
   * get all medias
   * @returns array
   */
  getMedia() {
    if (!this.isJSON()) {
      const medias = getMediaUrl(this.text())
      return medias ?? []
    } else {
      const { data } = this.getJSON() ?? {}
      return data?.image ? [data.image] : null
    }
  }

  /**
   * get JSON
   * @returns array
   */
  getJSON() {
    try {
      return JSON.parse(this.text().replaceAll('”', '"'))
    } catch (e) {
      return null
    }
  }

  /**
   * get forms
   * @returns {string}
   */
  getMediaUrl() {
    return this.get('chat')
  }

  /**
   * check if message is empty or not
   * @returns {boolean|*}
   */
  isEmpty() {
    if (this.get('chat').length) {
      if (this.isText()) {
        return !this.html().length
      }
      if (this.isMedia()) {
        return !this.getMedia()?.length
      }
      if (this.isJSON()) {
        return !Object.values(this.getJSON()).length
      }
      return false
    }

    return true
  }

  /**
   * check if message is by bot
   * @returns {boolean}
   */
  isBot() {
    return (
      this.get('hbo') === 'b' || this.isFallback() || this.get('hbo') === 'l'
    )
  }

  /**
   * check if message is by bot
   * @returns {boolean}
   */
  isGreeting() {
    return this.get('hbo') === 'g'
  }

  /**
   * check if message is by bot
   * @returns {boolean}
   */
  isFallback() {
    return this.get('hbo') === 'f'
  }

  /**
   * check if message is by bot
   * @returns {boolean}
   */
  isAgent() {
    return this.get('hbo') === 'o'
  }

  score() {
    if (this.get('ai_score')) {
      try {
        const data = JSON.parse(this.get('ai_score'))
        return data.score
      } catch (e) {
        return this.get('ai_score').score
      }
    }
    return 0
  }

  private assignScoreToButtons() {
    const aiScore = this.get('ai_score')
    if (aiScore?.hints) {
      this._buttons = this._buttons.map((button: MessageButton) => {
        const hint = aiScore.hints.find(
          (h: AiScoreHint) => h.qea_id === button.qea_id
        )
        if (hint) {
          button.score = hint.score
        }
        return button
      })
    }
  }
}
