import Vue from 'vue'

export const ClickOutside = {
  bind(el, binding, vnode) {
    vnode.context.onMouseClick = function (event) {
      if (!el.contains(event.target)) {
        binding.value()
      }
    }

    document.addEventListener('click', vnode.context.onMouseClick)
  },
  unbind(el, binding, vnode) {
    document.removeEventListener('click', vnode.context.onMouseClick)
  },
}

Vue.directive('clickOutside', ClickOutside)
