/* global HttpHeaders */
/* global $ */

import axios from 'axios'
import each from 'lodash/each'
import { Response } from './Http/Response'
import { Loader } from './Html/Loader'

export class Http {
  /**
   * @description redirect url on 401
   * @type {string}
   */
  // redirectUrl401 = '/login';

  /**
   * @description contain loader disappear function
   */

  // loaderTimeout: any;

  /**
   * @description http basic service
   */
  constructor() {
    this.service = axios
    this.redirectUrl401 = '/'
    this.loading = new Loader()
  }

  /**
   * @description post ajax method
   * @param {string} url
   * @param data
   * @param callback
   * @param failed
   */
  post(url, data, callback, failed) {
    this.request({
      url,
      data,
      method: 'POST',
      callback: (res) => {
        return callback.call(this, res)
      },
      failed: (res) => {
        if (failed) {
          return failed.call(this, res)
        }
      },
    })
  }

  /**
   * @description create generic request
   * @param options
   * @return {Observable<any>}
   */
  request(options) {
    /* let httpRequest = new HttpRequest(options.method, options.url, options.data, options.options);

        this.http.request(httpRequest)
          .subscribe(res => {
            return options.callback ? options.callback.call(this, res) : res;
          }, res => {
            return options.failed ? options.failed.call(this, res) : res;
          }); */

    $.ajax({
      url: options.url,
      method: options.method,
      data: options.data,
      dataType: 'json',
      // beforeSend: () => {
      // this.loading.appear();
      // },
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
    })
      .done((res) => {
        this.manageRedirects(res)
        const response = new Response(res)
        if (response.succeed()) {
          if (options.callback) options.callback.call(this, response)
        } else if (options.failed) options.failed.call(this, response)

        this.loaderDisappear()
      })
      .fail((res) => {
        const response = new Response(res)
        if (options.failed) options.failed.call(this, response)
      })
  }

  /**
   * @description send head call
   * @param url
   * @param callback
   * @param failed
   */
  head(url, callback, failed) {
    this.http
      .request('HEAD', url, {
        headers: this.getHeaders(),
      })
      .subscribe(callback, failed)
  }

  /**
   * @description return data string
   * @param json
   * @return {string}
   */
  prepareData(json) {
    const urlSearchParams = new URLSearchParams()
    each(json, (value, key) => {
      urlSearchParams.append(key, value)
    })

    return urlSearchParams.toString()
  }

  /**
   * @description return default headers
   * @return {HttpHeaders}
   */
  getHeaders() {
    let headers = new HttpHeaders()
    headers = headers.append(
      'Content-type',
      'application/x-www-form-urlencoded'
    )
    return headers
  }

  /**
   * @description loader disappear
   */
  loaderDisappear() {
    this.loaderTimeout = setTimeout(() => this.loading.disappear(), 1000)
  }

  /**
   * @description redirect to login if not loggedin
   * @param res
   */
  manageRedirects(res) {
    if (res.code === 401) {
      this.router.navigate([this.redirectUrl401])
    }
  }
}
