import { render, staticRenderFns } from "./u-documents-uploading.html?vue&type=template&id=7668db14&scoped=true&external"
import script from "./u-documents-uploading.ts?vue&type=script&lang=ts&external"
export * from "./u-documents-uploading.ts?vue&type=script&lang=ts&external"
import style0 from "./u-documents-uploading.scss?vue&type=style&index=0&id=7668db14&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7668db14",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports