import { localStorage } from 'nuxt-storage'

export default class ChatOption {
  name: string

  set(value: any) {
    localStorage?.setItem(this.name, value)
  }

  get() {
    return localStorage?.getItem(this.name)
  }
}
