import isUrl from '@/assets/js/helpers/isUrl'

export default {
  getMessage: (field: any, args: any) => {
    return 'The url is not a valid url'
  },
  validate: (value: string, args: any) => {
    // check if is added mailto: or something similar
    if (!value.match(/^[a-zA-Z]+:/)) {
      return isUrl(value)
    }
    return true
  },
}
