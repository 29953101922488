import cookie from 'js-cookie'

class _i18n {
  /**
   * Get navigator language
   * @return {string}
   */
  get() {
    if (process.server) {
      return 'it'
    }
    return navigator?.language || navigator?.userLanguage
  }

  /**
   * Get language
   * @return {string}
   */
  locale() {
    if (this.isCookieSet()) {
      return this.getCookie()
    }
    return this.get().split('-')[0]
  }

  /**
   * Check if cookie lang is set
   */
  isCookieSet() {
    return !!cookie.get('lang')
  }

  /**
   * Check if cookie lang is set
   */
  getCookie() {
    return cookie.get('lang')
  }
}

const i18n = new _i18n()

export default i18n
