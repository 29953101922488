
// @ts-nocheck
import { Vue } from 'nuxt-property-decorator'
import { defineAsyncComponent } from 'vue'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import BaseLayout from './base.vue'
import UNotice from '@/components/u-notice/index.vue'
import UConfetti from '@/components/u-confetti/index.vue'
import UFrillWidget from '@/components/u-frill-widget/index.vue'
import UNavbar from '@/components/u-navbar/index.vue'
import { Socket } from '@/assets/js/core/Socket'
import Browser from '@/assets/js/core/Helpers/Browser'
import { is_mobile } from '@/assets/js/core/helpers'
import { hasPermission, logout, UserAuthMixin } from '@/modules/auth'
import { PluginManager } from '@/assets/js/core/PluginManager'
import Router from '@/assets/js/core/Router'
import { Bot } from '@/assets/js/models/Bot'
import OnboardingGuardMixin from '@/assets/js/guards/OnboardingGuardMixin'
import Analytics from '@/assets/js/core/Analytics'

import { Bearer } from '@/assets/js/core/Http/Bearer'
import { User } from '@/assets/js/models/User'
import SocketBindings from '@/components/hocs/socket-bindings.vue'
import Gtm from '@/components/hocs/gtm.vue'
import RetrieveFile from '@/assets/js/mixins/retrieveFile'
import handleUrlChange from '@/assets/js/helpers/handleUrlChange'

type RobinStats = {
  notifications: number
}

type Notification = {
  robin_stats: RobinStats
  counter_nota: number
}

enum TypeNotification {
  info = 'Info',
  warn = 'Warn',
  error = 'Error',
}

function getNotificationValue(data: Notification) {
  if (data.robin_stats && data.robin_stats.notifications > 0) {
    return data.robin_stats.notifications
  } else if (data.counter_nota) {
    return data.counter_nota
  }
  return 0
}

let alertDisconnected = null

export default {
  components: {
    UNotice,
    BaseLayout,
    UConfetti,
    UFrillWidget,
    UNavbar,
    SocketBindings,
    Gtm,
    'u-modal-chat-limiting': (resolve) => {
      import(
        '@/components/modals/u-modal-chat-limiting/index.vue' /* webpackChunkName: 'components/u-modal-chat-limiting' */
      ).then(resolve)
    },
    'u-banner-chat-limiting': (resolve) => {
      import(
        '@/components/u-banner-chat-limiting/index.vue' /* webpackChunkName: 'components/u-banner-chat-limiting' */
      ).then(resolve)
    },
    'u-page-ops': defineAsyncComponent(() => {
      // eslint-disable-next-line no-new
      new Promise((resolve) => {
        import(
          '@/components/u-page-ops/index.vue' /* webpackChunkName: 'components/u-page-ops' */
        ).then(resolve)
      })
    }),
  },
  mixins: [OnboardingGuardMixin, UserAuthMixin, RetrieveFile],
  provide() {
    return {
      bot: () =>
        this.$store.state.page.bot ? new Bot(this.$store.state.page.bot) : null,
      notifications: () => this.globalChatNotifyNumber,
      plugins: () => this.plugins,
      isDesktop: () => this.desktop === true,
      isDesktopHD: () => this.desktopHD,
      screenWidth: () => this.screenWidth,
      isTabletLandscape: () => this.tabletLandscape === true,
      user: () => this.user,
      chatRates: () => this.chatRateLimits,
      notice: () => this.$notice,
      translate: (key, params = {}) => this.$t(key, params),
      translateP: (key, params = {}) => this.$tc(key, params),
      store: () => this.$store
    }
  },
  data() {
    return {
      /**
       * @description number of global chat unread
       */
      globalChatNotifyNumber: 0,

      /**
       * @description loaded
       */
      loaded: false,

      /**
       * @description plugins
       */
      plugins: null,

      /**
       * @description is desktop
       */
      desktop: process.server ? false : document.body.clientWidth >= 1440,

      desktopHD: process.server ? true : document.body.clientWidth >= 1366,

      tabletLandscape: process.server
        ? false
        : document.body.clientWidth >= 1024,

      screenWidth: process.server ? false : document.body.clientWidth,

      /**
       * @description chat rate limits
       */
      chatRateLimits: false,
    }
  },
  computed: {
    browser: {
      get() {
        return Browser
      },
    },
    billing() {
      return this.$store?.state.authentication.billing
    },
    subscription() {
      return this.$store?.state.authentication.subscription
    },
    user() {
      return this.$store?.state.authentication.user
    },
    frillKey() {
      return process.env.NUXT_ENV_FRILL_KEY
    },
    pageId() {
      return this.$route.name?.split('___')[0] ?? ''
    },
    loadedNewUrl() {
      return this.$store?.state.page.changingBot
        ? this.$route.params?.botKey === this.$store?.state.page.changingBot &&
            this.$store.state.page.newBotSet
        : true
    },
  },
  beforeMount() {
    Bearer.setToken(this.$auth.getToken('local'))
  },
  mounted() {
    // TODO: translate into a component
    addEventListener('resize', this.handlerWindowResize)

    handleUrlChange()

    Socket.on('key', (_, data) => {
      if (!data.search) {
        new Router().navigate('/bots')
      }
      const botData = data.search[0]
      this.plugins = Object.values(data.plugins[botData.id])
        .filter((item) => item.dashboard === 1)
        .map((item) => item.name)

      if (this.plugins.length) {
        PluginManager.setPlugins(this.plugins)
        PluginManager.registerComponents(Vue)
        const translations = require(`@/langs/${this.$i18n.locale}.json`)
        const allTranslations = PluginManager.mergeTranslations(translations)
        this.$i18n.setLocaleMessage(this.$i18n.locale, allTranslations)
      }

      this.setChatRatesLimits(data.chatLimitReport)
    })

    Socket.on('domain', (_, data) => {
      this.globalChatNotifyNumber = getNotificationValue(data)
      this.loaded = true
    })

    Socket.on('sitekey', (_, data) => {
      this.setChatRatesLimits(data.chatLimitReport)
      this.setImportTrainingStatus(data.trainingImportData)
      this.setWorkspaceLimit(data.workspaceData)
      Socket.send('domain')
    })

    Socket.on('who', async (_, message) => {
      if (
        !hasPermission('Customers') &&
        location.hostname === 'agency.userbot.ai'
      ) {
        location.href = 'https://my.userbot.ai'
        return
      }

      await this.$store?.dispatch(
        'authentication/setUser',
        new User(message.user.userData)
      )
      this.browser.askNotifyPermissions().catch(() => {})
      await Analytics.init(new User(this.user))
    })

    Socket.on('disconnect', () => {
      alertDisconnected = this.$notice.alert({
        content: this.$t('no-connection-found'),
        fixed: true,
      })
    })

    Socket.on('reconnect', () => {
      alertDisconnected?.dismiss()
      setTimeout(() => {
        this.$notice.success({
          content: this.$t('connection-established'),
        })
      }, 600)
    })

    Socket.on(
      ['chat-notification', 'robin-close-notify'],
      debounce((command, data) => {
        const value = getNotificationValue(data)
        if (
          command === 'robin-close-notify' ||
          (data.data.bot_argument === this.botId &&
            typeof value !== 'undefined')
        ) {
          // Force rerending in badge
          this.globalChatNotifyNumber = 0
          this.$nextTick(() => {
            this.globalChatNotifyNumber = value
          })
        }
      }, 600)
    )

    Socket.on('notify', (_, data) => {
      const notify = data.data
      if (notify.type === TypeNotification.error) {
        this.$notice.alert({
          content: this.$t(notify.messageKey, notify.messageArgs),
        })
      } else {
        this.$notice.success({
          content: this.$t(notify.messageKey, notify.messageArgs),
        })
      }
    })

    Socket.on('logout', logout)

    Socket.connect(this.$route.params.botKey ?? '')
  },
  methods: {
    /**
     * @description set chat rates limits
     * @param data
     */
    setChatRatesLimits(data) {
      this.chatRateLimits = data || {
        sessions: {
          limit: null,
          unique: 0,
        },
      }
    },

    handlerWindowResize: throttle(function () {
      this.desktop = document.body.clientWidth >= 1440
      this.desktopHD = document.body.clientWidth >= 1360
      this.tabletLandscape = document.body.clientWidth >= 1024
      this.screenWidth = document.body.clientWidth
    }, 300),

    /**
     * @description check if rate limiting was overridden
     * @return {boolean}
     */
    isRateLimitingOverride() {
      return (
        this.user &&
        (this.chatRateLimits?.alerts?.alert50 ||
          this.chatRateLimits?.alerts?.alert80 ||
          this.chatRateLimits?.alerts?.limitReached)
      )
    },

    /**
     * @description check if 100 chat limiting rate
     * @return {boolean}
     */
    isRateLimitingAt100() {
      return this.user && this.chatRateLimits?.alerts?.limitReached
    },

    setImportTrainingStatus(data) {
      const trainingImportData = data?.data
      if (trainingImportData) {
        this.$store?.commit(
          'importTraining/updateFlag',
          trainingImportData.importFlag
        )
        this.$store?.commit(
          'importTraining/updateTimestamp',
          trainingImportData.importTimestamp
        )
      }
    },

    setWorkspaceLimit(data) {
      const workspaceData = data?.data
      if (workspaceData) {
        this.$store?.commit('bots/updateLimit', workspaceData.workspaceLimit)
        this.$store?.commit(
          'bots/updateCounter',
          workspaceData.workspaceCounter
        )
      }
    },

    /**
     * @description check if user agent is mobile
     */
    isMobile() {
      return is_mobile()
    },

    hasPermission,
  },
}
