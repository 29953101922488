import { BaseService } from './BaseService'

type UserProfile = {
  id: string
  ownerMail: string
  name: string
  surname: string
  coBrandingLogo: string
}

class _ProfileService extends BaseService {
  async load(customerId: string): Promise<UserProfile> {
    const res = await this.http
      .mock(`customers/1/profile`)
      .get(`customers/${customerId}/profile`)
    return res.data()
  }
}

const ProfileService = new _ProfileService()

export default ProfileService
