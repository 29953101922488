import { Model } from '@/assets/js/core/Model'

export class BaseType extends Model {
  /**
   * @description check if is text
   * @return {boolean}
   */
  isText() {
    return this.get('type') === 'STRICT' || this.get('type') === 'LIKE'
  }

  /**
   * @description check if is empty
   * @return {*}
   */
  isEmpty() {
    if (this.get('question_nl')) {
      return this.get('question_nl').trim().length === 0
    }
    return true
  }
}
