import axios from 'axios'
import { Api } from '../core/Api'
import { BaseService } from './BaseService'
import { apiUrl } from '@/modules/api'
import { Bearer } from '@/assets/js/core/Http/Bearer'

export default class GptService extends BaseService {
  constructor(private readonly botId: string) {
    super()
  }

  getConfig() {
    return Api.mock().get(`bot/${this.botId}/gpt/get-config`)
  }

  getSummarize(sessionId: string) {
    return Api.post(`bot/${this.botId}/gpt/summarize`, { sessionId })
  }

  getChatScore(sessionId: string) {
    return Api.post(`bot/${this.botId}/gpt/chat-score`, { sessionId })
  }

  getModels() {
    return axios({
      url: apiUrl(`/v1/ai/models`),
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Bearer.getToken()}`,
      },
    })
  }
}
