import { BaseType } from './BaseType'

export default class DeepLearningType extends BaseType {
  constructor() {
    super({
      label: 'deeplearning-type',
      description: 'deeplearning-description',
      icon: 'brain',
      type: 'LIKE',
    })
  }
}
