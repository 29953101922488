const PermissionsAdapter = {
  Bot: 'BOT__VISUALIZZA',
  BotEdit: 'BOT__RINOMINA',
  BotDisable: 'BOT__SOSPENDI',
  BotAdd: 'BOT__AGGIUNGI',
  BotHomeStats: 'BOT__HOME_STATS',
  BotOptionsEdit: 'OPZIONI_BOT__MODIFICA',
  BotOptionsView: 'OPZIONI_BOT__VISUALIZZA',

  // Addestramento
  AI: 'ADDESTRAMENTO__VISUALIZZA',
  AIInChat: 'ADDESTRAMENTO__IN_CHAT',
  AIEdit: 'ADDESTRAMENTO__MODIFICA',
  AIToApprove: 'ADDESTRAMENTO__DA_APPROVARE',
  AIDeepLearning: 'ADDESTRAMENTO__DEEP_LEARNING',
  AILimited: 'ADDESTRAMENTO__INTERAZIONI_LIMITATE',
  AIDownload: 'ADDESTRAMENTO__DOWNLOAD',
  AITrainingDocsExtractImages: 'ADDESTRAMENTO__AI_EXTRACT_IMAGES',

  // Flussi
  Flows: 'FLUSSI__VISUALIZZA',
  FlowsUnlimited: 'FLUSSI__AGGIUNGI_ILLIMITATI',
  FlowsOperatorCondition: 'FLUSSI__CONDIZIONI',
  FlowsOperatorEmailNotification: 'FLUSSI__NOTIFICA_EMAIL_LOGO',

  // Audience
  Audience: 'AUDIENCE__ENABLED',
  AudienceSegmentation: 'AUDIENCE__SEGMENTATION',

  Kpi: 'METRICHE__VISUALIZZA',
  KpiCustom: 'METRICHE__ESTRAZIONI_PERSONALIZZATE',
  KpiSales: 'METRICHE__SALES',
  Chats: 'CHAT__VISUALIZZA',
  Buy: 'LICENZA__UPGRADE',
  Scheduler: 'PIANIFICAZIONE_ORARI__VISUALIZZA',
  SchedulerEdit: 'PIANIFICAZIONE_ORARI__MODIFICA',
  ChatHistoryUnlimited: 'CHAT__VISUALIZZA_STORICO',
  PlanVisible: 'ABBONAMENTO__VISUALIZZA',
  TopIntent: 'METRICHE__TOP_INTENT',
  TopGroups: 'METRICHE__TOP_GROUPS',

  WebChatView: 'WEBCHAT__PERSONALIZZAZIONI_VISUALIZZA',
  WebChatEdit: 'WEBCHAT__PERSONALIZZAZIONI_SALVA',
  WebChatBrandRemove: 'WEBCHAT__RIMOZIONE_BRAND',

  // Payments
  PaymentMethodView: 'METODI_DI_PAGAMENTO__VISUALIZZA',

  // Invoices
  InvoicesView: 'FATTURE__VISUALIZZA',

  // Billing
  Billing: 'DATI_FATTURAZIONE__VISUALIZZA',

  // Agency
  Agency: 'LICENZA__UPGRADE',
  Customers: 'CLIENTI__VISUALIZZA',
  CustomersEdit: 'CLIENTI__MODIFICA',
  CustomersRemove: 'CLIENTI__RIMUOVI',
  CustomersAdd: 'CLIENTI__AGGIUNGI',

  // Agenti
  AgentsUpgrade: 'AGENTI__UPGRADE',
  Agents: 'AGENTI__VISUALIZZA',
  AgentsUnlimited: 'AGENTI__AGGIUNGI',
  AgentsEdit: 'AGENTI__MODIFICA',
  AgentsDelete: 'AGENTI__RIMUOVI',

  // All users
  Profile: 'PROFILO__VISUALIZZA',

  // Integrations
  Integrations: 'INTEGRAZIONI__VISUALIZZA',
  IntegrationTwitter: 'INTEGRAZIONI__TWITTER',
  IntegrationSlack: 'INTEGRAZIONI__SLACK',
  IntegrationMessenger: 'INTEGRAZIONI__FACEBOOK_MESSENGER',
  IntegrationWhatsApp: 'INTEGRAZIONI__WHATSAPP',
  IntegrationMultilanguage: 'INTEGRAZIONI__MULTILINGUA',
  IntegrationAlan: 'INTEGRAZIONI__ALAN',
  IntegrationTextToSpeech: 'INTEGRAZIONI__TEXTTOSPEECH',
  IntegrationSpeechToText: 'INTEGRAZIONI__SPEECHTOTEXT',
  IntegrationCalendly: 'INTEGRAZIONI__VISUALIZZA',
  IntegrationMail: 'INTEGRAZIONI__MAIL',

  // Theme
  LogoEdit: 'LOGO_CO-BRANDING__MODIFICA',
  Logo: 'LOGO_CO-BRANDING__VISUALIZZA',

  // IP Reversing
  Intel: 'IP_REVERSING__ENABLED',

  // Chat
  Attachments: 'ATTACHMENTS__ENABLED',
  ChatRetrieve: 'CHAT__RETRIEVER',

  Vision: 'AI_VISION__ENABLED',
  
  //GENERATIVE_AI
  AllModels: 'GENERATIVE_AI__ALL_MODELS',
}

export type Permission = keyof typeof PermissionsAdapter

export default PermissionsAdapter
