import { user as userFunction } from './user'
import {
  hasPermission as hasPermissionFn,
  hasPermissions as hasPermissionsFn,
  logout as logoutFn,
  login as loginFn,
} from './auth'

export { default as permissions } from './permissions'
export type { Permission } from './permissions'
export * from './mixins'

export const logout = logoutFn
export const login = loginFn
export const user = userFunction
export const hasPermission = hasPermissionFn
export const hasPermissions = hasPermissionsFn

export const Auth = {
  user,
  login,
  hasPermission,
  hasPermissions,
}
