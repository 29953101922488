import { eventBus } from '@/modules/events/bus'

const PAGINATION_OFFSET = 15

const defaultSearchToApproveSettings = {
  recommended: true,
  toBeVerified: true,
  offset: 0,
  limit: PAGINATION_OFFSET,
  search: '',
}

export default {
  state: () => ({
    search: {
      toApprove: null,
    },
    counters: {
      all: 0,
      recommended: 0,
      toBeVerified: 0,
    },
    toApprove: {
      loading: false,
    },
  }),
  mutations: {
    setToApproveSearchSettings(state: any, settings: any) {
      state.search.toApprove = Object.assign(
        {},
        defaultSearchToApproveSettings,
        state.search.toApprove,
        settings
      )
    },
    approveLoading(state: any, value: boolean) {
      state.toApprove.loading = value
    },
  },
  actions: {
    searchToApprove({ commit, state }: any, search: string) {
      commit('approveLoading', true)
      state.search.toApprove.offset = 0
      state.search.toApprove.limit = PAGINATION_OFFSET
      commit('setToApproveSearchSettings', { search })
      eventBus.$emit('search-interaction-to-improve')
      // const service = new TrainingService('09')
      // InteractionToApproveService.listToApprove(state.search.toApprove)
      // InteractionToApproveService.counters(state.search.toApprove.search)
    },
  },
}
