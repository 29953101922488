import { Vue, Component, Watch, Prop } from 'nuxt-property-decorator'

@Component
export default class PageLoader extends Vue {
  @Prop({ type: Boolean, required: true })
  modelValue: boolean

  @Prop({ type: Boolean, required: false, default: false })
  autohide: boolean

  @Prop({ type: Boolean, required: false, default: false })
  primary: boolean

  @Watch('modelValue', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.loaded = val
    }
  }

  loaded: boolean = false

  mounted() {
    document.body.addEventListener('click', (e) => {
      const target = this.getTarget(e)
      if (target?.classList.contains('animsition-link')) {
        e.preventDefault()
        document.body.classList.add('animsition--out')
        this.loaded = false
        setTimeout(() => {
          location.href = target.href
        }, 1000)
      }
    })

    if (this.autohide) {
      setTimeout(() => {
        this.loaded = true
      }, 1500)
    }
  }

  getTarget(e: MouseEvent): HTMLAnchorElement | null {
    e = e || window.event
    const target = e.target ?? e.srcElement
    return target as HTMLAnchorElement
  }
}
