type State = {
  isAside: boolean
}

export default {
  state: () => ({
    isAside: false
  }),
  mutations: {
    setAside(state: State, value: boolean) {
      state.isAside = value
    },
  },
}
