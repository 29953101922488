import PricePlan from '@/assets/js/interfaces/PricePlan'

export default {
  id: 'ltd',
  name: 'LTD',
  disabled: false,
  stripe_key: {
    month: 'products.lifetime.LTD',
  },
  price: {
    monthly: 0,
  },
} as PricePlan
