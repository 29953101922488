import moment from 'moment-timezone'
import random from 'lodash/random'
import Variable from '../interfaces/Variable'
import { Model } from '@/assets/js/core/Model'
import { SubscriptionService } from '@/assets/js/services/User/SubscriptionService'
import { PaymentMethodService } from '@/assets/js/services/User/PaymentMethodService'
import { Subscription } from '@/assets/js/models/Users/Subscription'
import { PaymentMethod } from '@/assets/js/models/Users/PaymentMethod'
import { UserService } from '@/assets/js/services/User/UserService'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import { Response } from '@/assets/js/core/Http/Response'
import InvoiceService from '@/assets/js/services/User/InvoiceService'
import Invoice from '@/assets/js/models/Users/Invoice'

const PROMISES: any = {
  subscription: null,
  billing: null,
  paymentMethods: null,
}

export class User extends Model {
  private _image: any

  /**
   * @description used to save async data
   * @type JSON
   */
  data: any = {
    paymentMethods: null,
    invoices: null,
  }

  constructor(attributes: any) {
    super(attributes)

    if (!attributes?.variables) {
      this.attributes.variables = []
    }
  }

  /**
   * @description get completename
   * @return string
   */
  completeName() {
    let name = this.get('user')

    // if has firstname and lastname
    if (this.moreData() && this.moreData()?.first_name) {
      name = `${this.moreData()?.first_name} ${this.moreData().last_name}`
    } else if (
      this.get('nome') &&
      this.get('cognome') &&
      this.get('nome') !== 'John' &&
      this.get('cognome') !== 'Doe'
    ) {
      // if has nome e cognome
      name = `${this.get('nome')} ${this.get('cognome')}`
    }

    if (name) return name.replace(/_/g, ' ')
    return `${this.lastname()} ${this.firstname()}`.trim()
  }

  /**
   * @description get more user Data
   * @returns {null}
   */
  moreData(): any {
    let moreData = {
      additionalInfo: {
        user: '',
      },
      last_name: '',
      first_name: '',
      userPhone: false,
      userEmail: '',
      profile_pic: '',
    }
    if (this.get('json_trace')) {
      let jsontrace = this.get('json_trace')

      // rimuovo sniffed perché da problemi nel parsing del json
      if (jsontrace.includes(',"sniffed":')) {
        jsontrace = jsontrace.substr(0, jsontrace.indexOf(',"sniffed":'))
        jsontrace = jsontrace + '}'
      }

      jsontrace = unescape(jsontrace)
      jsontrace = jsontrace.replace(/\s/g, '')
      jsontrace = jsontrace.replace(/\\/g, '')

      moreData = jsontrace ? JSON.parse(jsontrace) : {}

      if (!moreData!.additionalInfo) {
        moreData.additionalInfo = { user: '' }
      }
    }
    return moreData
  }

  /** +
   * @description get customer id
   * @return {any}
   */
  customerId() {
    return this.get('customer_id') || this.get('customers_id')
  }

  /**
   * @description get user id
   * @returns {*}
   */
  id() {
    return this.get('id') > -1 ? this.get('id') : false
  }

  /**
   * @description get name
   * @return {string}
   */
  name() {
    return this.completeName()
  }

  /**
   * @description get firstname
   * @return string
   */
  firstname() {
    let firstname = this.get('first_name')
      ? this.get('first_name').trim()
      : false
    if (!firstname)
      firstname = this.get('nome') !== 'John' ? this.get('nome') : false
    if (!firstname) firstname = this.get('name') ? this.get('name') : ''
    if (!firstname) firstname = this.get('userName')
    if (!firstname) firstname = this.get('user')
    if (!firstname && this.get('nickname'))
      firstname = this.get('nickname').replace('_', ' ')
    if (firstname) {
      const email = this.email().split('@')
      if (email[0] === firstname) {
        firstname = ''
      }
    }
    return firstname || ''
  }

  setFirstName(value: string) {
    this.set('first_name', value)
    this.set('nome', value)
    this.set('user', value)
  }

  /**
   * @description get lastname
   * @return any
   */
  lastname() {
    let cognome = this.get('cognome') === 'Doe' ? false : this.get('cognome')
    if (!cognome) cognome = this.get('last_name')
    if (!cognome) cognome = this.get('surname')
    if (cognome) {
      const email = this.email().split('@')
      if (email[1] === cognome) {
        cognome = ''
      }
    }
    return cognome || ''
  }

  setLastName(value: string) {
    this.set('last_name', value)
    this.set('cognome', value)
  }

  setCompany(value: string) {
    this.set('company', value)
  }

  /**
   * @description get phone
   * @return any
   */
  phone() {
    return this.get('phone') && this.get('phone') !== 'undefined'
      ? this.get('phone')
      : this.moreData().userPhone
  }

  /**
   * @description get email
   * @return any
   */
  email() {
    const email = this.get('email')
    if (email && email.trim()) {
      if (email.charAt(0) === '_') {
        return email.substr(1)
      }
      return email
    }
    return this.moreData().userEmail ?? ''
  }

  /**
   * @description get profile pic
   * @returns {*}
   */
  image() {
    if (!this._image) {
      let prefix = '/'
      let pic = ''

      if (this.moreData().profile_pic) {
        pic = this.moreData().profile_pic
        if (pic.substring(0, 4).trim() === 'http') prefix = ''
        else pic = pic.replace('assets', 'images')
      }

      if (pic) {
        return `${prefix}${pic}`
      }
      const number = random(0, 6)
      pic = `images/avatars/user${number}.png`

      this._image = pic
    }
    return this._image
  }

  /**
   * @description get from url
   * @param type
   * @returns {*}
   */
  fromUrl(type: string) {
    if (!this.moreData().urlweb) return false

    const url = this.moreData().urlweb
    if (type === 'href') {
      // eslint-disable-next-line no-useless-escape
      return url.replace(/^[a-z]{4}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1')
    }
    if (type === 'last') {
      const splits = url.split('/')
      return splits[splits.length - 1]
    }
    return url
  }

  /**
   * @description check source come
   * @param string
   */
  comeFrom(string: string) {
    return this.get('source') === string
  }

  /**
   * @description get user status
   */
  infoStatus() {
    return this.moreData() ? this.moreData().additionalInfo.user : ''
  }

  /**
   * @description get user age
   * @returns {any}
   */
  age() {
    return this.get('age')
      ? this.get('age')
      : this.moreData()
      ? this.moreData().additionalInfo.age
      : ''
  }

  /**
   * @description get note user
   * @returns {any}
   */
  note() {
    return this.get('notes')
  }

  /**
   * @description set attributes of user
   * @param name
   * @param value
   * @returns {User}
   */
  set(name: string, value: any) {
    switch (name) {
      case 'email':
        if (this.get('email')) {
          this.set('email', value)
        } else this.moreData().userEmail = value
        break

      case 'firstname':
        this.set('first_name', value)
        break
      case 'lastname':
        this.set('last_name', value)
        break

      case 'phone':
        if (this.get('phone')) {
          this.set('phone', value)
        } else this.moreData().userPhone = value
        break

      default:
        super.set(name, value)
    }
    return this
  }

  getAnalyticsData() {
    const data = {
      email: this.get('email'),
      firstname: this.firstname(),
      lastname: this.lastname(),
      customerId: this.customerId(),
      created_at: this.get('created_user'),
      plan: this.get('plan'),
      groups: this.get('groups'),
    }

    if (Bearer.data) {
      data.plan = Bearer.data.userplan
      data.groups = Bearer.data.usergroups
    }

    return data
  }

  /**
   * @description days created from
   * @returns {string | *}
   */
  createdFromNow() {
    const date = this.get('created_user').replace('Z', '').replace('T', ' ')
    return moment(date).fromNow()
  }

  /**
   * @description get last url viewed date
   */
  dateLastUrlViewed() {
    const date = this.get('quando').replace('Z', '').replace('T', ' ')
    return moment(date).from(moment())
  }

  /**
   * @description get more data
   * @return {User._moreData.additionalInfo|{user}|*}
   */
  additionalData() {
    return this.moreData().additionalInfo
  }

  hasAdditionalData() {
    return Object.keys(this.additionalData()).length > 0
  }

  /**
   * @description get subscription
   * @return {Promise<void>}
   */
  async subscription() {
    if (PROMISES.subscription === null) {
      PROMISES.subscription = new SubscriptionService().get()
    }
    const billing = await this.billing()
    const data: any = await PROMISES.subscription

    if (!(data instanceof Subscription)) {
      PROMISES.subscription = new Subscription(
        data.attrs.subscriptionPlan[0],
        billing
      )
    }
    return PROMISES.subscription
  }

  /**
   * @description get subscription
   * @return {Promise<void>}
   */
  async paymentMethods() {
    if (!PROMISES.paymentMethods) {
      PROMISES.paymentMethods = this._promisePaymentMethods()
    }
    return PROMISES.paymentMethods
  }

  async _promisePaymentMethods() {
    if (!this.data.paymentMethods) {
      const res = await new PaymentMethodService().get()
      if (res.status === 200) {
        this.data.paymentMethods = res
          .data()
          .paymentMethods.map((item: any) => new PaymentMethod(item))
      } else {
        this.data.paymentMethods = []
      }
    }

    return this.data.paymentMethods
  }

  /**
   * @description get user invoices
   * @return {Promise<void>}
   */
  async invoices() {
    if (!this.data.invoices) {
      const res = await new InvoiceService().get()
      if (res.succeed()) {
        this.data.invoices = res
          .data()
          .invoices.map((invoice: any) => new Invoice(invoice))
      } else {
        this.data.invoices = []
      }
    }
    return this.data.invoices
  }

  /**
   * @description load new relation
   * @param name
   */
  async load(name: string) {
    if (PROMISES[name]) {
      PROMISES[name] = null
    } else {
      this.data[name] = undefined
    }
    return await (this as any)[name]()
  }

  /**
   * @description get subscription
   * @param data {JSON}
   * @return {Promise<any>}
   */
  async addPaymentMethod(data: any) {
    const subscription = await this.subscription()
    return new PaymentMethodService().create(subscription.get('id'), data)
  }

  /**
   * @description save billing data into user
   * @param data
   */
  saveBillingData(data: any) {
    return new UserService().saveBillingData(data)
  }

  /**
   * @description get billing info
   * @return {Promise<void>}
   */
  async billing() {
    if (PROMISES.billing === null) {
      PROMISES.billing = new UserService().getBillingData()
    }

    const data = await PROMISES.billing

    if (data instanceof Response) {
      PROMISES.billing = data.attrs.data
    }
    return PROMISES.billing
  }

  isAnonymous() {
    return !this.get('nome') && !this.get('cognome')
  }

  hasAgency() {
    return this.get('group_id')
  }

  variables(): Variable[] {
    return this.get('variables') || []
  }

  notSystemVariables() {
    return this.variables().filter((v: Variable) => {
      return !v.name?.match(/^user_/)
    })
  }

  isUsingSaleProduct() {
    return Bearer.data?.userplan?.includes('SALES_') ?? false
  }
}
