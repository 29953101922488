import { render, staticRenderFns } from "./avatar-menu.html?vue&type=template&id=1bd1d216&scoped=true&external"
import script from "./avatar-menu.ts?vue&type=script&lang=ts&external"
export * from "./avatar-menu.ts?vue&type=script&lang=ts&external"
import style0 from "./avatar-menu.scss?vue&type=style&index=0&id=1bd1d216&prod&scoped=true&lang=scss&external"
import style1 from "./dropdown.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd1d216",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports