import moment from 'moment'
import { Model } from '@/assets/js/core/Model'

export class Plan extends Model {
  /**
   * @description get name plan
   * @return {*}
   */
  name() {
    return this.get('name')
  }

  /**
   * @description price
   * @deprecated
   * @return {*}
   */
  price() {
    return this.amount()
  }

  /**
   * @description calc amount plan
   * @return {null|*|number}
   */
  amount() {
    if (this.get('price')) {
      return this.get('price').monthly
    }
    return 0
  }

  /**
   * @description get price string
   * @return {*}
   */
  priceString() {
    return `${this.price()}€`
  }

  /**
   * @description get stripe product id
   * @return {*}
   */
  stripeKey() {
    if (this.get('stripe_key')) {
      return this.get('stripe_key').month
    }
    return ''
  }

  /**
   * @description tim eto start
   * @return {string}
   */
  executionDate(format = 'DD/MM/YYYY') {
    const date = moment.unix(this.get('executionDate'))
    if (!format) return date
    return date.format(format)
  }
}
