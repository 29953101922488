import getImageRegex from '../helpers/getImageRegex'

export default class BBCodeParser {
  text: string = ''
  private _searches: RegExp[]
  private _replaces: string[]

  /**
   * @description constructor
   * @param string
   */
  constructor(string: string) {
    this.text = string

    this._searches = [
      // images
      /\[img\](.*?)\[\/img\]/gi,
    ]

    this._replaces = ['<img src="$1">']
  }

  /**
   * @description transform bbcode to html
   * @return {*}
   */
  toHTML() {
    let string = this.text

    this._searches.forEach((s, key) => {
      string = string.replace(s, this._replaces[key])
    })

    string = string.replace(/\\n/g, '<br>')
    string = string.replace(/\\'/g, "'")
    string = string.replace(/”/g, '"')

    string = this.transformURLtoMediaImage(string)

    if (!string.includes('<img') && !string.includes('<a')) {
      string = this.transformURLToATags(string)
    }

    return string
  }

  /**
   * @description transform links to clicca qui
   * @param text
   * @return {string|void|*}
   */
  transformURLToATags(text: string) {
    text = text.replace(
      /((?:(?:http(?:s|):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)))/g,
      '<a href="$1" target="_blank">$1</a>'
    )
    text = text.replace(/(?:href=\"((?!http)[^"]*)\")/g, 'href="https://$1"')
    return text
  }

  /**
   * @description transform url images to <img> tags
   * @param text
   * @return {*}
   */
  transformURLtoMediaImage(text: string) {
    return text.replace(getImageRegex(), '<img src="$1"/>')
  }
}
