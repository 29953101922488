import Variable from '../assets/js/models/Variable'
import VariableService from '@/assets/js/services/VariableService'

interface State {
  all: null | Variable[]
  loading: boolean
}

export default {
  state: () => ({
    all: null,
    loading: false,
  }),
  mutations: {
    setAll(state: State, value: Variable[]) {      
      state.all = value
      state.loading = false
    },
    addNew(state: State, value: Variable) {
      if (value.getName().length) {
        const exist = state.all?.find((v) => v.getName() === value.getName())
        if (!exist) {
          if (!state.all) {
            state.all = []
          }
          state.all.push(value)
        }
      }
    },
    setLoading(state: State, value: boolean) {
      state.loading = value
    },
  },
  actions: {
    load({ commit, state }: any) {
      if (!state.loading) {
        if (state.all === null) {
          VariableService.onLoad((data: Variable[]) => {
            commit('setAll', data)
          })

          VariableService.load()
          commit('setLoading', true)
        }
      }
    },
    reset({ commit, dispatch }: any) {
      commit('setAll', null)
      dispatch('load')
    }
  },
}
