import { extend } from 'vee-validate'
import {
  required,
  email,
  numeric,
  between,
  min,
  max,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('min', min)
extend('max', max)
extend('between', between)
