import { Vue, Component, Inject } from 'nuxt-property-decorator'
import UProgressBar from '@/components/u-progress-bar/index.vue'
import { Subscription } from '@/assets/js/models/Users/Subscription'
import MUPOptions from '@/assets/js/interfaces/MUPOptions'
import Analytics from '@/assets/js/core/Analytics'
import StripeService from '@/assets/js/services/StripeService'
import { user } from '@/modules/auth'

@Component({
  components: {
    UProgressBar,
  },
})
export default class UTrialBannerComponent extends Vue {
  @Inject('bot')
  private readonly bot!: Function

  subscription: Subscription | null = null

  get href(): string {
    return this.bot() ? `/${this.bot().id()}/accounts/` : '/accounts/'
  }

  get status(): boolean {
    return this.$store?.state.trialBanner.status
  }

  set status(value: boolean) {
    this.$store?.dispatch('trialBanner/setStatus', value)
  }

  get navbarStatus() {
    return this.$store?.state.navbar.status
  }

  get trialDays(): number {
    if (this.subscription) {
      return this.subscription!.getTrialDaysRemaining()
    }
    return 0
  }

  get duration() {
    if (this.subscription) {
      return this.subscription.getTrialDaysDuration()
    }
    return 0
  }

  modalUpgradePlan: boolean = false
  get modalUpgradePlanOptions(): MUPOptions {
    return {
      icon: 'dashboard',
      title: this.$t('upgrade.title').toString(),
      description: this.$t('upgrade.description').toString(),
      highlights: [
        {
          icon: 'brain',
          title: this.$t('upgrade.highlights.1.title').toString(),
          description: this.$t('upgrade.highlights.1.description').toString(),
        },
        {
          icon: 'user',
          title: this.$t('upgrade.highlights.2.title').toString(),
          description: this.$t('upgrade.highlights.2.description').toString(),
        },
        {
          icon: 'chart-line',
          title: this.$t('upgrade.highlights.3.title').toString(),
          description: this.$t('upgrade.highlights.3.description').toString(),
        },
      ],
    }
  }

  async mounted() {
    this.subscription = await user().subscription()
    this.status =
      this.subscription!.getTrialDaysRemaining() >= 0 &&
      !this.subscription?.isForever()
  }

  destroyed() {
    this.status = false
  }

  private async onClickUpgradeNow() {
    try {
      Analytics.event('Trial Banner', 'Click', 'Upgrade now')
      const url = await StripeService.getCustomerPortalUrl()
      if (url) {
        location.href = url
      } else {
        throw new Error('Customer portal url missing')
      }
    } catch (e) {
      this.$notice.alert(this.$t('cannot-show-plan-info'))
    }
  }
}
