import { mixins, Component, Prop, Inject } from 'nuxt-property-decorator'
import { BotAuthMixin, hasPermission, hasPermissions } from '@/modules/auth'
import UNavbarMenuElement from '@/components/u-navbar/u-navbar-menu-element/index.vue'
import UNavbarMenuCategory from '@/components/u-navbar/u-navbar-menu-category/index.vue'

@Component({
  components: {
    UNavbarMenuElement,
    UNavbarMenuCategory,
  },
})
export default class UNavbarMenu extends mixins(BotAuthMixin) {
  @Inject()
  readonly notifications: () => number

  @Prop({ type: String, required: false, default: '' })
  route: string

  get navbarStatus() {
    return this.$store.state.navbar.status
  }

  get trainingItems() {
    return [
      {
        title: this.$t('flows'),
        active: this.checkRoute('flows'),
        route: `bots/${this.bot?.id()}/flows`,
        visible: hasPermission('Flows'),
      },
      {
        title: this.$t('training-ai'),
        active: this.checkRoute('ai'),
        route: `bots/${this.bot?.id()}/ai`,
        visible: hasPermission('AI'),
      },
      {
        title: this.$t('automated-messages'),
        active: this.checkRoute('automated-messages'),
        route: `bots/${this.bot?.id()}/automated-messages`,
        visible: true,
      },
    ]
  }

  get reportsItem() {
    if (hasPermission('KpiSales')) {
      return [
        {
          title: 'Customer Care',
          route: `bots/${this.bot?.id()}/dashboard/customer`,
          active: this.checkRoute('dashboard-customer'),
          visible: true,
        },
        {
          title: 'Sales Report',
          route: `bots/${this.bot?.id()}/dashboard/sales`,
          active: this.checkRoute('dashboard-sales'),
          visible: hasPermission('KpiSales'),
        },
      ]
    } else {
      return []
    }
  }

  hasPermission = hasPermission
  hasPermissions = hasPermissions

  checkRoute(path: string) {
    return this.route.includes(`botKey-${path}`)
  }
}
