export interface Plugin {
  id: number
  name: string
  parameters: any
}

interface Plugins {
  plugins: Plugin[]
}

const state = (): Plugins => ({
  plugins: [],
})

const mutations = {
  setPlugins(state: Plugins, value: Plugin[]) {
    state.plugins = value
  },
}

export default {
  state,
  mutations,
}
