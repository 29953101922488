import { BaseService } from '../BaseService'

export class UserService extends BaseService {
  /**
   * Login
   * @param data
   * @return {Promise<any>}
   */
  login(data) {
    return this.http.post('login', data)
  }

  /**
   * Register a new User
   * @param data
   */
  register(data) {
    let postData = data

    if (data.card) {
      postData = Object.assign({}, postData, {
        card: data.card,
      })
    }

    return this.http.mock().post('register', postData)
  }

  salesRegister(data) {
    return this.http2.mock().post('register', data)
  }

  /**
   * Save billing data
   * @param data
   */
  saveBillingData(data) {
    return this.http.mock().put('customers/billing', data)
  }

  /**
   * Get billing data
   * @return {Promise<void>}
   */
  getBillingData() {
    return this.http.mock().get('customers/billing')
  }

  /**
   * Get all users
   * @return {Promise<void>}
   */
  all() {
    return this.http.mock().get('users')
  }

  /**
   * @description remove user by id
   * @param id
   * @return {*}
   */
  remove(id) {
    return this.http.mock().delete(`users/${id}`)
  }

  /**
   * @description create a direct new user
   * @param data
   * @return {*}
   */
  create(data) {
    return this.http.mock().post('users', data, false)
  }

  /**
   * @description create a direct new user
   * @param userId
   * @param data
   * @return {*}
   */
  update(userId, data) {
    return this.http.mock().put(`users/${userId}`, data)
  }
}
