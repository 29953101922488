class Key {
  isCode(event: KeyboardEvent, code: number) {
    return this.getKeyCode(event) === code || event.which === code
  }

  isEsc(event: KeyboardEvent) {
    const keyCode = this.getKeyCode(event)
    return keyCode === 27 || keyCode === 'Escape'
  }

  isEnter(event: KeyboardEvent) {
    const keyCode = this.getKeyCode(event)
    return keyCode === 13 || keyCode === "Enter"
  }

  isSpaceBar(event: KeyboardEvent) {
    return event.code === 'Space' || this.getKeyCode(event) === 32
  }

  isRightClick(event: KeyboardEvent) {
    return event.which === 3
  }

  isADeleteKey(event: KeyboardEvent) {
    const key = this.getKeyCode(event) || event.charCode
    return key === 8 || key === 46
  }

  isUpArrow(event: KeyboardEvent) {
    return this.getKeyCode(event) === 38
  }

  isDownArrow(event: KeyboardEvent) {
    return this.getKeyCode(event) === 40
  }

  isLeftArrow(event: KeyboardEvent) {
    return this.getKeyCode(event) === 37
  }

  isRightArrow(event: KeyboardEvent) {
    return this.getKeyCode(event) === 39
  }

  isArrow(event: KeyboardEvent) {
    return (
      this.isRightArrow(event) ||
      this.isDownArrow(event) ||
      this.isUpArrow(event) ||
      this.isLeftArrow(event)
    )
  }

  isTextable(event: KeyboardEvent) {
    const code = this.getKeyCode(event)

    if (code) {
      const inp = String.fromCharCode(Number(code))
      return /[a-zA-Z0-9-_ ]/.test(inp)
    }
    return false
  }

  isShift(event: KeyboardEvent) {
    return event.shiftKey
  }

  isCtrl(event: KeyboardEvent) {
    return event.ctrlKey
  }

  private getKeyCode(event: KeyboardEvent) {
    if (event.key !== undefined) {
      return event.key
    } else if (event.keyCode !== undefined) {
      return event.keyCode
    }
  }
}

export default new Key()
