import orderBy from 'lodash/orderBy'
import { SocketFlow } from '../assets/js/models/Flows/SocketFlow'
import FlowService from '@/assets/js/services/FlowService'
import Flow from '@/components/flowchart-builder/interfaces/Flow'

const service = new FlowService('flows')

export default {
  state: () => ({
    all: [],
    loading: true,
  }),
  mutations: {
    setAll(state: any, value: []) {
      state.all = orderBy(value, 'name')
      state.loading = false
    },
    add(state: any, value: any) {
      state.all.push(value)
    },
    updateOneById(state: any, value: any) {
      const key = state.all.findIndex((f: any) => f.id === value.id)
      state.all[key] = value
    },
  },
  getters: {
    active: (state: any) => {
      if (state.all) {
        return state.all.filter((f: any) => {
          return f.isVisible
        })
      }
    },
  },
  actions: {
    load({ commit, state, dispatch }: any) {
      service.onLoad((data: any[]) => {
        commit('setAll', data)
      })

      service.load()
      dispatch('bind')
    },
    bind({ commit }: any) {
      SocketFlow.onPublish((data: any) => {
        const flowPublished = JSON.parse(data.publishedRecord.records)[0]
        commit('updateOneById', flowPublished)
      })

      service.onCreate((data: Flow) => {
        commit('add', data)
      })
    },
  },
}
