/* eslint-disable camelcase */
import { Model } from '@/assets/js/core/Model'
import { is_url_scheme } from '@/assets/js/core/helpers'

const actions = [
  {
    action: 'goto',
    icon: 'training',
    title: 'action.interaction',
    type: 'Interaction',
  },
  {
    action: 'goto',
    icon: 'swap',
    title: 'action.flows',
    type: 'Flow',
  },
  {
    action: 'text',
    icon: 'text',
    title: 'action.text',
  },
  {
    action: 'url',
    icon: 'link',
    title: 'action.link',
  },
]

export class InteractionButton extends Model {
  constructor(attrs = null) {
    attrs.content = attrs.content ?? ''
    super(attrs || actions[0])
    this.set('dirty', false)
    if (this.get('action') === 'goto' && this.get('flows_id')) {
      this.set('flows_startMessageLabel', this.get('goto'))
      delete this.attrs().goto
    }
  }

  /**
   * @description get json value
   * @return {*}
   */
  toJson() {
    const attrs = this.attrs()

    if (!this.isFlow()) {
      delete attrs.flows_id
      delete attrs.goto
      delete attrs.flows_startMessageLabel
    }

    if (!this.isInteraction()) {
      delete attrs.label
    }

    if (this.isLink() && attrs.content) {
      if (!/^(f|ht)tps?:\/\//i.test(attrs.content)) {
        attrs.content = `https://${attrs.content}`
      }
    }

    return attrs
  }

  /**
   * @description check if button is valid
   * @return {boolean|*}
   */
  isValid() {
    if (this.get('title') && this.get('title').trim()) {
      if (this.get('title').trim()) {
        if (this.isFlow()) {
          return !!this.get('flows_id')
        }

        if (this.isInteraction()) {
          return !!this.get('label')
        }

        if (!this.get('content') || !this.get('content').trim().length) {
          return false
        }

        if (this.isText()) {
          return this.get('content').trim().length
        }

        if (this.isLink()) {
          return (
            this.get('content').trim().length &&
            is_url_scheme(this.get('content'))
          )
        }
      }
      return true
    }

    return false
  }

  /**
   * @description is interaction action
   * @return {boolean}
   */
  isInteraction() {
    return (
      this.get('action') === this.constructor.actions(0).action &&
      this.isUndefined('flows_id')
    )
  }

  /**
   * @description check if is flow actions
   * @return {boolean}
   */
  isFlow() {
    return (
      this.get('action') === this.constructor.actions(1).action &&
      !this.isUndefined('flows_id')
    )
  }

  /**
   * @description check if is text action
   * @return {boolean}
   */
  isText() {
    return this.get('action') === this.constructor.actions(2).action
  }

  /**
   * @description is link action
   * @return {boolean}
   */
  isLink() {
    return this.get('action') === this.constructor.actions(3).action
  }

  /**
   * @description set action
   * @param action
   */
  setAction(action) {
    this.set('action', action.action)
    if (action.type === 'Flow') {
      this.set('flows_id', '')
    } else {
      this.set('flows_id', undefined)
    }
  }

  /**
   * @description get button icon
   * @return {string}
   */
  icon() {
    return actions.find((a) => a.action === this.get('action')).icon
  }

  /**
   * @description default actions
   * @return {*[]}
   */
  static actions(val = null) {
    if (val !== null) return actions[val]
    return actions
  }
}
