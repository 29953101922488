export class Router {
  /**
   * @description redirect to url
   * @param path {Array}
   */
  navigate(path = '/') {
    location = path
  }
}

export default new Router()
