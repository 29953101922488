export default class Collection {
  /**
   * @description Elements container
   * @type {Array}
   */
  constructor(els) {
    this.elements = els
  }

  /**
   * @description count elements
   * @returns {number}
   */
  count() {
    return this.elements.length
  }

  /**
   * @description check if collection is empty
   * @returns {boolean}
   */
  isEmpty() {
    return !this.count()
  }

  /**
   * @description add element
   * @param el
   * @returns {Core.Collection}
   */
  add(el) {
    this.elements.push(el)
    return this
  }

  /**
   * @description remove element
   * @param el
   */
  pull(el) {
    this.elements.forEach((item, key) => {
      if (item === el) {
        this.elements.splice(key, 1)
      }
    })
    return this
  }

  /**
   * @description remove element
   * @param el
   */
  pullById(el) {
    this.elements.forEach((item, key) => {
      if (item.get('id') === el.get('id')) {
        this.elements.splice(key, 1)
      }
    })
    return this
  }

  /**
   * @description return all elements
   * @returns {Array}
   */
  all() {
    return this.elements
  }

  /**
   * @description get last element
   * @returns {*}
   */
  last() {
    return this.elements[this.elements.length - 1]
  }

  /**
   * @description call callback every element
   * @param callback
   * @return Collection
   */
  each(callback) {
    this.elements.forEach((item, key) => callback.call(this, item, key))
    return this
  }

  /**
   * @description get first element where key has value
   * @param key
   * @param value
   */
  find(key, value) {
    let found = false
    this.elements.some((item) => {
      if (item.get(key) === value) {
        found = item
        return true
      }
    })
    return found
  }

  /**
   * @description filter collection
   * @param params
   * @param value
   * @return this
   */
  filter(params, value) {
    if (typeof params !== 'object') params = [params]
    const filtered = this.elements.filter((item) => {
      let _return = false
      params.forEach((param) => {
        if (item.get(param) === value) {
          _return = true
        }
      })
      return _return
    })
    return new Collection(filtered)
  }

  /**
   * @description return first element
   * @return {any}
   */
  first() {
    return this.elements[0]
  }

  /**
   * @description sort collection
   * @param callback
   */
  sort(callback) {
    this.elements = this.all().sort(callback)
    return this
  }

  /**
   * @description map elements array
   * @param cb
   * @returns {Collection}
   */
  map(cb) {
    this.elements = this.elements.map((item, key) => cb(item, key))
    return this
  }

  /**
   * @description add element to start array
   * @param el
   * @return {Collection}
   */
  prepend(el) {
    this.elements.unshift(el)
    return this
  }

  /**
   * @description foreach
   * @param fn
   * @returns {Collection}
   */
  forEach(fn) {
    this.elements.forEach(fn)
    return this
  }

  /**
   * @description reverse collection
   */
  reverse() {
    this.elements = this.elements.reverse()
    return this
  }

  /**
   * @description replace element by id
   * @param el
   */
  replaceById(el) {
    this.elements.forEach((item, key) => {
      if (item.get('id') === el.get('id')) {
        this.elements[key] = el
      }
    })
    return this
  }

  /**
   * @description set elements unique
   * @param attr
   * @returns {Collection}
   */
  unique(attr) {
    const attrsArray = []
    this.elements = this.elements.filter((item) => {
      if (!attrsArray.includes(item.get(attr))) {
        attrsArray.push(item.get(attr))
        return true
      }
      return false
    })
    return this
  }

  /**
   * @description replace element by id
   * @param el
   */
  replaceByKey(key, el) {
    this.elements[key] = el
    return this
  }

  /**
   * @description get element of array
   * @param key
   * @returns {*}
   */
  get(key) {
    return this.elements[key]
  }

  /**
   * @description index of
   * @param el
   */
  indexOf(el) {
    return this.elements.indexOf(el)
  }
}
