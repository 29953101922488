import { UserService } from '@/assets/js/services/User/UserService'
import Agent from '@/assets/js/interfaces/Agent'

export default {
  state: () => ({
    all: null,
    loading: true,
  }),
  getters: {
    chattables(state: any) {
      return state.all.filter(
        (a: Agent) =>
          a.role === 'OPERATORE' ||
          a.role === 'TEAM_LEADER' ||
          a.role === 'AMMINISTRATORE'
      )
    },
  },
  mutations: {
    setAll(state: any, value: any) {
      state.all = value
      state.loading = false
    },
    removeOne(state: any, { id, email }: { id: number; email?: string }) {
      let attributeName: string, attributeValue: any

      if (id) {
        attributeName = 'id'
        attributeValue = id
      } else if (email) {
        attributeName = 'email'
        attributeValue = email
      }

      const index = state.all.findIndex((a: any) => {
        return a[attributeName] === attributeValue
      })
      state.all.splice(index, 1)
    },
    suspend(state: any, { id }: { id: [] }) {
      id.forEach((agentId: number) => {
        const index = state.all.findIndex((a: Agent) => {
          return a.id === agentId
        })
        state.all[index].enabled = false
      })
    },
    enable(state: any, { id }: { id: [] }) {
      id.forEach((agentId: number) => {
        const index = state.all.findIndex((a: Agent) => {
          return a.id === agentId
        })
        state.all[index].enabled = true
      })
    },
  },
  actions: {
    async load({ commit }: any): Promise<any> {
      const res: any = await new UserService().all()
      commit('setAll', res.data()?.users)
    },
    enable({ commit }: any, params: any) {
      commit('enable', params)
      commit('authentication/increaseUsersUsed', null, { root: true })
    },
    suspend({ commit }: any, params: any) {
      commit('suspend', params)
      commit('authentication/decreaseUsersUsed', null, { root: true })
    },
    addFirst({ commit, state }: any, agent: any) {
      state.all.unshift(agent)
      commit('authentication/increaseUsersUsed', null, { root: true })
    },
    add({ commit, state }: any, agent: any) {
      state.all.push(agent)
      commit('authentication/increaseUsersUsed', null, { root: true })
    },
  },
}
