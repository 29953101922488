import { BaseService } from '../BaseService'

export class PaymentMethodService extends BaseService {
  /**
   * @description get subscription
   * @return {Promise<any>}
   */
  get() {
    return this.http.mock().get('payment-methods')
  }

  /**
   * @description create new payment method
   * @param id
   * @param card
   * @return {Promise<any>}
   */
  create(id, card) {
    return this.http.mock().post(`subscriptions/${id}/checkout`, {
      checkout: { card },
    })
  }

  /**
   * @description detach a payment method
   * @param paymentMethodId
   * @return {*}
   */
  detach(paymentMethodId) {
    return this.http.mock().delete(`payment-methods/${paymentMethodId}`)
  }

  /**
   * @description detach a payment method
   * @param paymentMethodId
   * @return {*}
   */
  setAsDefault(paymentMethodId) {
    return this.http
      .mock()
      .post('payment-methods/set-default', { paymentMethodId })
  }

  /**
   * @description get secret key for payment API
   * @return {Promise<any>}
   */
  getClientSecretKey(usage = 'off_session') {
    return this.http.mock().post('client-secret', { usage })
  }
}
