export default {
  name: 'u-navbar-menu-category',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['mounted'],
  data () {
    return {}
  },
  computed: {
    navbarStatus () {
      return this.$store.state.navbar.status
    }
  },
  mounted () {
    this.$emit('mounted', true)
  },
  methods: {}
}
