import Cookies from 'js-cookie'
import jwt from './jwt/jwt'
import permissions, { Permission } from './permissions'

export const hasPermission = (value: Permission) => {
  const permission = permissions[value]
  return !!jwt.permissions.find((v) => v === permission)
}

export const hasPermissions = (...props: Permission[]) => {
  return props.every((p) => hasPermission(p))
}

const destroyAll = async () => {
  jwt.delete()
  if (process.client) {
    localStorage?.removeItem('defaultQuestionType')
    localStorage?.removeItem('userbot_country')
  }
  removeAllCookies()
}

const removeAllCookies = () => {
  if (Cookies.get()) {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName, { path: '/' })
    })
  }
}

export const login = async (redirectUrl = '') => {
  await destroyAll()
  console.log(redirectUrl)
}

export const logout = async () => {
  await destroyAll()
}
