import { Vue } from 'nuxt-property-decorator'
import vSelect from 'vue-select'
import UButton from '@/components/buttons/u-button/u-button.vue'
import UIcon from '@/components/icons/u-icon/index.vue'
import ULink from '@/components/u-link/index.vue'
import Badge from '@/components/badge/index.vue'
import UBadge from '@/components/badges/u-badge/u-badge.vue'
import Badges from '@/components/badges/index.vue'
import UBadgePro from '@/components/badges/u-badge-pro/u-badge-pro.vue'
import UButtonDropdown from '@/components/buttons/u-button-dropdown/u-button-dropdown.vue'
import UButtonVariables from '@/components/buttons/u-button-variables/u-button-variables.vue'
import UCard from '@/components/cards/u-card/u-card.vue'
import UCardCollapse from '@/components/cards/u-card-collapse/index.vue'
import UDatalist from '@/components/form/u-datalist/index.vue'
import UDropdown from '@/components/dropdowns/u-dropdown/index.vue'
import UDropdownContent from '@/components/dropdowns/u-dropdown-content/index.vue'
import LoadingPoints from '@/components/loading-points/index.vue'
import PageLoader from '@/components/page-loader/index.vue'
import UTrialBanner from '@/components/u-trial-banner/index.vue'
import UScrollContent from '@/components/u-scroll-content/index.vue'
import UPopover from '@/components/UPopover.vue'
import UButtonNew from '@/components/buttons/u-button-new/u-button-new.vue'
import UButtonLoad from '@/components/buttons/u-button-load/u-button-load.vue'
import UCheckbox from '@/components/form/u-checkbox/index.vue'
import ClickOutside from '@/components/events/click-outside/index.vue'
import Lottie from '@/components/lottie/index.vue'
import ImagesComponents from '@/components/images'
import USwitch from '@/components/u-switch/index.vue'
import UPage from '@/components/u-page/index.vue'
import UModal from '@/components/modals/u-modal/index.vue'
import UModalTitle from '@/components/modals/u-modal-title/index.vue'
import Panel from '@/components/panels/panel/index.vue'
import UApi from '@/components/u-api/index.vue'
import UUserPicture from '@/components/u-user-picture/index.vue'
import ULoader from '@/components/u-loader/index.vue'
import UPopoverUpgradePlan from '@/components/upgrades/u-popover-upgrade-plan/index.vue'
import UInput from '@/components/form/u-input/index.vue'
import uChevron from '@/components/icons/u-chevron/index.vue'

// Directives
import '@/assets/js/directives/Autowidth'
import '@/assets/js/directives/InView'
import '@/assets/js/directives/sortable'
import '@/assets/js/directives/ClickOutside'

require('@/assets/js/filters/Currency')
require('@/assets/js/filters/Date')

Vue.component('ULink', ULink)
Vue.component('Badge', Badge)
Vue.component('UBadge', UBadge)
Vue.component('Badges', Badges as any)
Vue.component('UBadgePro', UBadgePro)
Vue.component('UIcon', UIcon)
Vue.component('UButton', UButton)

Vue.component('UCard', UCard)
Vue.component('UDatalist', UDatalist)
Vue.component('LoadingPoints', LoadingPoints)
Vue.component('UTrialBanner', UTrialBanner)
Vue.component('PageLoader', PageLoader)
Vue.component('UScrollContent', UScrollContent)
Vue.component('UPng', ImagesComponents.UPng)
Vue.component('UPopover', UPopover)
Vue.component('UPopoverUpgradePlan', UPopoverUpgradePlan)
Vue.component('UDropdown', UDropdown)
Vue.component('UDropdownContent', UDropdownContent)

// Layout
Vue.component('UPage', UPage)
Vue.component('UApi', UApi)

// Buttons
Vue.component('UButtonNew', UButtonNew)
Vue.component('UButtonDropdown', UButtonDropdown)
Vue.component('UButtonVariables', UButtonVariables)
Vue.component('UButtonLoad', UButtonLoad)

// Icons
Vue.component('UChevron', uChevron)

// Components
Vue.component('UModal', UModal)
Vue.component('UModalTitle', UModalTitle)
Vue.component('Panel', Panel)
Vue.component('UUserPicture', UUserPicture)
Vue.component('UCardCollapse', UCardCollapse)
Vue.component('ULoader', ULoader)

// Images

// Form
Vue.component('USwitch', USwitch)
Vue.component('UInput', UInput)
Vue.component('VSelect', vSelect)
Vue.component('UCheckbox', UCheckbox)
Vue.component('UPng', ImagesComponents.UPng)
Vue.component('UBadge', UBadge)
Vue.component('ClickOutside', ClickOutside)
Vue.component('Lottie', Lottie)
