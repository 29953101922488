import { Bot } from '../models/Bot'
import BotUpdatePayloadDto from '../dtos/BotUpdatePayloadDto'
import BotUpdateResponseDto from '../dtos/BotUpdateResponseDto'
import { Api } from '../core/Api'
import { Socket } from '@/assets/js/core/Socket'

interface BotData {
  name: string
  lang: string
  group?: number | null
}
class _BotService {
  load() {
    Socket.send('bot stats', {
      type: 'all',
    })
  }

  getOneByKey(key: string) {
    return Api.get(`bot/key/${key}/`)
  }

  onList(cb: Function) {
    Socket.on('bot', (_: string, data: any) => {
      if (data.status === 200) {
        if (data.subcommand === 'stats') {
          cb.call(
            this,
            data.data.stats.map((b: any) => new Bot(b))
          )
        }
      } else {
        // TODO: manage errors
      }
    })
  }

  create(data: BotData) {
    Socket.send('bot create', {
      name: data.name.trim(),
      lang: data.lang.toUpperCase(),
      group: data.group,
    })
  }

  onCreate(cb: Function) {
    Socket.on('bot', (cmd: string, data: any) => {
      if (data.status === 200) {
        if (data.subcommand === 'create') {
          data.created_now = true
          cb.call(this, new Bot(data.data))
        }
      } else {
        // TODO: manage errors
      }
    })
  }

  update(params: BotUpdatePayloadDto) {
    Socket.send('bot rename', params)
  }

  onUpdate(cb: (data: BotUpdateResponseDto) => void) {
    Socket.on('bot', (_cmd: string, data: BotUpdateResponseDto) => {
      if (cb) {
        cb(data)
      }
    })
  }

  getOptions (key: string) {
    return Api.get(`bot/${key}/options`)
  }

  getDefaultOption () {
    return Api.get(`bot/option/default`)
  }

  updateOption (key: number, id: number | undefined, value: string) {
    return Api.patch(`bot/${key}/option/${id}`, {
      value
    })
  }

  deleteOption (key: number, id: number | undefined) {
    return Api.delete(`bot/${key}/option/${id}`)
  }
}

const BotService = new _BotService()

export default BotService
