import { render, staticRenderFns } from "./u-button.vue?vue&type=template&id=93d5c204&scoped=true"
import script from "./u-button.vue?vue&type=script&setup=true&lang=ts"
export * from "./u-button.vue?vue&type=script&setup=true&lang=ts"
import style0 from "node-waves/src/scss/waves.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./u-button.scss?vue&type=style&index=1&id=93d5c204&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93d5c204",
  null
  
)

export default component.exports