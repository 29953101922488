interface FileState {
  downloadingFiles: number[]
}

export default {
  state: () => ({
    downloadingFiles: [],
  }),
  mutations: {
    addDownloadingFile(state: FileState, value: number) {
      state.downloadingFiles.push(value)
    },
    removeDownloadingFile(state: FileState, value: number) {
      const index = state.downloadingFiles.indexOf(value)
      if (index > -1) {
        state.downloadingFiles.splice(index, 1)
      }
    },
  },
}
