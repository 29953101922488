import PricePlan from '@/assets/js/interfaces/PricePlan'

export default {
  id: 'sales-free',
  name: 'Free',
  disabled: false,
  stripe_key: {
    month: 'products.license.sales_free.month',
  },
  price: {
    monthly: 0,
  },
} as PricePlan
