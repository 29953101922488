import { Model } from '@/assets/js/core/Model'
import DiscountInterface from '@/assets/js/interfaces/Discount'

export default class Discount extends Model implements DiscountInterface {
  amount(): number {
    if (this.get('coupon').amount_off) {
      return this.get('coupon').amount_off / 100
    } else {
      return 0
    }
  }

  percent(): number {
    return this.get('coupon').percent_off
  }

  calcPercent(value: number): number {
    if (this.percent()) {
      return (value * this.percent()) / 100
    }
    return 0
  }

  isPercent(): boolean {
    return !!this.get('coupon').percent_off
  }

  endDate(): string {
    return this.get('end')
  }

  name(): string {
    return this.get('coupon').name
  }

  isForever(): boolean {
    return this.get('coupon').duration === 'forever'
  }

  isOnce(): boolean {
    return this.get('coupon').duration === 'once'
  }
}
