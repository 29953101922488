export class HttpError {
  private readonly attrs: any

  constructor(attrs: any) {
    this.attrs = attrs
  }

  text(): string {
    return this.attrs.message
  }
}
