import { defineAsyncComponent } from 'vue'
import USearchBar from '@/components/u-search-bar/index.vue'

const UDropdownInteractionsGroups = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/dropdowns/u-dropdown-interactions-groups/index.vue' /* webpackChunkName: "pages/db-ai/u-dropdown-interactions-groups" */
      ).then(resolve)
    })
)

const DbAiToApproveUpgradeButton = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/db-ai-to-approve-upgrade-button/index.vue' /* webpackChunkName: "pages/db-ai/db-ai-to-approve-upgrade-button" */
      ).then(resolve)
    })
)

const UQuestionsSearchBar = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-questions/u-questions-search-bar/index.vue' /* webpackChunkName: "components/u-questions-search-bar" */
      ).then(resolve)
    })
)

const UButtonPopoverUnlockMaxInteractions = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        './u-button-popover-unlock-max-interactions/index.vue' /* webpackChunkName: "components/u-button-popover-unlock-max-interactions" */
      ).then(resolve)
    })
)

const UBannerUnlockMaxInteractions = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        './u-banner-unlock-max-interactions/index.vue' /* webpackChunkName: "components/u-banner-unlock-max-interactions" */
      ).then(resolve)
    })
)

const DbAiInteractionMultiSelection = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/db-ai-interaction-multi-selection/index.vue' /* webpackChunkName: "components/db-ai-interaction-multi-selection" */
      ).then(resolve)
    })
)

const UEmptyBox = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-empty-box/index.vue' /* webpackChunkName: "components/u-empty-box" */
      ).then(resolve)
    })
)

const AiInteraction = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/ai-interaction/index.vue' /* webpackChunkName: "components/ai-interaction" */
      ).then(resolve)
    })
)

const UPageUpgrade = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-page-upgrade/index.vue' /* webpackChunkName: "components/u-page-upgrade" */
      ).then(resolve)
    })
)

const UDropdownFilterToApproveInteractions = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/dropdowns/u-dropdown-filter-to-approve-interactions/index.vue' /* webpackChunkName: "components/u-dropdown-filter-to-approve-interactions" */
      ).then(resolve)
    })
)

const UQuestions = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-questions/index.vue' /* webpackChunkName: "components/u-questions" */
      ).then(resolve)
    })
)

const UDropdownAiHelp = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/u-dropdown-ai-help/index.vue' /* webpackChunkName: "components/u-dropdown-ai-help" */
      ).then(resolve)
    })
)

const UModalInteractionsBulkDelete = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/u-modal-interactions-bulk-delete/index.vue' /* webpackChunkName: "components/u-modal-interactions-bulk-delete" */
      ).then(resolve)
    })
)

const UModalImportTraining = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/u-modal-import-training/index.vue' /* webpackChunkName: "components/u-modal-import-training" */
      ).then(resolve)
    })
)

const UConversation = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-conversation/index.vue' /* webpackChunkName: "components/u-conversation" */
      ).then(resolve)
    })
)

const UGenerativeAIConfiguration = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        './u-generative-ai-configuration/index.vue' /* webpackChunkName: "components/u-generative-ai-configuration" */
      ).then(resolve)
    })
)

const DbAiDocuments = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/db-ai-documents/index.vue' /* webpackChunkName: "components/db-ai-documents" */
      ).then(resolve)
    })
)

const SharePointDocuments = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/db-ai-documents-sharepoint/index.vue' /* webpackChunkName: "components/sharepoint-documents" */
      ).then(resolve)
    })
)

const DbAiUrl = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/db-ai-url/index.vue' /* webpackChunkName: "components/db-ai-url" */
      ).then(resolve)
    })
)

const UDropdownAddTraining = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/containers/db-ai/u-dropdown-add-training/index.vue' /* webpackChunkName: "components/u-dropdown-add-training" */
      ).then(resolve)
    })
)

export default {
  USearchBar,
  UQuestionsSearchBar,
  DbAiToApproveUpgradeButton,
  UButtonPopoverUnlockMaxInteractions,
  UBannerUnlockMaxInteractions,
  UDropdownInteractionsGroups,
  DbAiInteractionMultiSelection,
  UEmptyBox,
  AiInteraction,
  UPageUpgrade,
  UDropdownFilterToApproveInteractions,
  UQuestions,
  UDropdownAiHelp,
  UModalInteractionsBulkDelete,
  UModalImportTraining,
  UConversation,
  UGenerativeAIConfiguration,
  DbAiDocuments,
  DbAiUrl,
  UDropdownAddTraining,
  SharePointDocuments,
}
