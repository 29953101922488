export default {
  name: 'u-chevron',
  props: {
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
