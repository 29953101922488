import { Vue, Component } from 'nuxt-property-decorator'

declare global {
  interface Window {
    UApi: any
  }
}

@Component
export default class UApi extends Vue {
  render() {
    return ''
  }

  mounted() {
    window.UApi = this
  }

  showMup(minPlanId: string) {
    this.$store?.commit('page/setMUPStripe', { minPlanId })
  }
}
