declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

export function head(key: string) {
  const d = document
  const s = 'script'
  const l = 'dataLayer'
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s) as HTMLScriptElement
  const dl = l !== 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + key + dl
  f.parentNode?.insertBefore(j, f)
}
