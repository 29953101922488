import {
  Component,
  Inject,
  Prop,
  Watch,
  Ref,
  mixins,
} from 'nuxt-property-decorator'
import { defineAsyncComponent } from 'vue'
import { BotAuthMixin, hasPermission } from '@/modules/auth'
import Analytics from '@/assets/js/core/Analytics'
import UNavbarMenuElement from '@/components/u-navbar/u-navbar-menu-element/index.vue'
import { User } from '@/assets/js/models/User'
import introductionVideo from '@/assets/js/helpers/introductionVideo'

const UYoutubePlayer = defineAsyncComponent(
  () =>
    new Promise((resolve) => {
      import(
        '@/components/u-youtube-player/index.vue' /* webpackChunkName: "components/u-youtube-player" */
      ).then(resolve)
    })
)

@Component({
  components: {
    UNavbarMenuElement,
    UYoutubePlayer,
  },
})
export default class UOnboardingPanel extends mixins(BotAuthMixin) {
  @Inject()
  user: () => User

  @Ref('dropdownContainer')
  refDropdownContainer: HTMLElement

  @Prop({ type: Boolean, required: false, default: false })
  appendToBody: boolean

  @Prop({ type: String, required: false, default: 'div' })
  tag: string

  @Watch('dropdownStatus')
  onDropdownStatusChange(val: boolean) {
    if (val) {
      this.opened = true
      Analytics.event('Onboarding', 'Click', 'Apri')
      localStorage?.setItem('onboarding-panel', '1')
    }
  }

  get videoDuration() {
    return introductionVideo.duration
  }

  /**
   * @description Step starter of tour
   * @type Boolean
   */
  stepStarter = []

  /**
   * @description Onboarding dropdown status
   * @type Boolean
   */
  dropdownStatus = false

  /**
   * @description Get user name
   */
  name = ''

  /**
   * @description modal video status
   */
  modalVideoStatus = false

  /**
   * @description Opened almost one time status
   */
  opened = false

  beforeMount() {
    this.name = this.user().name()
  }

  mounted() {
    this.refDropdownContainer?.parentElement?.classList.add(
      'u-onboarding-dropdown'
    )
  }

  /**
   * @description check if onboarding panel was opened one time
   */
  isOpenedOneTime() {
    return this.opened
  }

  hasPermission = hasPermission

  /**
   * @description on click to open video introduction
   */
  onClickOpenVideo() {
    this.modalVideoStatus = true
    Analytics.event('Onboarding', 'Click', 'Video introduttivo')
  }

  onClickSupport() {
    Analytics.event('Onboarding', 'Click', 'Ottieni supporto')
    this.$ufeedback.openCenter()
  }

  onClickFacebookCommunity() {
    Analytics.event('Onboarding', 'Click', 'Accedi alla community')
    window.open('https://www.facebook.com/groups/2579031455654514/', '_blank')
  }

  onClickFeatures() {
    Analytics.event('Doc', 'Click', 'Accedi alla documentazione')
    window.open('https://feedback.userbot.ai/', '_blank')
  }

  onClickDocumentation() {
    Analytics.event('Doc', 'Click', 'Accedi alla documentazione')
    window.open('https://docs.userbot.ai/', '_blank')
  }

  getVideoId() {
    return introductionVideo.id
  }
}
