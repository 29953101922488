import { head } from '@/modules/gtm/head'

const gtmId = {
  auth: process.env.NUXT_ENV_GTM_ID_AUTH,
  guest: process.env.NUXT_ENV_GTM_ID_GUEST,
}

export default {
  name: 'Gtm',
  props: {
    logged: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const code = gtmId[(this as any).logged ? 'auth' : 'guest']
    if (code) {
      head(code)
    }
  },
}
