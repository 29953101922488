import { render, staticRenderFns } from "./u-confetti.html?vue&type=template&id=3ea903ee&scoped=true&external"
import script from "./u-confetti.ts?vue&type=script&lang=ts&external"
export * from "./u-confetti.ts?vue&type=script&lang=ts&external"
import style0 from "./u-confetti.scss?vue&type=style&index=0&id=3ea903ee&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea903ee",
  null
  
)

export default component.exports