import ChatAiScoreOption from '../assets/js/services/ChatOption/ChatAiScoreOption'
interface Session {
  source: 'webchat' | 'facebook' | 'twitter' | 'whatsapp'
  source_urlweb?: string
}


type State = {
  aiScore: boolean
  activeSession: Session | null
  // ID operators online
  operatorsOnline: number[]
}

const state = (): State => ({
  aiScore: ChatAiScoreOption.isEnabled(),
  operatorsOnline: [],
  activeSession: null,
})

const mutations = {
  setActiveSession(state: State, value: Session) {
    state.activeSession = value
  },
  setAiScore(state: State, value: boolean) {
    state.aiScore = !!value
    ChatAiScoreOption.set(value)
  },
  setOperatorsOnline(state: State, value: number[]) {
    state.operatorsOnline = value
  },
}

export default {
  state,
  mutations,
}
