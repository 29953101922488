const InteractionCheckProperty = {
  areValidButtons() {
    if (this.get('buttons') && this.get('buttons').length) {
      return this.get('buttons').every((btn) => {
        if (btn.get('title').trim()) {
          return btn.isValid()
        } else return true
      })
    }
    return true
  },

  isApproved() {
    return this.get('ai') === 'approved'
  },

  isInTraining() {
    return this._training_status
  },

  isType(type) {
    return this.callbackType().type === type
  },

  isValid() {
    if (this.get('flows_id')) {
      return this.validQuestions().length
    } else if (this.getType() === 'upscale' && !this.answer()) {
      return (
        this.validQuestions().length &&
        this.areValidButtons() &&
        !this.hasButtons()
      )
    } else {
      return (
        this.validQuestions().length &&
        !!this.answer() &&
        this.areValidButtons()
      )
    }
  },

  hasButtons() {
    if (this.get('buttons')) {
      return this.get('buttons').filter((button) => button.get('title')).length
    }
    return false
  },

  hasDeepLearningQuestions() {
    return !!this.getDeepLearningQuestions().length
  },

  hasMinDeepLearningQuestions() {
    return this.getDeepLearningQuestions().length >= 5
  },

  hasPlaceholderButton() {
    if (!this.get('buttons') || !this.get('buttons').length) {
      return false
    }

    return this.get('buttons').some((btn) => !btn.isValid())
  },

  hasTextarea() {
    return this.callbackType().type !== 'flow'
  },

  hasToBeApproved() {
    return this.get('to_approve')
  },
}

export default InteractionCheckProperty
