import { Vue, Component } from 'nuxt-property-decorator'
import ConfettiGenerator from 'confetti-js'
import uniqueId from 'lodash/uniqueId'

declare global {
  interface Window {
    UserbotConfetti: UConfetti
  }
}

@Component
export default class UConfetti extends Vue {
  id = ''
  confetti: typeof ConfettiGenerator | null = null
  show = false

  beforeMount() {
    this.id = uniqueId('confetti-')
    window.UserbotConfetti = this
    Vue.prototype.$confetti = this
  }

  create() {
    if (this.confetti === null) {
      this.confetti = new ConfettiGenerator({
        target: this.id,
        max: '80',
        size: '1',
        animate: true,
        props: ['circle', 'square', 'triangle', 'line'],
        colors: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
        ],
        clock: '25',
        rotate: true,
        width: window.outerWidth,
        height: window.outerHeight,
      })

      this.show = true
    }
  }

  play(timeout: null | number = null) {
    this.create()
    this.confetti.render()

    if (timeout !== null) {
      setTimeout(() => this.stop(), timeout)
    }
  }

  stop() {
    this.show = false

    setTimeout(() => {
      if (this.confetti) {
        this.confetti.clear()
      }
      this.confetti = null
    }, 2000)
  }
}
