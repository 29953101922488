import { Context } from '@nuxt/types'
import { Api } from '@/assets/js/core/Api'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import ProfileService from '@/assets/js/services/ProfileService'

export default async function ({
  $auth,
  store,
  redirect,
  localePath,
}: Context) {
  if ($auth.loggedIn) {
    const token = $auth.getToken('local')
    Bearer.setToken(token)

    try {
      const response = await Api.get('users/auth')
      const user = response.attrs?.user
      if (user) {
        const profile = await ProfileService.load(user.customer_id)
        store.commit('authentication/setProfile', profile)
        store.commit('authentication/setLogo', profile?.coBrandingLogo)
        return store.commit('authentication/setUser', user)
      } else {
        throw new Error('Missing user')
      }
    } catch (e: any) {
      return redirect(localePath('logout'))
    }
  }
}
