import { Vue, Component, Prop, Watch, Emit, Ref } from 'nuxt-property-decorator'
import UCardCollapseInterface from './u-card-collapse.interface'

@Component
export default class UCardCollapse
  extends Vue
  implements UCardCollapseInterface
{
  @Ref()
  readonly body: HTMLDivElement

  @Prop({ type: String, required: false, default: '' })
  title: string

  @Prop({ type: Boolean, required: false, default: false })
  subTitle: boolean

  @Prop({ type: String, required: false, default: '' })
  description: string

  @Prop({ type: Boolean, required: false, default: true })
  toggeable: boolean

  @Prop({ type: Boolean, required: false, default: false })
  modelValue: boolean

  @Prop({ type: String, required: false, default: 'edit' })
  icon: string

  @Prop({ type: String, required: false, default: '' })
  titleIcon: string

  @Prop({ type: Boolean, required: false, default: false })
  iconAsPng: boolean

  @Prop({ type: Boolean, required: false, default: false })
  heightTitle: boolean

  @Watch('modelValue', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localValue = val
    }
  }

  static emits = ['input']

  localValue: boolean = false
  height: string = ''
  private bodyChangeInterval: null | ReturnType<typeof setInterval> = null

  beforeDestroy() {
    if (this.bodyChangeInterval) {
      clearInterval(this.bodyChangeInterval)
    }
  }

  toggle() {
    if (this.toggeable) {
      if (this.localValue) {
        this.close()
      } else {
        this.open()
      }
    }
  }

  private getBodyHeight() {
    if(!this.body) {
      return
    }
    return Array.from(this.body.children)
      .map((el: Element) => {
        const style = (el as any).currentStyle || window.getComputedStyle(el)
        return (
          el.getBoundingClientRect().height +
          parseInt(style.marginTop) +
          parseInt(style.marginBottom)
          // parseInt(style.paddingBottom) +
          // parseInt(style.paddingTop)
        )
      })
      .reduce((totalHeight: number, height: number) => {
        return (totalHeight += height)
      }, 0)
  }

  @Emit('update:modelValue')
  open(): boolean {
    this.bodyChangeInterval = setInterval(() => {
      if (this.localValue) {
        this.height = `${this.getBodyHeight()}px`
      } else {
        clearInterval(this.bodyChangeInterval!)
      }
    }, 200)
    this.localValue = true
    return true
  }

  @Emit('update:modelValue')
  close(): boolean {
    this.height = ''
    this.localValue = false
    return false
  }

  isOpened() {
    return this.localValue
  }
}
