/**
 * @description multilanguage Vue components
 */
import { Vue } from 'nuxt-property-decorator'
import VueI18n from 'vue-i18n'
import Cookie from 'js-cookie'
import moment from 'moment-timezone'
import i18n from '@/assets/js/core/i18n'

let localeLang = Cookie.get('lang') || i18n.locale()

switch (localeLang) {
  case 'en':
  case 'it':
  case 'fr':
    break

  default:
    localeLang = 'en'
    break
}

const translations = require(`@/langs/${localeLang}.json`)

Vue.use(VueI18n)

const vuei18n = new VueI18n({
  localeLang,
  messages: {
    [localeLang]: translations,
  },
  fallbackLocale: 'en',
  silentFallbackWarn: true,
})

moment.tz.setDefault('Europe/Berlin')
moment.locale(localeLang)

const locale = localeLang

export { Vue, vuei18n, locale, translations }
