import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class LogoComponent extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  white: boolean

  @Prop({ type: Boolean, required: false, default: false })
  horizontal: boolean

  @Prop({ type: Boolean, required: false, default: true })
  payoff: boolean

  @Prop({ type: Boolean, required: false, default: false })
  noClaim: boolean
}
