import axios from 'axios'
import { Http } from './Http'
import { Response } from './Http/Response'
import { Mocker } from './Http/Mocker'
import { apiUrl } from '@/modules/api'
import { Bearer } from '@/assets/js/core/Http/Bearer'
import i18n from '@/assets/js/core/i18n'

const axiosMocked = axios.create()
const mocker = new Mocker(axiosMocked)

class _API {
  /**
   *
   */
  constructor({ version }) {
    this.http = new Http()
    this._version = version
    this._url = apiUrl('')
    this._mocked_url = null
  }

  /**
   * @description post ajax method
   * @param {string} url
   * @param data
   */
  async post(url, data, withFinalSlash = true) {
    if (mocker.isActive()) {
      return mocker.mock(
        'post',
        this._mocked_url || url,
        this._getResponseFunction.bind(this)
      )
    }

    return axios({
      method: 'POST',
      url: this.url(url, withFinalSlash),
      data,
      json: true,
      headers: this._headers(),
    })
      .then(this._getResponseFunction.bind(this))
      .catch((err) => {
        return new Response(err.response.data)
      })
  }

  /**
   * @description get ajax method
   * @param {string} url
   * @param data
   * @param callback
   * @param errorCallback
   */
  async get(url, data = {}, withFinalSlash = true) {
    if (mocker.isActive()) {
      return mocker.mock(
        'get',
        this._mocked_url || url,
        this._getResponseFunction.bind(this)
      )
    }

    return axios({
      method: 'GET',
      url: this.url(url, withFinalSlash),
      data,
      json: true,
      headers: this._headers(),
    })
      .then(this._getResponseFunction.bind(this))
      .catch((err) => {
        return new Response(err.response ? err.response.data : err)
      })
  }

  _getResponseFunction(res) {
    mocker.restore()
    this._mocked_url = null
    return new Response(res)
  }

  /**
   * @description get ajax method
   * @param {string} url
   * @param data
   */
  async put(url, data) {
    if (mocker.isActive()) {
      return mocker.mock(
        'put',
        this._mocked_url || url,
        this._getResponseFunction.bind(this)
      )
    }

    return axios({
      method: 'PUT',
      url: this.url(url),
      data,
      json: true,
      headers: this._headers(),
    })
      .then(this._getResponseFunction.bind(this))
      .catch((err) => {
        return new Response(err.response ? err.response.data : err)
      })
  }

  async patch(url, data) {
    if (mocker.isActive()) {
      return mocker.mock(
        'patch',
        this._mocked_url || url,
        this._getResponseFunction.bind(this)
      )
    }

    return axios({
      method: 'PATCH',
      url: this.url(url),
      data,
      json: true,
      headers: this._headers(),
    })
      .then(this._getResponseFunction.bind(this))
      .catch((err) => {
        return new Response(err.response ? err.response.data : err)
      })
  }

  /**
   * @description post ajax method
   * @param {string} url
   * @param data
   * @param callback
   * @param errorCallback
   */
  async delete(url) {
    if (mocker.isActive()) {
      return mocker.mock(
        'delete',
        this._mocked_url || url,
        this._getResponseFunction.bind(this)
      )
    }

    return axios({
      method: 'DELETE',
      url: this.url(url),
      headers: this._headers(),
    })
      .then(this._getResponseFunction.bind(this))
      .catch((err) => {
        return new Response(err.response.data)
      })
  }

  /**
   *
   * @param options
   * @return {Observable<HttpEvent<any>>}
   */
  request(options) {
    const optionsMerged = Object.assign(options, {
      url: this.url(options.url),
    })
    return this.http.request(optionsMerged)
  }

  /**
   * @description download url
   * @param url
   * @param name
   * @param data
   * @param type
   * @return {Promise<AxiosResponse | Response>}
   */
  download({ url, name, data, type = 'pdf' }) {
    return axios({
      method: 'GET',
      url: this.url(url),
      data,
      headers: this._headers(`application/${type}`),
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: `application/${type}` })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${name}.${type}`
        link.click()
        return new Response(response)
      })
      .catch((res) => {
        return new Response(res)
      })
  }

  /**
   * @description return url to api endpoint
   * @param {string} url
   * @return {string}
   */
  url(url, withFinalSlash = true) {
    if (url.includes('login')) {
      return '/login/'
    } else {
      const urlCleaned =
        url[url.length - 1] === '/' || url.includes('?') || !withFinalSlash
          ? url
          : `${url}/`
      return `${this._url}/v${this._version}/${urlCleaned}`
    }
  }

  /**
   * @description send head call
   * @param url
   * @param callback
   * @param failed
   */
  head(url, callback, failed) {
    this.http.head(this.url(url), callback, failed)
  }

  /**
   * @description get headers
   * @return {JSON}
   * @private
   */
  _headers(type = 'application/json') {
    const data = {
      Accept: type,
      'Content-Type': type,
      'Accept-Language': i18n.locale(),
    }

    if (Bearer.getToken()) {
      data.Authorization = `Bearer ${Bearer.getToken()}`
    }

    return data
  }

  /**
   * @description set next call mocked
   * @param url
   */
  mock(url = '') {
    this._mocked_url = url
    mocker.start()
    mocker.mockNext()
    return this
  }
}

const Api = new _API({
  version: 1,
})
const Api2 = new _API({
  version: 2,
})

export { Api, Api2 }
