import { Socket } from '@/assets/js/core/Socket'
import PluginDataResponsePayload from '@/assets/js/interfaces/PluginDataResponsePayload'

class PluginService {
  constructor(private name: string) {
    this.name = name || this.name
  }

  /**
   * Check Plugin status
   * @param {function} done
   */
  status() {
    Socket.send('plugin status', {
      name: this.name,
      token: this.name,
    })
  }

  statusAll() {
    Socket.send('plugin statusAll', {
      token: this.name,
    })
  }

  /**
   * On Status request
   * @param {function} done
   */
  onStatus(done: Function, error: Function | null = null) {
    Socket.on('plugin', (_cmd: string, data: PluginDataResponsePayload) => {
      if (data.subcommand === 'status') {
        if (data.statuscode === 200 && data.result) {
          if (data.result.name === this.name) {
            done(data.result)
          }
        } else if (data.statuscode === 500) {
          if (data.plugintoken === this.name) {
            if (error) {
              error()
            }
            throw new Error(`error.${this.name}.status`)
          }
        }
      }
    })
  }

  enable(parameters = {}) {
    Socket.send('plugin enable', {
      name: this.name,
      parameters,
      token: this.name,
    })
  }

  update(parameters: any, enabled: boolean) {
    Socket.send('plugin update', {
      name: this.name,
      parameters,
      enabled,
      token: this.name,
    })
  }

  disable() {
    Socket.send('plugin disable', {
      name: this.name,
      token: this.name,
    })
  }

  delete() {
    Socket.send('plugin delete', {
      name: this.name,
      token: this.name,
    })
  }

  get(botId: number | string) {
    Socket.send('plugin run', {
      tag: `${this.name}SetupInfo`,
      token: this.name,
      args: { botId },
    })
  }

  onGet(next: Function) {
    Socket.on(
      'plugin',
      (_cmd: string, res: PluginDataResponsePayload) => {
        if (res.subcommand === 'run') {
          if (res.statuscode === 200) {
            if (res.plugintoken === this.name) {
              next(res.result)
            }
          }
        }
      },
      '',
      { callbackToRegister: next }
    )
  }

  onStatusAll(next: Function, back: Function | null = null) {
    Socket.on('plugin', (_cmd: string, data: PluginDataResponsePayload) => { 
      if (data.subcommand === 'statusAll') {
        if (data.statuscode === 200 && data.result) {
            next(data.result)
        } else if (data.statuscode === 500) {
          if (back) {
            back()
          }
          throw new Error(`error.${this.name}.status`)
        }
      }
    })
  }

  onEnable(next: Function, back: Function | null = null) {
    this.socketHandler('enable', next, back)
  }

  onUpdate(next: Function, back: Function | null = null) {
    this.socketHandler('update', next, back)
  }

  onDisable(next: Function, back: Function | null = null) {
    this.socketHandler('disable', next, back)
  }

  onDelete(next: Function, back: Function | null = null) {
    this.socketHandler('delete', next, back)
  }

  socketHandler(command: string, next: Function, back: Function | null = null) {
    Socket.on(
      'plugin',
      (_cmd: string, res: PluginDataResponsePayload) => {
        if (res.subcommand === command) {
          if (res.statuscode === 200) {
            if (res.plugintoken === this.name) {
              next(res.result)
            }
          } else {
            if (back) {
              back()
            }
            throw new Error(`error.${this.name}.${command}`)
          }
        }
      },
      '',
      { callbackToRegister: next }
    )
  }

  off(done: Function) {
    Socket.off('plugin', done)
  }
}

export default PluginService
