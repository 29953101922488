import WebSocketPublishEvent from '../decorators/WebSocketPublishEvent'
import WebSocketSubscribeEvent from '../decorators/WebSocketSubscribeEvent'
import { BaseService } from './BaseService'
import { Api } from '../core/Api'

interface StoreData {
  bot: string
  blob: Buffer
  name: string
  mime: string
}

interface RetrieveData {
  bot: string
  fileId: number
}

class _AttachFileService extends BaseService {
  getUploadUrl(data: any) {
    return Api.post(`bots/${data.botId}/docs/upload-url`, {
      reference: data.reference,
      fileType: data.fileType
    }, false)
  }

  @WebSocketPublishEvent('attach_file_to_chat')
  store(data: StoreData) {
    return data
  }

  @WebSocketSubscribeEvent('attach_file_to_chat', undefined, {
    data: 'data',
  })
  onStore(_cb: Function, _cbError?: Function) {}

  @WebSocketPublishEvent('attachments', 'retrieve')
  retrieve(data: RetrieveData) {
    return data
  }

  @WebSocketSubscribeEvent('attachments', 'retrieve', {
    data: 'data',
  })
  onRetrieve(_cb: Function, _cbError?: Function) {}
}

const AttachFileService = new _AttachFileService()

export default AttachFileService
