/* eslint-disable */

import { ucfirst } from '../../utils'
import env from '@/env'

const MockAdapter = require('axios-mock-adapter')

export class Mocker {
  /**
     * @description constructor
     * @param axios
     */
  constructor (axios) {
    this.adapter = null
    this.active = false
    this.oldAdapter = axios
  }

  /**
     * @description start mocking
     */
  start () {
    this.adapter = process.env.NUXT_ENV_USERBOT_API_ENDPOINT_MOCKED === 'true' ? new MockAdapter(this.oldAdapter, { delayResponse: 2000 }) : null
    return this
  }

  /**
     * @description mock next call
     */
  mockNext () {
    if (this.adapter) { this.active = true }
    return this
  }

  /**
     * @description check if mocker is active
     * @return {boolean}
     */
  isActive () {
    return this.active
  }

  /**
     * @description mock call
     * @param url
     */
  async mock (type, url, done) {
    if (this.adapter) {
      console.log('MOCKER: ', type, url, `@/assets/mocks/${type.toLowerCase()}/${url}.json`)
      // eslint-disable-next-line
      const data = await import(`@/assets/mocks/${type.toLowerCase()}/${url}.json`)
      this.adapter[`on${ucfirst(type)}`](url).reply((config) => {
        return [200, { data: data.default }]
      })
      return done({ data: data.default })
    }

    return Promise.resolve()
  }

  /**
     * @description reset the adapter
     * @return {Mocker}
     */
  reset () {
    if (this.active && this.adapter) {
      this.adapter.reset()
      this.active = false
    }
    return this
  }

  /**
     * @description restore adapter
     */
  restore () {
    if (this.active && this.adapter) {
      this.reset()
      this.adapter.restore()
      this.adapter.resetHandlers()
    }
    return this
  }
}
