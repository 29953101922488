import { Vue, Component, Inject } from 'nuxt-property-decorator'
import { Bot } from '@/assets/js/models/Bot'

@Component
export default class UWebChatSupport extends Vue {
  @Inject()
  bot: () => Bot

  mounted() {
    if (this.bot()) {
      this.bot().support({ opened: false })
    }
  }
}
