/* eslint-disable camelcase */
export { default as isEmail } from '@/assets/js/helpers/isEmail'
export { default as is_url } from '@/assets/js/helpers/isUrl'
import showdown from 'showdown'

/**
 * Return location object
 *
 * @param href
 * @return URL
 */
export function getLocation(href) {
  const location = document.createElement('a')
  location.href = href
  // IE doesn't populate all link properties when setting .href with a relative URL,
  // however .href will return an absolute URL which then can be used on itself
  // to populate these additional fields.
  if (location.host === '') {
    // eslint-disable-next-line no-self-assign
    location.href = location.href
  }
  return location
}

/**
 * Get hostname from url
 *
 * @param href
 * @return string
 */
export function getHostname(href) {
  return getLocation(href).hostname
}

/**
 * Return url path
 *
 * @param href
 * @return string
 */
export function getUrlPath(href) {
  return getLocation(href).pathname
}

/**
 * Get user picture
 * if user has not profile picture
 * i am going to use a default img getting last
 * number from user name
 *
 * @param user
 * @return string
 */
export function getUserPicture(user) {
  if (user.json_trace) {
    let userData = user.json_trace
    userData = userData.replace(/\\%20/g, '_')

    if (typeof userData === 'string') {
      userData = JSON.parse(userData)
    }

    if (userData.profile_pic) {
      return userData.profile_pic
    }
  }

  let imageLink = '/images/users/user0.png'

  const lastChar = user.user[user.user.length - 1]

  if (isNaN(lastChar) === false) {
    // è un numero
    imageLink = '/images/users/user' + lastChar + '.png'
  }

  return imageLink
}

/**
 * @description check if value is array
 * @param value
 * @return {boolean}
 */
export function is_array(value) {
  return Array.isArray(value)
}

/**
 * @description check if value is undefined
 * @param value
 * @returns {boolean}
 */
export function is_undefined(value) {
  return typeof value === 'undefined'
}

/**
 * @description check if value is a JSON
 * @param value
 * @return {boolean}
 */
export function is_object(value) {
  return (typeof value).toLowerCase() === 'object'
}

/**
 * @description capitalize first letter
 * @param string
 * @returns {string}
 */
export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * @description sanitize text and format it
 * @param text
 * @returns {*}
 */
export function sanitize(text) {  
  // TODO: move logic to the BE
  const converter = new showdown.Converter()
  converter.setOption('openLinksInNewWindow', true);
  return converter.makeHtml(text);
}

export function toFixedDown(number, digits) {
  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)')
  const m = number.toString().match(re)
  return m ? parseFloat(m[1]) : number.valueOf()
}
