import { Vue, Component } from 'nuxt-property-decorator'
import UProgressBar from '@/components/u-progress-bar/index.vue'
import { getIcon } from '@/assets/js/helpers/fileIcons'
import IconCsv from '@/components/u-select-file/icon-csv/index.vue'
import { ErrorType } from '@/components/containers/db-ai/db-ai'
import { CancelTokenSource } from 'axios'

@Component({
  components: {
    UProgressBar,
    IconCsv,
  },
})
export default class UDocumentsUploading extends Vue {
  get documents() {
    return this.$store.state.documents.documentsUploading
  }

  get collapsed() {
    return this.$store.state.documents.cardCollapsed
  }

  toggle() {
    this.$store.commit('documents/toggleCard')
  }

  stopUploading(token: CancelTokenSource) {    
    if (token) {
      token.cancel(ErrorType.STOP_BY_USER)
    }
  }

  getIcon = getIcon
}
