import { BaseType } from './BaseType'

export default class TextType extends BaseType {
  constructor() {
    super({
      label: 'strict',
      description: 'strict-description',
      icon: 'equals',
      type: 'STRICT',
    })
  }
}
