import { Vue, Component, Prop, Emit, Watch } from 'nuxt-property-decorator'
import Debounce from '@/assets/js/decorators/Debounce'

@Component
export default class USearchBar extends Vue {
  @Prop({ type: String, required: false, default: '' })
  placeholder: string

  @Prop({ type: Number, required: false, default: 3 })
  min: number

  @Prop({ type: Boolean, required: false, default: false })
  loading: boolean

  @Watch('loading', { immediate: true })
  onLoadingChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localLoading = val
    }
  }

  static emits = ['input', 'cancel']

  search: string = ''
  localLoading: boolean = false

  get sanitizedSearch(): string {
    return this.search.trim()
  }

  @Debounce(600)
  submit() {
    if (this.sanitizedSearch.length >= this.min) {
      this.$emit('submit', this.search)
    } else if (this.sanitizedSearch.length === 0) {
      this.$emit('submit', this.search)
    }
  }

  @Emit('update:modelValue')
  @Emit('cancel')
  cancel() {
    this.search = ''
    return ''
  }

  onSearchStringChange(val: string) {
    const oldSearch = this.search
    this.search = val
    if (val?.trim() || (oldSearch?.trim() && !val?.trim())) {
      this.$emit('update:modelValue', val)
    }
  }
}
