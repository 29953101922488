/**
 * Trim a string and add ellipsis
 * @param string
 * @param maxLength
 * @return {string}
 */
export default function truncate(string: string, maxLength: number) {
  if (string.length > maxLength) {
    const regex = string.trim().substr(0, maxLength)
    return regex + ' ...'
  }
  return string
}
