import { render, staticRenderFns } from "./u-navbar-menu-category.html?vue&type=template&id=18e077a5&scoped=true&external"
import script from "./u-navbar-menu-category.js?vue&type=script&lang=js&external"
export * from "./u-navbar-menu-category.js?vue&type=script&lang=js&external"
import style0 from "./u-navbar-menu-category.scss?vue&type=style&index=0&id=18e077a5&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e077a5",
  null
  
)

export default component.exports