import { BaseType } from './BaseType'

export default class TagType extends BaseType {
  constructor() {
    super({
      label: 'contain',
      description: 'tag-description',
      icon: 'box',
      type: 'AND',
    })
  }
}
