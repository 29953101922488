/**
 * Manage visibility of top TRIAL BANNER with X trial days remaining
 * must be hidden whem upgrade is performed
 */
export default {
  state: () => ({
    status: false,
  }),
  mutations: {
    show(state: any) {
      state.status = true
    },
    hide(state: any) {
      state.status = false
    },
  },
  actions: {
    setStatus({ commit }: { commit: Function }, value: boolean) {
      if (value) {
        commit('show', value)
        // document.body.classList.add('body--trial-banner')
      } else {
        commit('hide', value)
        // document.body.classList.remove('body--trial-banner')
      }
    },
  },
}
