import { Vue, Component, Emit, Prop, Ref, Watch } from 'nuxt-property-decorator'
import { createPopper } from '@popperjs/core'
import type { Placement } from '@popperjs/core'

@Component
export default class UDropdown extends Vue {
  @Ref()
  readonly button: Vue

  @Prop({ type: Boolean, required: false, default: false })
  modelValue: boolean

  @Prop({ type: String, required: false, default: 'bottom-start' })
  placement: Placement

  @Prop({ type: Number, required: false, default: 10 })
  offset: number

  @Prop({ type: Boolean, required: false, default: false })
  block: boolean

  @Prop({ type: Boolean, required: false, default: false })
  closeOnClick: boolean

  @Watch('modelValue', { immediate: true })
  async onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localValue = val
      await this.initPopper()
    }
  }

  static emits = ['updatePlacement']

  popperInstance: any
  localValue = false
  calculatePositionComplete = false
  tooltip: any = null
  dropdownMenu: Element[] | null = null

  async mounted() {
    await this.$nextTick()
    await this.initPopper()
    if (!this.closeOnClick) {
      this.dropdownMenu = [
        ...Array.from(this.$el?.querySelectorAll?.('.dropdown-menu') ?? []),
      ]
    }
  }

  async show() {
    if (this.tooltip) {
      if (this.popperInstance) {
        await this.popperInstance.update()
      }
      this.onUpdatePlacement(this.getTooltipPlacement())
    }
    await this.animate()
  }

  @Emit('updatePlacement')
  private onUpdatePlacement(e: string) {
    return e
  }

  private async animate() {
    await this.$nextTick()
    await this.popperInstance.update()
  }

  private getTweenLiteData() {
    const style = window.getComputedStyle(this.tooltip)
    const matrix = new WebKitCSSMatrix(style.transform)
    const dataFrom: {
      y?: number
      rotationX?: number
      rotationY?: number
      x?: number
    } = {}
    const dataTo: {
      y?: number
      rotationX?: number
      rotationY?: number
      x?: number
    } = {}

    let top
    let left

    if (this.getTooltipPlacement().includes('top')) {
      top = matrix.m42
      dataFrom.y = top - 60
      dataFrom.rotationX = -100
      dataTo.y = top
      dataTo.rotationX = 0
    } else if (this.getTooltipPlacement().includes('bottom')) {
      top = Math.abs(matrix.m42)
      dataFrom.y = top - 60
      dataFrom.rotationX = -100
      dataTo.y = top
      dataTo.rotationX = 0
    } else if (this.getTooltipPlacement().includes('left')) {
      left = Math.abs(matrix.m41)
      dataFrom.x = -left - 40
      dataFrom.rotationY = -100
      dataTo.x = -left + 40
      dataTo.rotationY = 0
    } else {
      left = Math.abs(matrix.m41)
      dataFrom.x = left + 40
      dataFrom.rotationY = -100
      dataTo.x = left - 40
      dataTo.rotationY = 0
    }

    return {
      from: dataFrom,
      to: dataTo,
    }
  }

  private getTransformOrigin() {
    const placement = this.getTooltipPlacement()
    if (placement.includes('top')) {
      return 'bottom'
    } else if (placement.includes('bottom')) {
      return 'top'
    } else if (placement.includes('left')) {
      return 'right'
    } else {
      return 'left'
    }
  }

  private getTooltipPlacement(): string {
    if (this.tooltip) {
      return (
        this.tooltip.getAttribute('data-popper-placement') || this.placement
      )
    }
    return this.placement
  }

  private async onInput(val: boolean) {
    // if (this.localValue !== val) {
    this.localValue = val
    await this.$nextTick()
    await this.show()
    this.$emit('update:modelValue', val)
    // }
  }

  private async initPopper() {
    if (this.button) {
      const button = this.button.$el
      this.tooltip = this.$el?.querySelector?.('.dropdown-menu')

      if (this.tooltip) {
        this.popperInstance = createPopper(button, this.tooltip, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, this.offset],
              },
            },
          ],
        })
        if (this.localValue) {
          await this.animate()
        }
      }
    }
  }
}
