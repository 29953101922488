import GenericVar from './GenericVar'

export default class GenericUserVar extends GenericVar {
  /**
   * @description retrieve value
   */
  retrieve(fn) {
    if (fn) this.on(fn)
    this.socket.send(`retrieve-user-option ${this.name}`)
  }

  /**
   * @description send store event
   * @param value
   */
  store(value) {
    this.socket.send(`store-user-option ${this.name} ${value}`)
  }

  /**
   * @description listen functions
   */
  listen(fn) {
    this.socket.on('retrieve-user-option', (cmd, data) => {
      if (data.text.split(' ').includes(this.name)) {
        this.callees.forEach((fn) =>
          fn(data.data, data.data ? data.data.optionValue : null)
        )
        return fn ? fn() : null
      }
    })
  }
}
