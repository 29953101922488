import { Vue, Component } from 'vue-property-decorator'
import AttachFileService from '@/assets/js/services/AttachFileService'

@Component
export default class RetrieveFile extends Vue {
  mounted() {
    AttachFileService.onRetrieve(this.onRetrieve)
  }

  beforeDestroy() {
    AttachFileService.off(this.onRetrieve)
  }

  onRetrieve(result: {
    filename: string
    botDocId: number
    buffer: { data: Buffer; type: string }
  }) {
    this.$store.commit('file/removeDownloadingFile', result.botDocId)
    const FileData = Uint8Array.from(result.buffer.data)
    const content = new Blob([FileData.buffer])

    const encodedUri = window.URL.createObjectURL(content)
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', result.filename)

    link.click()
  }
}
