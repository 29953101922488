import { Popover } from 'uiv'
import Key from '@/assets/js/core/Key'
import { hasPermission } from '@/modules/auth'
import Analytics from '@/assets/js/core/Analytics'

export default {
  name: 'u-popover-upgrade-plan',
  components: {
    Popover,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    align: {
      type: String,
      required: false,
      default: '',
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    top: {
      type: Boolean,
      required: false,
      default: false,
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      required: false,
    },
    analyticsLabel: {
      type: String,
      required: false,
      default: '',
    },
    generic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      show: false,
      hover: false,
    }
  },
  watch: {
    show(val, oldVal) {
      if (val !== oldVal && val) {
        Analytics.event(
          'PUP',
          'Hover',
          `${this.analyticsLabel ? this.analyticsLabel : this.title}`
        )
      }
    },
  },
  computed: {
    placement() {
      if (this.right) return 'right'
      if (this.left) return 'left'
      if (this.top) return 'top'
      if (this.bottom) return 'bottom'
    },
  },
  mounted() {
    if (this.target) {
      this.target.$el.addEventListener(
        'mouseenter',
        this.handleTargetMouseEnter
      )
      this.target.$el.addEventListener(
        'mouseleave',
        this.handleTargetMouseLeave
      )
      document.body.addEventListener('click', this.handleTargetMouseClick)
      document.body.addEventListener('keyup', this.handleEscKey)
    }
  },
  destroyed() {
    if (this.target) {
      this.target.$el.removeEventListener(
        'mouseenter',
        this.handleTargetMouseEnter
      )
      this.target.$el.removeEventListener(
        'mouseleave',
        this.handleTargetMouseLeave
      )
      document.body.removeEventListener('click', this.handleTargetMouseClick)
      document.body.removeEventListener('keyup', this.handleEscKey)
    }
  },
  methods: {
    emitClick(e) {
      if (this.generic) {
        this.show = false
        e.preventDefault()
        this.$store?.commit('page/setMUPStripe', true)
      } else {
        this.show = false
        this.$emit('click', e)
      }
    },
    onMouseLeave() {
      this.show = false
    },
    hasPermission,
    handleTargetMouseEnter() {
      this.timeout = setTimeout(() => {
        this.show = true
        this.hoverTarget = true
      }, 300)
    },
    handleTargetMouseLeave() {
      this.hoverTarget = false
      if (this.timeout) clearTimeout(this.timeout)
    },
    handleTargetMouseClick() {
      if (!this.hoverTarget) {
        this.show = false
      }
    },
    handleEscKey(e) {
      if (Key.isEsc(e)) {
        this.show = false
      }
    },
  },
}
