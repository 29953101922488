import { render, staticRenderFns } from "./u-navbar-menu-element.html?vue&type=template&id=92b1bea6&scoped=true&external"
import script from "./u-navbar-menu-element.ts?vue&type=script&lang=ts&external"
export * from "./u-navbar-menu-element.ts?vue&type=script&lang=ts&external"
import style0 from "./u-navbar-menu-element.scss?vue&type=style&index=0&id=92b1bea6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92b1bea6",
  null
  
)

export default component.exports