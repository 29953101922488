import Key from '@/assets/js/core/Key'
import MentionMixin from '@/assets/js/mixins/mention'

export default {
  name: 'badge',
  components: {},
  mixins: [MentionMixin],
  props: [
    'reversed',
    'color',
    'truncate',
    'input',
    'placeholder',
    'minWidth',
    'removable',
    'selectable',
    'preSelected',
    'addOnBlur',
    'addOnSpace',
    'preSelected',
    'variant',
    'disabled',
  ],
  emits: [
    'add',
    'blur',
    'focus',
    'keydown',
    'keyup',
    'remove',
    'selected',
    'toggle',
    'writing',
  ],
  data() {
    return {
      selected: false,

      name: '',

      /**
       * @description contain setTimeout writing action
       */
      writingTimeout: null,

      focused: false,

      /**
       * @description disabled status
       */
      disabledStatus: false,
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler(val) {
        this.disabledStatus = val
      },
    },
    preSelected(val) {
      this.selected = val
    },
  },
  created() {
    document.addEventListener('keyup', this.keyUpHandler)
  },
  destroy() {
    document.removeEventListener('keyup', this.keyUpHandler)
  },
  mounted() {
    this.selected = !!this.preSelected
    if (this.activeMention) {
      const ele = this.$refs.input
      if (ele) {
        this.attachMention(ele)
      }
    }
  },
  methods: {
    /**
     * @description toggle select
     */
    toggle() {
      if (this.selectable) {
        this.selected = !this.selected
        this.$emit('selected', true)
      }
      this.$emit('toggle', this.selected)
    },

    /**
     * @description deselect
     */
    deselect() {
      this.selected = false
      this.name = ''
    },

    /**
     * @description remove element
     */
    remove(event) {
      if (this.disabledStatus) return
      this.$emit('remove', true)
      if (event) {
        event.stopPropagation()
        event.preventDefault()
      }
    },

    /**
     * @description empty input
     */
    empty() {
      this.name = ''
    },

    /**
     * @description check writing action
     * @param event
     */
    writing(event) {
      if (this.writingTimeout) clearTimeout(this.writingTimeout)

      // Send element written
      if (Key.isEnter(event) || Key.isCode(event, 188)) {
        event.preventDefault()
        this.add()
      }
      // exit from input
      else if (Key.isEsc(event)) {
        this.$emit('writing', false)
      } else if (Key.isArrow(event)) {
      } else {
        this.$emit('writing', this.name)
      }
    },

    /**
     * @description add a new group
     */
    add() {
      if (this.name.trim().length) {
        this.$emit('add', { name: this.name })
        this.$emit('writing', false)
        this.name = ''
      }
    },

    // INPUT
    /**
     * @description focus to input
     * @deprecated
     */
    focusToInput() {
      this.focus()
    },

    /**
     * @description focus to input
     */
    focus() {
      this.focused = true
      this.$refs.input.focus()
      /* this.$emit('focus', new FocusEvent(this.$refs.input)); */
    },

    /**
     * @description focus input
     */
    focusInput(e) {
      this.focused = true
      this.$emit('focus', e)
    },

    /**
     * @description blur input
     */
    blurInput(e) {
      if (this.disabledStatus) return
      this.focused = false
      this.$emit('blur', e, this.name)
      if (this.name.trim() && this.addOnBlur) this.add()
    },

    /**
     * @description keydown event
     * @param e
     */
    keydownInput(e) {
      if (this.disabledStatus) return
      if (Key.isCode(e, 188)) e.preventDefault()
      if (Key.isSpaceBar(e) && this.addOnSpace) {
        const splitted = this.name.split(' ')
        splitted.forEach((string) => {
          this.name = string
          this.add()
        })
      }
      this.$emit('keydown', e, this.name)
    },

    /**
     * @description keyup input event
     * @param e
     */
    keyupInput(e) {
      if (this.disabledStatus) return
      this.writing(e)
      this.$emit('keyup', e, this.name)
    },

    /**
     * @description get style to apply to content
     * @returns {{"background-color": string, "border-color": *}}
     */
    getStyleBadge() {
      return {
        'background-color': this.color && this.selected ? this.color : '',
        'border-color': this.color,
        color: this.color && this.selected ? '#FFF' : '',
      }
    },

    /**
     * @description keyup handler
     * @param e
     */
    keyUpHandler(e) {
      if (Key.isEsc(e)) {
        this.$emit('writing', false)
        this.name = ''
      }
    },
  },
}
