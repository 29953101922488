/* eslint-disable no-unreachable-loop */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import getImageUrl from '@/assets/js/helpers/getImageUrl'
import getImageRegex from '@/assets/js/helpers/getImageRegex'
import getVideoRegex from '@/assets/js/helpers/getVideoRegex'
import convertImageUrlToEmoji from '@/assets/js/helpers/convertImageUrlToEmoji'
export { default as truncate } from '@/assets/js/helpers/truncate'

declare global {
  interface Window {
    typeformEmbed: any
  }
}

const freeCommonEmails = require('email-providers/all.json')
const MD5 = require('md5')
let TYPEFORM_SCRIPT = false

/**
 * @description get Gravatar
 * @param email
 * @param params
 * @return {string}
 */
type GravatarParams = {
  size?: number
  default?: string
}
export function gravatar(email: string, params: GravatarParams = {}) {
  if (typeof params !== 'object') {
    params = {}
  }

  const url = `https://www.gravatar.com/avatar/${MD5(email)}.jpg`
  const urlParams = []

  urlParams.push(`s=${params.size ? params.size : 80}`)

  if (params.default) {
    // let defaultUrl = `${window.location.protocol}//${window.location.host}/${params.default}`;
    const defaultUrl = `https://cdn.userbot.ai/${params.default}`
    urlParams.push(`d=${encodeURIComponent(defaultUrl)}`)
  }

  return urlParams.length ? `${url}?${urlParams.join('&')}` : url
}

/**
 * @description get a query param
 * @param name
 * @param url
 * @returns {string}
 */
export function get_query_param(name: string, url = '') {
  if (!url) url = location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return ''
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * @description extract json from string
 * @param string
 * @return {*}
 */
export function extract_json(string: string) {
  if (!string) {
    return ''
  }
  string = string
    .toString()
    .replace(/”/gi, '\\"')
    .replace('“', '\\"')
    .replace(/”/gi, '\\"')
  let firstOpen: number = 0
  let firstClose: number
  let candidate
  do {
    firstClose = string.lastIndexOf('}')
    firstOpen = string.indexOf('{', firstOpen + 1)
    do {
      candidate = string.substring(firstOpen, firstClose + 1)
      firstClose = string.substring(0, firstClose).lastIndexOf('}')
      try {
        return JSON.parse(candidate)
      } catch (e) {
        return false
      }
    } while (firstClose > firstOpen)
  } while (firstOpen !== -1)
}

/**
 * @description remove json from string
 * @param string
 * @return string
 */
export function remove_json(string: string) {
  if (typeof string !== 'string') {
    return ''
  }
  const firstOpen = string.indexOf('{')
  const lastClose = string.lastIndexOf('}') + 1
  return string.replace(string.substring(firstOpen, lastClose), '').trim()
}

/**
 * @description get outer width of a element
 * @param element
 * @returns {number}
 */
export function outer_width(element: HTMLElement) {
  const style = getComputedStyle(element)
  const width = element.offsetWidth // or use style.width
  const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight)
  const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
  const border =
    parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth)

  return width + margin + padding + border
}

/**
 * @description is scrolled into view
 * @param element
 * @return {boolean}
 */
export function isInViewport(element: HTMLElement) {
  if (!element || !element.getBoundingClientRect) return false
  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * @description
 * @param el
 * @param offset
 */
export function isTopAfterScrollTop(el: HTMLElement, offset = 0) {
  if (!el.getBoundingClientRect) return false
  const bounding = el.getBoundingClientRect()
  return bounding.top + offset < 0
}

/**
 * @description get script
 * @param source
 * @param callback
 */
export function getScript(source: string, callback = () => {}) {
  if (process.client) {
    const scripts = Array.from(document.getElementsByTagName('script'))
    const exist = scripts.some((item) => item.src === source)

    if (!exist) {
      const script: HTMLScriptElement | undefined =
        document.createElement('script')
      const prior = document.getElementsByTagName('script')[0]
      script.async = true
      script.addEventListener('load', callback)
      script.src = source
      prior.parentNode?.insertBefore(script, prior)
    } else if (callback) {
      callback()
    }
  }
}

/**
 * @description get currency symbol
 * @param currencyString
 * @return {string}
 */
export function currency(currencyString: string) {
  const string = String(currencyString)
  switch (string.toLowerCase()) {
    case 'eur':
      return '\u20AC'

    default:
      return string.toUpperCase()
  }
}

/**
 * @description capitalize first letter
 * @param string
 * @returns {string}
 */
export function ucfirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * @description check if user agent is mobile
 * @returns {boolean}
 */
export function is_mobile() {
  if (process.client) {
    let check = false
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substring(0, 4)
        )
      )
        check = true
    })(navigator.userAgent)
    return check
  }
  return false
}

export function is_mobile_or_tablet() {
  if (process.client) {
    let check = false
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor)
    return check
  }
  return false
}

/**
 * @description check if param is function
 * @param functionToCheck
 * @return {*|boolean}
 */
export function is_function(functionToCheck: unknown) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  )
}

/**
 * @description trim text without cutting words
 * @param string
 * @param maxLength
 * @return {string}
 */
export function trim(string: string, maxLength: number) {
  if (string.length > maxLength) {
    const regex = new RegExp(`^([\\s\\S]{${maxLength}}\\S*)[\\s\\S]*`)
    return string.replace(regex, '$1') + ' ...'
  }
  return string
}

/**
 * @description download file from array bytes
 * @param bytes
 * @param filename
 * @param type
 * @return {Function}
 */
export function download_bufferArray(
  bytes: Iterable<number>,
  filename: string,
  type: string
) {
  const int8Array = new Uint8Array(bytes)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')
  const blob = new Blob([int8Array], { type: `${type};charset=utf-8` })
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

/*
 * @description media link to emoji
 * @param text
 */
export function media_links_to_emoji(text: string) {
  return convertImageUrlToEmoji(text)
}

/**
 * @default remove media link from text
 * @param text
 * @return {*}
 */
export function remove_media_links(text: string) {
  return String(text)
    .replaceAll(getImageRegex('all'), '')
    .replaceAll(getVideoRegex('all'), '')
}

/**
 * @default export match media links
 * @param text
 * @return {*}
 */
export function extract_media_links(text: string) {
  return getImageUrl(text)
}

/**
 * @description typeform init
 */
export function typeform_init() {
  return new Promise((resolve) => {
    if (TYPEFORM_SCRIPT === false) {
      getScript('https://embed.typeform.com/embed.js', () => {
        TYPEFORM_SCRIPT = true
        return resolve(true)
      })
    } else {
      return resolve(true)
    }
  })
}

export function typeform_popup(url: string) {
  const completeUrl = `${url}${window.location.search}${window.location.hash}`
  return window.typeformEmbed.makePopup(completeUrl, {
    mode: 'drawer_right',
    hideHeaders: true,
    hideFooter: true,
  })
}

/**
 * Get icon app from url
 * @param {string} url
 */
export function get_url_app_icon(url = '') {
  const matches = url.match(/^[a-zA-Z]+:/)
  if (matches && matches.length) {
    const app = matches[0].slice(0, -1)

    switch (app) {
      case 'mailto':
        return 'email'

      case 'https':
      case 'http':
        return 'link'

      case 'fb':
        return 'facebook'

      case 'tel':
      case 'callto':
        return 'phone'

      case 'whatsapp':
      case 'skype':
      case 'twitter':
      case 'pinterest':
      case 'instagram':
      case 'yelp':
      case 'linkedin':
        return app
    }
  }
  return 'link'
}

/**
 * Check if string is a valid URL SCHEME
 * @param {string} url
 */
export function is_url_scheme(url: string) {
  const matches = url.match(/^[a-zA-Z]+:/)
  return matches && matches.length
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(object: Object, base: Object) {
  function changes(object: Object, base: Object) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

/**
 * Check if is a business email (not hotmail, gmail, etc)
 * @param {string} email
 */
export function isBusinessEmail(email: string) {
  if (email) {
    try {
      const domain = email.split('@')[1]
      return !freeCommonEmails.includes(domain)
    } catch (e) {
      return false
    }
  }
  return false
}

export function langs() {
  return [
    { label: 'English', value: 'en' },
    { label: 'Italiano', value: 'it' },
    { label: 'Français', value: 'fr' },
    { label: 'Español', value: 'es' },
    { label: 'Românesc', value: 'ro' },
  ]
}

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}
