/* eslint-disable camelcase */
/* global WebSocket */
import uniqueId from 'lodash/uniqueId'
import { is_array, is_object } from '../utils'
import { Router } from './Router'

const router = new Router()

let socketInstantiated = false

type ListenerEvent = {
  callback: Function
  alias: string
  callbackToRegister: Function | null
}

class WebSocketObj {
  private _calls: {
    [key: string]: ListenerEvent[]
  } = {
    connected: [],
  }

  private _socketError = false
  private XHRdisconnected = false
  private commandsNotSent: any[] = []
  private socket: WebSocket | null = null
  private isFreezed = false
  private queue: {string: string, data: any}[] = []

  getServerUrl() {
    const server = process.env.NUXT_ENV_USERBOT_AI_SERVER ?? ''
    return `${server}?token=${localStorage?.getItem('auth._token.local')}`
  }

  /**
   * @description connect socket
   */
  connect(botId: string = '') {
    try {
      this.socket = new WebSocket(this.getServerUrl())
      this.bindEvents(botId)
    } catch (e) {
      console.error(e)
      this.bounced()
    }
    return this
  }

  /**
   * @description bind events socket
   */
  bindEvents(botId: string = '') {
    if (this.socket) {
      this.socket.onopen = function () {}

      this.socket.onmessage = (evt) => {
        this._socketError = false
        let message
        try {
          message = JSON.parse(evt.data)
          console.log(message)
          this.call(message.command, message)
        } catch (e) {
          // handle error
          console.log(e)
        }
      }

      this.socket.onerror = () => {
        this._socketError = true
      }

      this.socket.onclose = (evt) => {
        this._socketError = true
        this.bounced(evt)
      }
    }

    /**
     * @description
     */
    if (!socketInstantiated) {
      this.on('banner', () => {
        this.send('sitekey undefined ' + botId)
      })

      this.on('domain', () => {
        this.unfreeze()
        this.sendAppendedCommands()
      })

      this.on('security-error', () => {
        // TODO: send to a 404 page
        router.navigate(['/bots'])
      })

      this.on('bounced', (_: string, data: any) => {
        this.bounced(data)
      })

      window.addEventListener('offline', this.disconnected)

      window.addEventListener('online', this.reconnected)

      socketInstantiated = true
    }
  }

  /**
   * @description set Socket connected
   * @return {Socket}
   */
  start(msg: any) {
    this.call('connected', msg)
    return this
  }

  /**
   * @description call function on Name
   * @param name
   * @param msg
   */
  call(name: string, msg: unknown = null) {
    if (this._calls[name]) {
      this._calls[name].forEach((func: Function | { callback: Function }) => {
        if (typeof func !== 'function') {
          func.callback.call(this, name, msg)
        } else {
          func.call(this, name, msg)
        }
      })
    }
    return this
  }

  /**
   * @description send name, value to socket
   * @param name
   * @param value
   */
  send(name: string, value: unknown = null) {
    if (this.socket) {
      if (this.isFreezed && name !== 'domain') {
        this.addToQueue(name, value)
      } else {
        const data =
          (is_array(value) || is_object(value)) && value !== null
            ? JSON.stringify(value)
            : value
  
        try {
          const stringCommand = `${name} ${!!data || data === 0 ? data : ''}`.trim()
          if (this._socketError) {
            this.commandsNotSent.push(stringCommand)
          }
          this.socket.send(stringCommand)
          return true
        } catch (e) {
          return false
        }
      }
    }
    return false
  }

  /**
   * TODO: Refactor alias param
   * @description on socket message "name" call callback
   * @param name
   * @param callback
   * @param alias
   * @params { callbackToRegister } is used for WebSocketPublishEvent and WebSocketSubscribeEvent decorators
   * @return {Socket}
   */
  on(
    name: string | string[],
    callback: (command: string, data: any) => void,
    alias: string = '',
    { callbackToRegister }: { callbackToRegister: null | Function } = {
      callbackToRegister: null,
    }
  ) {
    const array = typeof name === 'string' ? name.split(',') : name
    array.forEach((item) => {
      let aliasSingleCmd = alias
      const name = item.trim()
      if (!alias) {
        aliasSingleCmd = uniqueId(item.trim())
      }
      if (!this._calls[name]) {
        this._calls[name] = []
      }
      this._calls[name].push({
        callback,
        alias: aliasSingleCmd,
        callbackToRegister,
      })
    })
    return this
  }

  off(name: string, callback: Function) {
    const array = typeof name === 'string' ? name.split(',') : name
    array.forEach((item) => {
      const name = item.trim()
      if (this._calls[name]) {
        const index = this._calls[name].findIndex(
          (
            fn:
              | Function
              | { callback: Function; callbackToRegister: Function | null }
          ) => {
            if (typeof fn !== 'function' && fn.callbackToRegister) {
              return fn.callbackToRegister.toString() === callback.toString()
            } else if (typeof fn === 'function') {
              return fn.toString() === callback.toString()
            } else {
              return fn.callback.toString() === callback.toString()
            }
          }
        )

        if (index >= 0) {
          this._calls[name].splice(index, 1)
        }
      }
    })
    return this
  }

  /**
   * @description bounced socket
   * @param data
   */
  bounced(data?: any) {
    if (data?.cli) {
      this.commandsNotSent.push(data!.cli.join(' '))
    }
    if (process.client) {
      window.removeEventListener('offline', this.disconnected)
      window.removeEventListener('online', this.reconnected)
    }
    this.socket?.close()
    setTimeout(() => {
      this.connect()
    }, 5 * 1000)
  }

  /**
   * @description send appended commands
   */
  sendAppendedCommands() {
    if (this.commandsNotSent.length) {
      this.commandsNotSent.forEach((cmd) => this.send(cmd))
      this.commandsNotSent = []
    }
    this.callQueue()
    return this
  }

  /**
   * @description check if is connected
   */
  disconnected() {
    this.call('disconnect')
    this.XHRdisconnected = true
  }

  /**
   * @description check if is connected
   */
  reconnected() {
    if (this.XHRdisconnected) {
      this.call('reconnect')
      this.XHRdisconnected = false
    }
  }

  freeze () {
    this.isFreezed = true
    return this
  }

  unfreeze () {
    this.isFreezed = false
    return this
  }

  addToQueue (string: string, data: any) {
    this.queue.push({
      string: string,
      data: data
    })
  }

  callQueue () {
    if (this.queue.length) {
      this.queue.forEach((item) => {
        this.send(item.string, item.data)
      })
      this.queue = []
    }
  }
}

export const Socket = new WebSocketObj()
