<template>
  <div ref="container" :style="style" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    /* eslint-disable vue/require-prop-types */
    /* eslint-disable vue/require-default-prop */
    source: {
      required: true,
    },
    height: Number,
    width: Number,
    loop: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['created'],
  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
    }
  },

  mounted() {
    this.lottie = lottie.loadAnimation({
      container: this.$refs.container,
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoplay,
      animationData: this.source,
    })

    this.$emit('created', this.lottie)
  },

  methods: {
    play() {
      this.lottie.play()
    },
    stop() {
      this.lottie.stop()
    },
  },
}
</script>
