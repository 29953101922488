import { render, staticRenderFns } from "./u-button-dropdown.vue?vue&type=template&id=669248cd&scoped=true"
import script from "./u-button-dropdown.vue?vue&type=script&setup=true&lang=ts"
export * from "./u-button-dropdown.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./u-button-dropdown.scss?vue&type=style&index=0&id=669248cd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "669248cd",
  null
  
)

export default component.exports