import VariableBackendData from '../interfaces/VariableBackendData'

export default class Variable {
  name: string
  type: string
  uuid: string

  /**
   * @constructor
   * @param attrs
   */
  constructor({ name, type, uuid }: VariableBackendData) {
    // this.name = name.toLowerCase().replace(/[^\w\s]|_/gi, '')
    this.name = name.toLowerCase().replace(/[^\w\s]/gi, '')
    this.type = type
    this.uuid = uuid
  }

  /**
   * @description get original name variable
   * @return {string}
   */
  getName() {
    return this.name
  }

  setName(value: string) {
    this.name = value
    return this
  }

  setUUID(value: string) {
    this.uuid = value
    return this
  }

  getTag(): string {
    return `@${this.getName()}`
  }
}
