import { Component, Inject, Prop, mixins } from 'nuxt-property-decorator'
import { Popover } from 'uiv'
import {
  BotAuthMixin,
  UserAuthMixin,
  hasPermission,
  logout,
} from '@/modules/auth'
import { Socket } from '@/assets/js/core/Socket'
import UUserPicture from '@/components/u-user-picture/index.vue'
import StripeService from '@/assets/js/services/StripeService'
import Analytics from '@/assets/js/core/Analytics'

@Component({
  components: {
    UUserPicture,
    Popover,
  },
})
export default class AvatarMenuComponent extends mixins(
  UserAuthMixin,
  BotAuthMixin
) {
  @Inject()
  chatRates: any

  @Prop({ type: Boolean, required: false, default: false })
  initials: boolean

  @Prop({ type: String, required: false, default: '' })
  cmd: string

  /**
   * @description dropdown status
   */
  dropdownStatus: boolean = false

  /**
   * @description context for onboarding
   */
  context = this

  size: number = 50

  get navbarStatus() {
    return this.$store.state.navbar.status
  }

  get botLimit() {
    return this.$store.state.bots.limit
  }

  get subscription() {
    return this.$store?.state.authentication.subscription
  }

  get tooltipText() {
    return `
      <ul class="tooltip-plan-data">
        <li>Bot <span>
        ${
          this.botLimit && this.botLimit < 9999
            ? this.botLimit
            : this.$t('unlimited')
        }
        </span></li>
        <li>Chat/ora <span>${
          this.chatRates().sessions?.limit &&
          this.chatRates().sessions?.limit < 9999
            ? this.chatRates().sessions?.limit
            : this.$t('unlimited')
        }</span></li>
        <li>N Agenti <span>
        ${
          this.chatRates().customer?.agentsLimit &&
          this.chatRates().customer?.agentsLimit < 90
            ? this.chatRates().customer?.activeAgents +
              '/' +
              this.chatRates().customer?.agentsLimit
            : this.$t('unlimited')
        }</span></li>
      </ul>
    `
  }

  get planName() {
    return this.chatRates().customer?.plan
  }

  /**
   * @description logout from chats
   */
  logout() {
    Analytics.event('Logout', 'Click', 'Out')
    if (!this.user) return
    this.$emit('logout', true)
    if (this.cmd) {
      Socket.send(`robin ${this.cmd} ${this.user?.get('id')} offline`)
    }
    location.href = this.localePath('/logout/')
  }

  /**
   * @description check if is op
   * @returns {boolean}
   */
  isOp(): boolean {
    return this.cmd === 'op'
  }

  hasPermission = hasPermission

  /**
   * @description handle mouse leave dropdown
   */
  handleMouseLeaveDropdown() {
    this.dropdownStatus = false
  }

  async goToStripeCustomerPortal() {
    try {
      const url = await StripeService.getCustomerPortalUrl()
      if (url) {
        location.href = url
      } else {
        throw new Error('Customer portal url missing')
      }
    } catch (e) {
      this.$notice.alert(this.$t('cannot-show-plan-info'))
    }
  }
}
