class Key {
  /**
   * @description check if is code
   * @param event
   * @param code
   * @returns {boolean}
   */
  isCode(event, code) {
    return event.keyCode === code || event.which === code
  }

  isKey(event, key) {
    return event.key.toLowerCase() === key.toLowerCase()
  }

  /**
   * @description check if is esc
   * @param event
   * @returns {boolean}
   */
  isEsc(event) {
    return event.keyCode === 27
  }

  /**
   * @description check if is Enter key pressed
   * @param event
   * @returns {boolean}
   */
  isEnter(event) {
    return event.keyCode === 13
  }

  /**
   * @description check if key pressed is spacebar
   * @param event
   * @return {boolean}
   */
  isSpaceBar(event) {
    return event.code === 'Space' || event.keyCode === 32
  }

  /**
   * @description is right click mouse
   * @param event
   * @returns {boolean}
   */
  isRightClick(event) {
    return event.button === 2
  }

  /**
   * @description check if key pressed is backspace
   * @param event
   */
  isADeleteKey(event) {
    const key = event.keyCode || event.charCode
    return key === 8 || key === 46
  }

  /**
   * @description check if is UP arrow key
   * @param event
   * @returns {boolean}
   */
  isUpArrow(event) {
    return event.keyCode === 38
  }

  /**
   * @description check if is DOWN arrow key
   * @param event
   * @returns {boolean}
   */
  isDownArrow(event) {
    return event.keyCode === 40
  }

  /**
   * @description check if is DOWN arrow key
   * @param event
   * @returns {boolean}
   */
  isLeftArrow(event) {
    return event.keyCode === 37
  }

  /**
   * @description check if is DOWN arrow key
   * @param event
   * @returns {boolean}
   */
  isRightArrow(event) {
    return event.keyCode === 39
  }

  /**
   * @description check if is arrow
   * @param event
   * @returns {boolean}
   */
  isArrow(event) {
    return (
      this.isRightArrow(event) ||
      this.isDownArrow(event) ||
      this.isUpArrow(event) ||
      this.isLeftArrow(event)
    )
  }

  /**
   * @description check if is A letter, a number or a space
   * @param event
   * @returns {*|boolean}
   */
  isTextable(event) {
    const inp = String.fromCharCode(event.keyCode)
    return /[a-zA-Z0-9-_ ]/.test(inp)
  }

  /**
   *
   * @param event
   * @returns {*}
   */
  isShift(event) {
    return event.shiftKey
  }

  /**
   * @param event
   * @returns {boolean}
   */
  isCtrl(event) {
    return event.ctrlKey
  }
}

export default new Key()
