import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

export class Base {
  originals: any
  attributes: any
  errors: never[]
  dirty: boolean

  constructor(attributes: any) {
    if (attributes) {
      delete attributes.attributes
      delete attributes.originals
    }
    this.originals = cloneDeep(attributes || {})
    this.attributes = attributes || {}
    this.errors = []
    this.dirty = false
  }

  /**
   * Get attribute value
   * @param attr
   * @returns {any}
   */
  get(attr: string) {
    return this.attributes[attr]
  }

  /**
   * @description get plugin conf
   * @param name
   */
  getPlugin(name: string) {
    if (this.attributes.plugins) {
      return this.attributes.plugins[name]
    }
    return null
  }

  /**
   * @description Get original value
   * @param attr
   * @return {*}
   */
  original(attr: string) {
    return this.originals[attr]
  }

  /**
   * @description set value
   * @param attr
   * @param value
   */
  set(attr: string, value: unknown) {
    this.attributes[attr] = value
    return this
  }

  /**
   * @description increase by @increaser the attribute
   * @param attr
   * @param increaser 1
   */
  increase(attr: string, increaser = 1) {
    this.set(attr, this.get(attr) + increaser)
    return this
  }

  /**
   * @description set value and replace originals
   * @return {Base}
   */
  setDeep(attr: string, value: unknown) {
    this.originals[attr] = value
    return this.set(attr, value)
  }

  /**
   * @description Set model is changed
   * @return {Base}
   */
  setDirty() {
    this.dirty = true
    return this
  }

  /**
   * @description get all attributes
   * @returns {*}
   */
  attrs() {
    return this.attributes
  }

  /**
   * Set all attributes
   * @param attrs
   * @returns {Core.Model}
   */
  update(attrs: string) {
    merge(this.attributes, attrs)
    return this
  }

  /**
   * @description delete an attribute
   * @param attr
   * @return {Base}
   */
  delete(attr: string) {
    const path = attr.split('.')
    const prop = path.pop()
    const parent = path.reduce((obj, key) => obj[key], this.attributes)
    if (parent && prop) delete parent[prop]
    return this
  }

  /**
   * @description check if is undefined an attribute
   * @param attr
   * @return {boolean}
   */
  isUndefined(attr: string) {
    return (
      typeof this.attributes[attr] === 'undefined' ||
      this.attributes[attr] === undefined
    )
  }

  /**
   * @description check if prop is changed from original status
   * @return {*}
   */
  isDirty() {
    return this.dirty
  }

  /**
   * @description check if property name is changed from original status
   */
  propIsChanged(name: string) {
    return this.original(name) !== this.get('name')
  }
}
