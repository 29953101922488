import { Bot } from '../models/Bot'
import WebSocketSubscribeEvent from '../decorators/WebSocketSubscribeEvent'
import WebSocketPublishEvent from '../decorators/WebSocketPublishEvent'
import { BaseService } from './BaseService'
import { Socket } from '@/assets/js/core/Socket'
import { ChatTyping } from '@/assets/js/enum/ChatTyping'

interface StoreUserParams {
  user_email: string
  user_phone: string
  user_name: string
  user_notes: string
}

interface getActiveOptions {
  socketCommand: 'op' | 'sv'
  userId: number
  from: number
  limit: number
  search?: string
}

class _ChatService extends BaseService {
  async archive(userId: number, chatId: number) {
    Socket.send(`robin sv ${userId} close ${chatId}`)
  }

  async getActive(data: getActiveOptions) {
    Socket.send(`robin ${data.socketCommand} ${data.userId} get sessions`, {
      search: data.search,
      offset: data.from,
      limit: data.limit,
    })
  }

  async getOneById(id: string | number) {
    Socket.send(`session get-by-id`, {
      id,
    })
  }

  getChatUser(
    id: string | number,
    { cmd, authId }: { cmd: 'sv' | 'op' | 'tl'; authId: number }
  ) {
    Socket.send(`robin ${cmd} ${authId} user ${id}`)
    return this
  }

  @WebSocketSubscribeEvent('session', 'get-by-id')
  async onGetOneById(_successFn: Function, _errorFn: Function) {}

  async assignToAgent({
    chatId,
    userEmail,
  }: {
    chatId: number
    userEmail: string
  }): Promise<void> {
    Socket.send('chat-assign-to-agent', {
      session: chatId,
      agent: userEmail,
    })
  }

  async assignToBot({ chatId }: { chatId: number }): Promise<void> {
    Socket.send('chat-assign-to-bot', { session: chatId })
  }

  async saveUserConversationInfo(
    sessionId: string | number,
    variables: Partial<StoreUserParams>
  ) {
    Socket.send('save_user_vars', {
      sessionId,
      variables,
    })
  }

  onNotification(bot: Bot, cb: Function) {
    Socket.on('chat-notification', (_: string, data: any) => {
      if (bot.get('argument') === data.data.bot_argument) {
        cb.call(this, data)
      }
    })
  }

  operatorTyping(
    sessionId: number,
    action: ChatTyping.START_TYPING | ChatTyping.END_TYPING
  ) {
    Socket.send('', {
      command: ChatTyping.DASHBOARD_COMMAND,
      sessionId,
      action,
    })
  }
}

const ChatService = new _ChatService()

export default ChatService
