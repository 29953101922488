import { BaseService } from '../BaseService'

export default class InvoiceService extends BaseService {
  /**
   * @description get subscription
   * @return {*}
   */
  get() {
    return this.http.mock().get('invoices')
  }
}
