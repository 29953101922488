import GenericUserVar from '../GenericUserVar'
import OnboardingConfig from '@/components/containers/onboarding-sales/interfaces/OnboardingConfig'

export default class OnboardingVar extends GenericUserVar {
  /**
   * @description Constructor
   * @param user
   */
  constructor() {
    super('onboarding')
  }

  store(value: OnboardingConfig) {
    try {
      super.store(JSON.stringify(value))
    } catch (e) {
      console.trace(e)
    }
  }
}
