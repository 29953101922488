import { Context } from '@nuxt/types'
import { logout } from '@/modules/auth'

export default async function ({ $auth, redirect, localePath }: Context) {
  if ($auth.loggedIn) {
    await $auth.logout()
  }
  await logout()
  return redirect(localePath('/login'))
}
