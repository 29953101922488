<template>
  <div class="flex-element" :style="{ flex: size }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FlexElement',
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.flex-element {
  flex: 1;
}
</style>
