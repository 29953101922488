import moment from 'moment-timezone'
import { Plan } from '../Plan'
import { Model } from '@/assets/js/core/Model'
import { ucfirst } from '@/assets/js/core/../utils'
import { currency } from '@/assets/js/core/helpers'
import { plans, agents, chats } from '@/assets/js/config/prices'
import Discount from '@/assets/js/models/Discount'
import { hasPermission } from '@/modules/auth'

export class Subscription extends Model {
  constructor(attrs = {}, billing = null) {
    super(attrs)

    this._roles = [
      'ADDESTRATORE',
      'AMMINISTRATORE',
      'ANALISTA',
      'OPERATORE',
      'SUPERVISORE',
      'TEAM_LEADER',
    ].map((role) => this.getRoleName(role))

    this._plan = this.plan()
    this.billing = billing
    this.discount = this.get('discount')
      ? new Discount(this.get('discount'))
      : null
    return this
  }

  /**
   * @description get plan name
   * @return {string}
   */
  name() {
    if (this.plan()) {
      return ucfirst(this.plan().nickname)
    }
    return ''
  }

  /**
   * @description get plan
   * @return {*}
   */
  plan() {
    if (!this._plan) {
      this._plan =
        this.get('items') &&
        this.get('items').find((item) => {
          return plans.some(
            (plan) => plan.stripe_key && plan.stripe_key.month === item.id
          )
        })
    }
    return this._plan
  }

  /**
   * @description get plan object
   * @return {Plan}
   */
  getPlan() {
    const attrs = plans.find((p) => p.stripe_key.month === this.plan()?.id)
    return new Plan(attrs)
  }

  /**
   * @description get amount
   * @return {Number}
   */
  amount() {
    let total = this.agentAmount() + this.planAmount()
    if (this.discount) {
      if (this.discount.isPercent()) {
        total -= this.discount.calcPercent(this.planAmount())
      } else {
        total -= this.discount.amount()
      }
    }
    return total
  }

  /**
   * @description check if Subscription is free
   * @return {boolean}
   */
  isFree() {
    return this.planAmount() === 0
  }

  /**
   * @description get plan amount
   * @return {Number}
   */
  planAmount() {
    const amount = this.plan()?.amount
    return parseFloat(amount.toString().replace(/\B(?=(\d{2})+(?!\d))/g, '.'))
  }

  /**
   * @description get agent amount
   */
  agentAmount() {
    const plan = agents.filter((a) => a.plan_id === this.plan()?.id)[0]

    if (plan) {
      return (this.billing.userLimits.total - 1) * plan.amount
    }

    return 0
  }

  deadline() {
    return moment.unix(this.get('current_period_end')).format('DD/MM/Y')
  }

  /**
   * @description get currency + price in string
   * @return {string}
   */
  priceString() {
    return `${this.amount()}${this.currencySymbol()} `
  }

  /**
   * @description get plan amount string
   * @return {string}
   */
  planAmountString() {
    return `${this.planAmount()}${this.currencySymbol()} `
  }

  /**
   * @description get price agent string
   */
  agentAmountString() {
    return `${this.agentAmount()}${this.currencySymbol()} `
  }

  /**
   * @description get currency + price in string
   * @return {string}
   */
  totalString() {
    return `${this.currencySymbol()} ${this.plan()?.amount}`
  }

  /**
   * @description get currency symbol by string
   * @return {string}
   */
  currencySymbol() {
    const currencyString = this.plan()?.currency.toLowerCase()
    return currency(currencyString)
  }

  /**
   * @description check if has a plan with stripe_key
   * @param key
   * @return {*}
   */
  hasStripeKey(key) {
    return this.get('items').some((item) => {
      return key === item.id
    })
  }

  /**
   * @description check if is in trial period
   * @return {boolean}
   */
  isTrialPeriod() {
    return this.get('status') === 'trialing'
  }

  getTrialDaysRemaining() {
    if (this.get('trialEnd')) {
      const trialEndMoment = moment.unix(this.get('trialEnd')).endOf('day')
      return trialEndMoment.diff(moment(), 'days')
    } else if (this.get('trialEnd') === null) {
      return -1
    }
    return 0
  }

  getTrialDaysDuration() {
    const finish = moment.unix(this.get('trialEnd')).endOf('day')
    const start = moment.unix(this.get('current_period_start')).endOf('day')
    return finish.diff(start, 'days')
  }

  /**
   * @description check if user can add agents
   * @return {boolean}
   */
  canAddAgents() {
    return this.get('maxUsers') > this.get('usedUsers')
  }

  /**
   * @description Plan limits
   * @param limits
   */
  addLimits({ userLimits }) {
    this.set('maxUsers', userLimits?.total ?? 1)
    this.set('usedUsers', userLimits?.used ?? 1)
    return this
  }

  /**
   * @description get role name
   * @param role
   * @return {string}
   */
  getRoleName(role) {
    return ucfirst(role.toLowerCase()).replace('_', ' ')
  }

  /**
   * @description check if all roles are available
   * @return {Boolean}
   */
  allRolesAvailable() {
    return this._roles
  }

  /**
   * @description get available roles
   * @return {Array}
   */
  availableRoles() {
    return this._roles
  }

  /**
   * @description get disabled roles
   * @return {Array}
   */
  disabledRoles() {
    return []
  }

  /**
   * @description check if is scheduled a downgrade
   * @return {any|*}
   */
  isScheduledDowngradePlan() {
    return this.get('__scheduled')
  }

  /**
   * @description get scheduled downgrade plan
   * @return {*}
   */
  scheduledDowngradePlan() {
    if (!this._scheduled_downgrade_plan) {
      if (this.isScheduledDowngradePlan() && this.get('__scheduled').plan) {
        const downgradePlanStripeKey = this.get('__scheduled').plan.requested
        const attrs = plans.find(
          (p) => p.stripe_key.month === downgradePlanStripeKey
        )
        attrs.executionDate = this.get('__scheduled').plan.executionDate
        this._scheduled_downgrade_plan = new Plan(attrs)
      } else {
        this._scheduled_downgrade_plan = false
      }
    }
    return this._scheduled_downgrade_plan
  }

  /**
   * @description get scheduled downgrade agent
   * @return {*}
   */
  scheduledAgents() {
    if (this.isScheduledDowngradePlan()) {
      if (this.get('__scheduled').agents) {
        return this.get('__scheduled').agents.requested
      } else if (this.scheduledDowngradePlan().get('id') === 'free') {
        if (this.get('maxUsers') > 1) {
          return 1
        }
      }
    }
    return false
  }

  /**
   * @description get scheduled downgrade agent
   * @return {*}
   */
  scheduledAgentsExecutionDate() {
    if (this.scheduledAgents()) {
      return this.scheduledExecutionDate()
    }
    return false
  }

  /**
   * @description scheduled total value
   * @return {*}
   */
  scheduledTotal() {
    const agentAmount = this.scheduledAgents()
      ? this.scheduledAgentsAmount()
      : this.agentAmount()
    const planAmount = this.scheduledDowngradePlan()
      ? this.scheduledDowngradePlan().amount()
      : this.planAmount()
    return agentAmount + planAmount
  }

  /**
   * @description get scheduled agents amount
   * @return {number}
   */
  scheduledAgentsAmount() {
    if (this.scheduledAgents()) {
      const plan = this.scheduledDowngradePlan()
        ? this.scheduledDowngradePlan()
        : this.getPlan()
      const amount = agents.find((a) => a.plan_id === plan.stripeKey()).amount
      return (this.scheduledAgents() - 1) * amount
    }
    return 0
  }

  /**
   * Scheduled execution update
   */
  scheduledExecutionDate() {
    const scheduled = this.get('__scheduled')
    if (scheduled && scheduled.executionDate) {
      return moment.unix(scheduled.executionDate).format('DD/MM/YYYY')
    }
    return false
  }

  /**
   * @description scheduled total string
   * @return {*}
   */
  scheduledTotalString() {
    return this.scheduledTotal() + currency('eur')
  }

  hasDiscount() {
    return this.discount !== null
  }

  isForever() {
    return this.plan()?.id.includes('products.lifetime')
  }

  isLTD() {
    return this.isForever() && !hasPermission('Customers')
  }

  isOff() {
    if (this.plan()) {
      return this.plan().id.includes('sales_free')
    }
    return false
  }
}
