/**
 * @description Import Uiv : Bootstrap3 Vue implementation
 */
// import '@/assets/styles/bootstrap/tooltip.css'

import Vue from 'vue'
import { Dropdown, tooltip } from 'uiv'

Vue.component('Dropdown', Dropdown)
Vue.directive('tooltip', tooltip)
