import moment from 'moment-timezone'
import { Model } from '@/assets/js/core/Model'
import { currency } from '@/assets/js/core/helpers'

export default class Invoice extends Model {
  number() {
    return this.get('docNumber')
  }

  /**
   * @description get description
   * @return {string}
   */
  description() {
    return this.get('object')
  }

  /**
   * @description get pdf download url
   * @return {string}
   */
  url() {
    return `invoices/${this.get('id')}/download`
  }

  /**
   * @description get payed date
   */
  paidDate() {
    return moment.unix(this.get('date'))
  }

  /**
   * @description check if is paid
   * @return {boolean}
   */
  isPaid() {
    return this.get('paid') === true
  }

  /**
   * @description get total string
   * @return {string}
   */
  totalString() {
    const amount = this.get('amount')
    return `${Number(amount.total).toFixed(2)}${currency(amount.currency)}`
  }

  /**
   * @description check if invoice si quittance
   * @return {boolean}
   */
  isQuittance() {
    return this.get('type') === 'R'
  }
}
